import clsx from 'clsx';

type CollapserIconProps = {
  show?: boolean;
  onClick?: () => void;
};

export const CollapserIcon: React.FC<CollapserIconProps> = ({
  show,
  onClick,
}) => {
  return (
    <svg
      width="22"
      height="14"
      onClick={onClick}
      viewBox="0 0 22 14"
      fill="none"
      className={clsx('transition-transform', show ? 'rotate-180' : '')}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7349 7.67426L15.2046 3.20459L16.2653 4.26525L10.7349 9.79558L5.20459 4.26525L6.26525 3.20459L10.7349 7.67426Z"
      />
    </svg>
  );
};
