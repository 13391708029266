import React, { useMemo } from 'react';
import clsx from 'clsx';
import RttIcon from '@mui/icons-material/Rtt';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

import { Button, PermissionCheck } from '../../components';
import { formatDate } from '../../utilities';
import { message_type } from './const';
import type { MessageResponse } from './useConversation';

const sortMessagesByTimestamp = (
  messages: MessageResponse[]
): MessageResponse[] => {
  return messages
    ? messages.sort(
        (a, b) =>
          new Date(a.timestamp!).getTime() - new Date(b.timestamp!).getTime()
      )
    : [];
};

type MessageHeaderProps = {
  isUser: boolean;
  icon?: React.ReactNode;
  messageType?: message_type;
};

const MessageHeader: React.FC<MessageHeaderProps> = ({
  isUser,
  icon,
  messageType,
}) => {
  const isTextType = messageType === null || messageType === message_type.TEXT;

  return (
    <div
      className={clsx(
        'text-medium font-semibold mb-xs flex items-center',
        isUser ? 'text-right justify-end mt-lg' : 'justify-start'
      )}
    >
      {isUser && (
        <div
          className="mr-2 mb-2"
          style={{ transform: isUser && !isTextType ? 'scaleX(-1)' : '' }}
        >
          {icon}
        </div>
      )}
      <span>{isUser ? 'User' : 'Agent'}</span>
      {!isUser && <div className="ml-2 mb-2">{icon}</div>}
    </div>
  );
};

type MessageBoxProps = {
  isUser: boolean;
  message: MessageResponse;
};

const MessageBox: React.FC<MessageBoxProps> = ({ isUser, message }) => {
  return (
    <div
      className={clsx(
        isUser
          ? 'mr-sm border-buttonLight'
          : 'ml-sm bg-buttonLight border-transparent',
        'text-medium p-lg rounded-slug border-2'
      )}
    >
      {message.message_text}
    </div>
  );
};

type MessagesProps = {
  className?: string;
  messages: MessageResponse[];
};

export const Messages: React.FC<MessagesProps> = ({
  className,
  messages: defaultMessages,
}) => {
  let prevMessage: MessageResponse | null = null;
  const messages = useMemo(
    () => sortMessagesByTimestamp(defaultMessages),
    [defaultMessages]
  );

  const messageIcon = (messageType?: string) => {
    return messageType === message_type.VOICE ? (
      <RecordVoiceOverIcon color="info" fontSize="large" />
    ) : (
      <RttIcon color="primary" fontSize="large" />
    );
  };

  return (
    <div
      className={clsx(className, 'flex flex-col gap-y-md pr-lg min-w-[20rem]')}
    >
      {messages
        .filter(message => !!message.message_text?.trim?.()?.length)
        .map((message, index) => {
          const isUser = message.author_type === 'user';
          const showHeader = prevMessage?.author_type !== message.author_type;

          prevMessage = message;

          return (
            <div
              key={message.id ?? index}
              className={clsx(
                'max-w-[80%] group/message-group',
                isUser ? 'ml-auto' : ''
              )}
            >
              {showHeader && (
                <MessageHeader
                  isUser={isUser}
                  icon={messageIcon(message?.message_type)}
                  messageType={message?.message_type}
                />
              )}
              <div
                className={clsx(
                  'text-small italic font-medium pl-md mt-md',
                  !isUser ? 'ml-sm' : ''
                )}
              >
                {formatDate(message.timestamp)}
              </div>
              <MessageBox isUser={isUser} message={message} />
              <PermissionCheck action="view_conversation_meta">
                <div className="flex justify-end mt-sm md:group-hover/message-group:visible md:invisible transition-def">
                  <Button
                    size="medium"
                    type="primary"
                    label="Metadata"
                    className="absolute right-0"
                  />
                </div>
              </PermissionCheck>
            </div>
          );
        })}
    </div>
  );
};
