import React, { useState } from 'react';

import { Input } from '../../../components';
import { Form, FormItem, SubmitButton, Button } from '../../../components';
import type { PermissionFormType } from './usePermissions';

type PartialForm = Partial<PermissionFormType>;

type PermissionFormProps = {
  saving: boolean;
  isEdit?: boolean;
  form?: PartialForm | null;

  onCancel: () => void;
  onSave: (form: PermissionFormType) => void;
};

const transformToSnakeCase = (input?: string): string => {
  if (!input) return '';

  return input.toLowerCase().replace(/\s+/g, '_');
};

export const PermissionForm: React.FC<PermissionFormProps> = ({
  form,
  isEdit,
  saving,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialForm>(form ?? {});

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<PartialForm> required name="name" label="Name" inline={false}>
          <Input withBorder placeholder="Enter Permission Name" />
        </FormItem>
        <FormItem<PartialForm>
          required
          inline={false}
          name="description"
          label="Description"
        >
          <Input withBorder placeholder="Enter Permission Description" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            label={isEdit ? 'Update' : 'Create'}
            disabled={saving}
            className="w-full"
            onClick={() =>
              onSave({
                ...values,
                mnemonic: values.mnemonic || transformToSnakeCase(values.name),
              } as PermissionFormType)
            }
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
