import React from 'react';

import icon from './icon.png';
import { Intents } from './Intents';
import { AgentForm } from '../../components';
import { CopyFromAgent } from '../../components';
import { useCheckPermissions } from '../../shared';
import { SectionHeader, Button } from '../../components';
import { SubmitButton, Copy, AssignAgent } from '../../components';
import { DynamicTopPanel, DynamicPageTitle } from '../../components';
import type { AgentDto } from '../../services';
import type { AgentFormProps } from '../../components/AgentForm';

export type AgentProps = AgentFormProps & {
  agent?: any;
  copying: boolean;
  editing: boolean;
  loading: boolean;
  onCopy: (id: string) => Promise<AgentDto | undefined>;
  onSubmit: () => void;
  showCopyModal: boolean;
  openCopyModal: () => void;
  closeCopyModal: () => void;
};

const AgentPage: React.FC<AgentProps> = ({
  form,
  formId,

  agent,
  copying,
  editing,
  loading,
  updatingFlow,

  onCopy,
  onChange,
  onSubmit,
  onUpdateFlow,

  showCopyModal,
  openCopyModal,
  closeCopyModal,
  ...rest
}) => {
  const editDisabled = !useCheckPermissions(['edit_agents'])[0]?.allowed;

  if (!form || !agent || loading) {
    return <DynamicPageTitle>Agent Configuration</DynamicPageTitle>;
  }

  return (
    <>
      <DynamicPageTitle>Agent Configuration</DynamicPageTitle>
      <div className="border-b pb-lg mb-lg">
        <div className="flex gap-md flex-wrap justify-between items-center mb-md">
          <div className="flex flex-row gap-x-md">
            <div className="text-small truncate">Agent ID: {agent.id}</div>
            <Copy className="shrink-0" text={agent.id ?? ''} />
          </div>
        </div>
        <div className="flex flex-row grow">
          <img
            alt=""
            src={icon}
            className="size-24 p-sm bg-contrast mr-sm shrink-0 rounded-xs border"
          />
          <SectionHeader
            className="grow"
            label={
              <div className="flex flex-row gap-x-md">{agent.agent_name}</div>
            }
            subLabel={agent.title ?? ''}
            contentClassName="mt-auto"
            content={
              <div className="flex flex-row items-center gap-x-md">
                <AssignAgent agentId={agent.id!} />
                <CopyFromAgent
                  onCopy={onCopy}
                  copying={copying}
                  showCopyModal={showCopyModal}
                  openCopyModal={openCopyModal}
                  closeCopyModal={closeCopyModal}
                />
              </div>
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-md">
        <AgentForm
          isEdit
          form={form}
          formId={formId}
          onChange={onChange}
          disabled={editDisabled}
          onUpdateFlow={onUpdateFlow}
          updatingFlow={updatingFlow}
          {...rest}
        >
          <Intents agentId={agent.id!} disabled={editDisabled} />
          {!editDisabled && (
            <div>
              <DynamicTopPanel>
                <div className="flex flex-row gap-x-md justify-center items-center">
                  <Button
                    label="Cancel"
                    url="/agents"
                    type="contrast"
                    size="medium"
                  />
                  <SubmitButton
                    label="Save"
                    size="medium"
                    type="primary"
                    loading={editing}
                    disabled={editing}
                    onClick={onSubmit}
                  />
                </div>
              </DynamicTopPanel>
            </div>
          )}
        </AgentForm>
      </div>
    </>
  );
};

export default AgentPage;
