import { useEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';

import type { RefObject } from 'react';

const needUpdateDeps = (ref: RefObject<any[]>, deps: any[], deep: boolean) => {
  if (!deep) return true;

  return !isEqual(deps, ref.current);
};

const useDeps = (deps: any[], deep: boolean) => {
  const ref = useRef<any[]>([]);

  if (needUpdateDeps(ref, deps, deep)) {
    ref.current = deps;
  }

  return ref.current;
};

export const useUpdateEffect = (
  factory: React.EffectCallback,
  deps: any[],
  deep: boolean = false
) => {
  const isFirstRun = useRef(true);

  useEffect(
    () => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      return factory();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeps(deps, deep)
  );
};
