import React, { useRef, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

type AudioPlayerProps = {
  src: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  const playerRef = useRef<ReactAudioPlayer>(null);

  useEffect(() => {
    if (playerRef.current?.audioEl?.current) {
      const audioElement = playerRef.current.audioEl.current;
      audioElement.setAttribute('controlsList', 'nodownload noplaybackrate');
    }
  }, []);

  return (
    <div className="w-full text-white relative p-4  rounded-lg pl-4 flex flex-col items-center">
      <ReactAudioPlayer
        ref={playerRef}
        src={src}
        className="max-h-10"
        preload="auto"
        controls
        title="Audio Player"
      />
    </div>
  );
};
export default AudioPlayer;
