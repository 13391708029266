import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { getLanguageDisplayName } from '../utils/getLanguageDisplayName';

export type CountryGreetingFieldProps = {
  value: Record<string, string>;
  label?: string;
  withButtonList?: boolean;
  languages: string[];
  updateValue: (value: Record<string, string>, error: string | null) => void;
  classes?: {
    root?: string;
    autocomplete?: string;
    greetingInput?: string;
    chip?: string;
    jsonInput?: string;
    itemBox?: string;
    langLabel?: string;
    langValue?: string;
    iconButton?: string;
    dialogTitle?: string;
    dialogContent?: string;
    dialogActions?: string;
    helperText?: string;
  };
};

export const CountryGreetingField: React.FC<CountryGreetingFieldProps> = ({
  value,
  updateValue,
  label,
  languages,
  classes,
  withButtonList = false,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [greeting, setGreeting] = useState<string>('');
  const [editKey, setEditKey] = useState<string | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<string | null>(null);
  const [jsonText, setJsonText] = useState<string>(
    JSON.stringify(value, null, 2)
  );
  const [jsonError, setJsonError] = useState<string>('');

  const normalizeKeys = (obj: Record<string, string>) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v])
    );

  const availableLanguages = languages.filter(
    lang =>
      !Object.keys(value || {}).includes(lang.toLowerCase()) ||
      editKey === lang.toLowerCase()
  );

  const isValidLang = (code: string) =>
    languages.some(l => l.toLowerCase() === code.toLowerCase());

  const validateJsonObject = (obj: Record<string, string>): string | null => {
    const normalized = normalizeKeys(obj);

    if (
      typeof normalized !== 'object' ||
      Array.isArray(normalized) ||
      !normalized
    )
      return 'Object is not valid';

    for (const [key, val] of Object.entries(normalized)) {
      if (typeof key !== 'string' || typeof val !== 'string')
        return 'Keys and values must be strings';
      if (!isValidLang(key)) return `Unknown language: ${key}`;
    }

    return null;
  };

  const handleAddOrUpdate = () => {
    if (!selectedLanguage || !greeting) return;

    const lowerKey = selectedLanguage.toLowerCase();
    const newObj = { ...value, [lowerKey]: greeting };

    const error = validateJsonObject(newObj);
    setJsonError(error || '');
    updateValue(newObj, error);

    if (!error) {
      setSelectedLanguage(null);
      setGreeting('');
      setEditKey(null);
    }
  };

  const handleEdit = (code: string) => {
    setSelectedLanguage(code);
    setGreeting(value[code]);
    setEditKey(code);
  };

  const confirmDelete = () => {
    if (deleteTarget) {
      const newValue = { ...value };
      delete newValue[deleteTarget];
      updateValue(newValue, null);
      setJsonText(JSON.stringify(newValue, null, 2));
    }
    setDeleteTarget(null);
  };

  const handleJsonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setJsonText(text);

    try {
      const parsed = JSON.parse(text);
      const error = validateJsonObject(parsed);
      if (error) {
        setJsonError(error);
        updateValue(value, error);
      } else {
        setJsonError('');
        const normalized = normalizeKeys(parsed);
        updateValue(normalized, null);
      }
    } catch {
      setJsonError('Object is not valid');
      updateValue(value, 'Object is not valid');
    }
  };

  useEffect(() => {
    setJsonText(JSON.stringify(value, null, 2));
  }, [value]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      className={classes?.root}
    >
      <Box display="flex" gap={2}>
        <Autocomplete
          className={classes?.autocomplete}
          fullWidth
          options={availableLanguages}
          value={selectedLanguage}
          onChange={(_, val) => {
            setSelectedLanguage(val);
            setGreeting('');
            setEditKey(null);
          }}
          getOptionLabel={lang => getLanguageDisplayName(lang)}
          renderInput={params => (
            <TextField
              {...params}
              label={label || 'Language'}
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: selectedLanguage ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedLanguage(null);
                        setGreeting('');
                        setEditKey(null);
                      }}
                      className={classes?.iconButton}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ) : null,
                },
              }}
            />
          )}
        />

        <TextField
          className={classes?.greetingInput}
          fullWidth
          label="Greeting"
          value={greeting}
          onChange={e => setGreeting(e.target.value)}
          disabled={!selectedLanguage}
        />
      </Box>

      <Box textAlign="right">
        <Chip
          className={classes?.chip}
          label={editKey ? 'Update Greeting' : 'Add Greeting'}
          color="primary"
          clickable
          onClick={handleAddOrUpdate}
        />
      </Box>

      <Box>
        <TextField
          className={classes?.jsonInput}
          label="JSON Object"
          multiline
          minRows={6}
          fullWidth
          value={jsonText}
          onChange={handleJsonChange}
          error={!!jsonError}
        />
        <Typography
          variant="caption"
          className={classes?.helperText}
          sx={{
            color: jsonError ? 'error.main' : 'text.secondary',
            fontStyle: jsonError ? 'italic' : 'normal',
          }}
        >
          {jsonError || 'Enter a JSON object: { "en": "Hello", ... }'}
        </Typography>
      </Box>

      {withButtonList && (
        <Box mt={2}>
          {Object.entries(value || {}).map(([code, phrase]) => (
            <Box
              key={code}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              p={1}
              border="1px solid #ddd"
              borderRadius={2}
              className={classes?.itemBox}
            >
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center"
              >
                <Typography fontWeight="bold" className={classes?.langLabel}>
                  {getLanguageDisplayName(code)}:
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className={classes?.langValue}
                >
                  {phrase}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => handleEdit(code)}
                  className={classes?.iconButton}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => setDeleteTarget(code)}
                  className={classes?.iconButton}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Dialog open={!!deleteTarget} onClose={() => setDeleteTarget(null)}>
        <DialogTitle className={classes?.dialogTitle}>
          Confirm Delete
        </DialogTitle>
        <DialogContent className={classes?.dialogContent}>
          Are you sure you want to remove this language greeting?
        </DialogContent>
        <DialogActions className={classes?.dialogActions}>
          <Button onClick={() => setDeleteTarget(null)} color="inherit">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
