import React, { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import type { LinkProps, NavLinkProps } from 'react-router-dom';

import { normalizeLink } from './normalizeLink';

type InternalNavLinkProps = {
  isNav: true;
  newTab?: boolean;
} & NavLinkProps;

type InternalNonNavLinkProps = {
  isNav?: false | undefined;
  newTab?: boolean;
} & LinkProps;

export type InternalLinkProps = InternalNavLinkProps | InternalNonNavLinkProps;

export type ExternalLinkProps = Omit<LinkProps, 'to' | 'target'> & {
  to: string;
  newTab?: boolean;
};

type AutoLinkExternalProps = {
  external: true;
  isNav?: false | undefined;
} & ExternalLinkProps;

type AutoLinkInternalProps = {
  external?: false;
} & InternalLinkProps;

export type AutoLinkProps = AutoLinkExternalProps | AutoLinkInternalProps;

const InternalLink: React.FC<InternalLinkProps> = props => {
  if (props.isNav) {
    const { isNav, newTab, to, ...rest } = props;

    return (
      <NavLink
        {...rest}
        to={to}
        rel="noopener noreferrer"
        target={newTab ? '_blank' : undefined}
      />
    );
  }

  const { newTab, isNav, ...rest } = props;
  const thisTo =
    typeof props.to === 'string' ? normalizeLink(props.to) : props.to;

  return (
    <Link
      {...rest}
      to={thisTo}
      rel="noopener noreferrer"
      target={newTab ? '_blank' : undefined}
    />
  );
};

const ExternalLink: React.FC<ExternalLinkProps> = ({
  to,
  newTab = true,
  ...props
}) => {
  const pathname = useMemo(() => normalizeLink(to ?? ''), [to]);

  return (
    <Link {...props} target={newTab ? '_blank' : undefined} to={{ pathname }} />
  );
};

const AutoLink: React.FC<AutoLinkProps> = props => {
  if (props.external) {
    const { external, isNav, ...rest } = props;
    return <ExternalLink {...rest} />;
  }

  const { external, ...rest } = props;
  return <InternalLink {...rest} />;
};

export type { AutoLinkProps as LinkProps };
export default React.memo(AutoLink) as typeof AutoLink;
