import clsx from 'clsx';

import { Input } from '..';
import { SearchIcon } from './SearchIcon';

export type SearchBarProps = {
  search: string;
  className?: string;
  withBorder?: boolean;
  onSearch: (newSearch: string) => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  search,
  onSearch,
  className,
  withBorder = true,
}) => {
  return (
    <Input
      value={search}
      onChange={onSearch}
      placeholder="Search"
      className={className}
      prefix={<SearchIcon />}
      withBorder={withBorder}
      inputClassName={clsx('pl-20')}
      inputRoundedClassName="rounded-card"
      defaultSizeClass="min-w-[14rem] md:min-w-[20rem]"
    />
  );
};
