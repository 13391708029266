import { RolesTab } from './RolesTab';
import { Tabs, Tab } from '../../components';
import { PermissionsTab } from './PermissionsTab';

type TabsEnum = 'roles' | 'permissions';

const RolesAndPermissionsPage = () => {
  return (
    <Tabs<TabsEnum>
      tabs={[
        { name: 'roles', label: 'Roles' },
        { name: 'permissions', label: 'Permissions' },
      ]}
      contentClassName="mt-md"
    >
      <Tab name="roles">
        <RolesTab />
      </Tab>
      <Tab name="permissions">
        <PermissionsTab />
      </Tab>
    </Tabs>
  );
};

export default RolesAndPermissionsPage;
