/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AgentSettingsDto = {
    who_speaks_first?: AgentSettingsDto.who_speaks_first;
    keywords?: Array<Array<Record<string, any>>>;
    activation_phrases?: Array<string>;
};
export namespace AgentSettingsDto {
    export enum who_speaks_first {
        USER = 'USER',
        AGENT = 'AGENT',
    }
}

