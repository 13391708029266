import React from 'react';
import {
  DynamicPageTitle,
  SectionHeader,
  CopyFromAgent,
} from '../../components';
import AgentFormInputs from '../../components/AgentForm/AgentFormInputs';
import type { AgentResponse } from '../../api';
import type { AgentFormProps } from '../../components/AgentForm';
import type { AgentForm as AgentFormType } from '../../utilities';

type NewAgentProps = AgentFormProps & {
  copying: boolean;
  loading: boolean;
  onCopy: (id: string) => Promise<AgentResponse | undefined>;
  showCopyModal: boolean;
  openCopyModal: () => void;
  closeCopyModal: () => void;
  editDisabled?: boolean;
  form: AgentFormType;
};

const NewAgentPage: React.FC<NewAgentProps> = ({
  form = {} as AgentFormType,
  formId,
  copying,
  loading,
  onCopy,
  onChange,
  editDisabled,
  showCopyModal,
  openCopyModal,
  closeCopyModal,
  ...rest
}) => {
  if (!form || loading) {
    return <DynamicPageTitle>Create new Agent</DynamicPageTitle>;
  }

  return (
    <>
      <DynamicPageTitle>Agent Configuration</DynamicPageTitle>
      <div className="border-b pb-lg mb-lg">
        <div className="flex flex-row grow">
          <SectionHeader
            className="grow"
            label={<div className="flex flex-row gap-x-md">New Agent</div>}
            contentClassName="mt-auto"
            content={
              <div className="flex flex-row items-center gap-x-md">
                <CopyFromAgent
                  onCopy={onCopy}
                  copying={copying}
                  showCopyModal={showCopyModal}
                  openCopyModal={openCopyModal}
                  closeCopyModal={closeCopyModal}
                />
              </div>
            }
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-md">
        <AgentFormInputs
          isEdit={false}
          form={form}
          formId={formId}
          onChange={onChange}
          disabled={editDisabled}
          {...rest}
        ></AgentFormInputs>
      </div>
    </>
  );
};

export default NewAgentPage;
