import React, { useState } from 'react';

import { getUserName } from '../../utilities';
import { SubmitButton, Button } from '../../components';
import { Dropdown, Form, FormItem } from '../../components';
import type { UserListType, AssignUserToRolePayload } from './useOrganization';

type PartialForm = Partial<AssignUserToRolePayload>;

type AssignUserRoleFormProps = {
  saving: boolean;
  user?: UserListType;

  onCancel: () => void;
  onSave: (form: AssignUserToRolePayload) => void;

  roles: OptionType[];
};

export const AssignUserRoleForm: React.FC<AssignUserRoleFormProps> = ({
  user,

  saving,

  onSave,
  onCancel,

  roles,
}) => {
  const [values, setValues] = useState<PartialForm>({ userId: user?.id });

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <div className="text-medium">Select Role you want assign to:</div>
      <div className="text-medium font-medium">{getUserName(user)}</div>
      <Form onChange={setValues} initialValue={values}>
        <FormItem<PartialForm>
          required
          label="Role"
          name="roleId"
          inline={false}
        >
          <Dropdown options={roles} placeholder="Select role" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            label="Assign"
            disabled={saving}
            className="w-full"
            onClick={() => onSave(values as AssignUserToRolePayload)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
