import { Edit, DeleteOutline } from '@mui/icons-material';
import { ContentCopyOutlined } from '@mui/icons-material';

import { RoleForm } from './RoleForm';
import { useRoles } from './useRoles';
import { Table, SearchPanel } from '../../../components';
import { EditRolePermissions } from './EditRolePermissions';
import { useRemovePermission } from './useRemovePermission';
import { Button, Modal, MoreActions } from '../../../components';
import { DynamicPageTitle, ConfirmModal, Copy } from '../../../components';
import type { RoleType } from './useRoles';

type ViewRolePermissionsProps = {
  row: RoleType;
  onRefresh: () => void;
};

const ViewRolePermissions: React.FC<ViewRolePermissionsProps> = ({
  row,
  onRefresh,
}) => {
  const { isOpened, onClose, onOpen, onRemovePermission, loading } =
    useRemovePermission(row.id!, onRefresh);

  return (
    <>
      <ConfirmModal
        title="Do you want to remove this permission from role?"
        open={isOpened}
        onClose={onClose}
        onConfirm={onRemovePermission}
      />
      <Table
        withHoverBorder={false}
        cellsClassName="bg-background"
        config={{
          columns: [
            { header: 'Name', getValue: ({ name }) => name },
            {
              header: 'Description',
              getValue: ({ description }) => description,
            },
            {
              header: '',
              cellContentClassName: 'text-right',
              cellClassName: 'min-w-[10rem] max-w-[10rem] w-[10rem]',
              getValue: ({ id }) => (
                <Button
                  size="small"
                  loading={loading}
                  icon={<DeleteOutline />}
                  onClick={() => onOpen(id)}
                />
              ),
            },
          ],
        }}
        data={row?.permissions ?? []}
        emptyMessage="There are no permissions for this role"
      />
    </>
  );
};

export const RolesTab = () => {
  const {
    roles,
    loading,
    reloading,
    isOpened,

    updating,
    search,
    currentForm,
    currentFormId,

    allPermissions,

    onOpen,
    onClose,
    refresh,
    setSearch,
    handleAddRole,
    handleUpdateRole,
    setCurrentForm,
    handleUpdateRolePermissions,

    openedDeleteModal,
    onDeleteRole,
    openDeleteModal,
    closeDeleteModal,

    openedDuplicateModal,
    onDuplicateRole,
    openDuplicateModal,
    closeDuplicateModal,
  } = useRoles();

  const inProgress = loading || reloading || updating;

  return (
    <>
      <DynamicPageTitle>Roles</DynamicPageTitle>
      <ConfirmModal
        title="Do you want to delete this role?"
        open={openedDeleteModal}
        onConfirm={onDeleteRole}
        onClose={closeDeleteModal}
      />
      <ConfirmModal
        title="Do you want to duplicate this role?"
        open={openedDuplicateModal}
        onConfirm={onDuplicateRole}
        onClose={closeDuplicateModal}
      />
      <div className="flex justify-between items-center mb-sm">
        <SearchPanel
          search={search}
          onSearch={setSearch}
          withPaginate={false}
        />

        <Button
          label="Add Role"
          onClick={() => {
            setCurrentForm(undefined);
            onOpen();
          }}
          size="medium"
        />
      </div>
      <Modal
        size="small"
        open={isOpened}
        onClose={onClose}
        title={currentFormId ? 'Update role' : 'Create new role'}
      >
        <RoleForm
          key={currentFormId ?? 'new'}
          form={currentForm}
          onCancel={onClose}
          isEdit={!!currentFormId}
          saving={inProgress}
          onSave={currentFormId ? handleUpdateRole : handleAddRole}
        />
      </Modal>
      <Table
        data={roles}
        loading={loading}
        emptyMessage="There are no roles."
        renderExpandComponent={row => (
          <ViewRolePermissions row={row} onRefresh={refresh} />
        )}
        config={{
          columns: [
            {
              sortName: 'id',
              header: 'ID',
              cellClassName: 'min-w-[10rem] w-[10rem] max-w-[10rem]',
              getValue: ({ id }) => (
                <>
                  <Copy
                    text={id ?? ''}
                    label="Copy ID"
                    className="mr-xs block"
                  />
                </>
              ),
            },
            {
              header: 'Name',
              sortName: 'name',
              cellClassName: 'min-w-[20rem]',
              getValue: ({ name }) => name,
            },
            {
              header: 'Description',
              sortName: 'description',
              cellClassName: 'min-w-[30rem]',
              getValue: ({ description }) => description,
            },
            {
              header: '',
              cellClassName: 'min-w-[25rem] max-w-[25rem] w-[25rem]',
              getValue: role => (
                <div className="flex gap-x-md items-center justify-center">
                  <EditRolePermissions
                    role={role}
                    allPermissions={allPermissions}
                    onEditPermissions={handleUpdateRolePermissions}
                    loading={inProgress}
                  />
                  <MoreActions
                    options={[
                      {
                        label: 'Edit',
                        icon: <Edit />,
                        loading: inProgress,
                        onClick: () => {
                          setCurrentForm(role);
                          onOpen();
                        },
                      },
                      {
                        label: 'Duplicate',
                        loading: inProgress,
                        icon: <ContentCopyOutlined />,
                        onClick: () => {
                          openDuplicateModal(role.id!);
                        },
                      },
                      {
                        label: 'Delete',
                        loading: inProgress,
                        icon: <DeleteOutline />,
                        onClick: () => {
                          openDeleteModal(role.id!);
                        },
                      },
                    ]}
                  />
                </div>
              ),
            },
          ],
        }}
      />
    </>
  );
};

export default RolesTab;
