import { useState } from 'react';

import { notify } from '../../../app';
import { useFetch, useModal, useRequest } from '../../../hooks';
import { getOrganizations, addOrganization } from '../../../api';
import { editOrganization, deleteOrganization } from '../../../api';
import type { OrganizationType, OrganizationCreatePayload } from '../../../api';

export const useOrganizations = () => {
  const [search, setSearch] = useState('');
  const { isOpened, onClose, onOpen } = useModal();

  const [currentForm, setCurrentForm] = useState<
    Partial<OrganizationType> | undefined
  >(undefined);
  const currentFormId = currentForm?.id;

  const {
    data: organizations = [],
    intializing: loading,
    loading: reloading,
    refresh,
  } = useFetch<OrganizationType[]>(() => getOrganizations(), []);

  const [handleAddOrganization, adding] = useRequest(
    (_signal, values: OrganizationCreatePayload) => addOrganization(values),
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Organization created');
      },
      onError: () => {
        notify.error('Failed to create new organization');
      },
      deps: [],
    }
  );
  const [handleEditOrganization, updating] = useRequest(
    (_signal, values: OrganizationCreatePayload) => {
      if (!currentFormId) return null;

      return editOrganization(currentFormId, values);
    },
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Organization updated');
      },
      onError: () => {
        notify.error('Failed to update organization');
      },
      deps: [currentFormId],
    }
  );

  const {
    isOpened: openedDeleteModal,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useModal();
  const [orgForDelete, setOrgForDelete] = useState<string | undefined>();
  const handleOpenDeleteOrgModal = (intentId: string) => {
    setOrgForDelete(intentId);
    openDeleteModal();
  };
  const [handleDeleteOrganization, deleting] = useRequest(
    async _token => {
      if (!orgForDelete) return false;

      return await deleteOrganization(orgForDelete);
    },
    {
      deps: [orgForDelete],
      onSuccess: () => {
        notify.success('Organization deleted');
        refresh?.();
        closeDeleteModal();
      },
      onError: () => notify.error('Failed to delete organization'),
    }
  );

  return {
    organizations: organizations || [],
    loading,
    reloading,

    search,
    setSearch,

    adding,
    updating,
    isOpened,
    onClose,
    onOpen,
    refresh,

    handleAddOrganization,
    handleEditOrganization,

    currentForm,
    isEdit: !!currentFormId,
    setCurrentForm,

    deleting,
    orgForDelete,
    openedDeleteModal,
    closeDeleteModal,
    handleDeleteOrganization,
    openDeleteModal: handleOpenDeleteOrgModal,
  };
};

export type { OrganizationType, OrganizationCreatePayload };
