import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import {
  Tabs,
  Tab,
  WidgetSettingsBuilder,
  Breadcrumbs,
} from '../../components';
import AgentPage from './AgentFormTab';
import { useAgent } from './hooks/useAgent';
import { useFormSchema } from './hooks/useFormSchema';
import { useAgentWidgetSettings } from './hooks/useWidgetSettings';
import type { FormJsonData } from '../../api';
import type { AgentForm } from '../../utilities';

type TabsEnum = 'agent' | 'widgetFormSettings';

const AgentPageTabs = () => {
  const { params } = useRouteMatch<{ agent_id: string }>();
  const { data: schema, intializing } = useFormSchema('agentWidgetSettings');
  const { loading, agent, form, onSubmit, onChange, ...rest } = useAgent(
    params?.agent_id
  );
  const { handleUpdateWidgetSettings, data, getWidgetSettings, setWidgetData } =
    useAgentWidgetSettings();

  const [activeTab, setActiveTab] = useState<TabsEnum>('agent');

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as TabsEnum);
  };

  const handleSaveWidgetSettings = () => {
    handleUpdateWidgetSettings(params?.agent_id, {
      widgetSettings: { ...data },
    });
  };

  const handleWidgetSettingsChange = (settings: Record<string, unknown>) => {
    const data = {
      ...form,
      widget_settings: settings ? JSON.stringify(settings) : '',
    } as AgentForm;
    setWidgetData(settings);
    onChange(data);
  };

  useEffect(() => {
    if (activeTab === 'widgetFormSettings') {
      getWidgetSettings(params?.agent_id);
    }
  }, [activeTab, getWidgetSettings, params?.agent_id]);

  return (
    <div>
      <div className="flex gap-md flex-wrap justify-between items-center mb-md">
        <Breadcrumbs
          subtitle="Agents"
          subtitleUrl="/agents"
          title={agent?.agent_name ?? 'Title'}
        />
      </div>
      <Tabs<TabsEnum>
        tabs={[
          { name: 'agent', label: 'Agent' },
          { name: 'widgetFormSettings', label: 'Widget Settings' },
        ]}
        contentClassName="mt-md"
        onChange={handleTabChange}
      >
        <Tab name="agent">
          <AgentPage
            {...rest}
            onSubmit={onSubmit}
            onChange={onChange}
            agent={agent}
            form={form!}
            loading={loading}
          />
        </Tab>
        <Tab name="widgetFormSettings">
          <WidgetSettingsBuilder
            onChange={handleWidgetSettingsChange}
            widgetSettings={data}
            schemaData={schema as FormJsonData}
            intializing={intializing}
            onSave={handleSaveWidgetSettings}
            showSaveButton
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default AgentPageTabs;
