import { Filter } from './Filter';
import { useConversations } from './useConversations';
import { Table, SearchPanel, Link } from '../../components';
import { formatDate, getDurationFromSeconds } from '../../utilities';
import { GoToDetails, DynamicPageTitle, Copy } from '../../components';

const ConversationsPage = () => {
  const {
    agentsOptions,
    filters,
    setFilters,
    loading,
    conversations,
    pagination,
    setPage,

    sort,
    setSort,
  } = useConversations();

  return (
    <>
      <DynamicPageTitle>Conversations List</DynamicPageTitle>
      <Filter
        values={filters ?? {}}
        onChange={setFilters}
        agentsOptions={agentsOptions}
      />
      <Table
        sort={sort}
        onSort={setSort}
        loading={loading}
        verticalAlign="top"
        data={conversations ?? []}
        emptyMessage="No Conversations"
        config={{
          columns: [
            {
              sortName: 'title',
              header: 'Conversation Title',
              cellClassName: 'min-w-[20rem]',
              getValue: ({ title, id }) => (
                <>
                  <Link withIcon withDecoration url={`/conversation/${id}`}>
                    <span>{title}</span>
                  </Link>
                  <Copy
                    text={id ?? ''}
                    label="Copy ID"
                    className="mr-xs block"
                  />
                </>
              ),
            },
            {
              sortName: 'agentTitle',
              header: 'Agent Title',
              cellClassName: 'min-w-[12rem] max-w-[14rem]',
              getValue: ({ agent_id, agent_title }) => (
                <>
                  <span>{agent_title}</span>
                  <Copy
                    text={agent_id ?? ''}
                    label="Copy ID"
                    className="mr-xs block"
                  />
                </>
              ),
            },
            {
              header: 'Country',
              cellClassName: 'min-w-[12rem] max-w-[14rem]',
              getValue: ({ country }) => <span>{country ?? '-'}</span>,
            },
            {
              header: 'Started',
              sortName: 'startedAt',
              cellClassName: 'min-w-[18rem] max-w-[20rem]',
              getValue: ({ started_at }) => formatDate(started_at),
            },
            {
              header: 'Finished',
              sortName: 'endedAt',
              cellClassName: 'min-w-[18rem] max-w-[20rem]',
              getValue: ({ ended_at }) =>
                ended_at ? formatDate(ended_at) : ' - ',
            },
            {
              header: 'Duration',
              sortName: 'duration',
              cellClassName: 'min-w-[18rem] max-w-[20rem]',
              getValue: ({ duration }) => getDurationFromSeconds(duration),
            },
            {
              header: 'Messages count',
              sortName: 'messagesCount',
              cellClassName: 'min-w-[8rem] max-w-[10rem]',
              getValue: ({ messages_count }) => messages_count || 0,
            },
            {
              header: '',
              cellContentClassName: 'text-right',
              cellClassName: 'max-w-[8rem] min-w-[8rem] align-middle',
              getValue: ({ id }) => <GoToDetails url={`/conversation/${id}`} />,
            },
          ],
        }}
      />
      <div className="mt-sm flex justify-between items-center">
        <SearchPanel {...pagination} withPaginate setPage={setPage} />
      </div>
    </>
  );
};

export default ConversationsPage;
