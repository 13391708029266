import { z } from 'zod';

export const getChunkSizeSchema = () => {
  return z.object({
    chunk_size: z
      .union([z.string(), z.number()])
      .transform(val => Number(val))
      .refine(val => !isNaN(val), {
        message: 'Chunk size must be a number',
      })
      .refine(val => val >= 1000 && val <= 7000, {
        message: 'Chunk size must be between 1000 and 7000',
      }),
  });
};

export const validateChunkSize = (input?: number) => {
  if (!input) return false;
  try {
    getChunkSizeSchema().parse({ chunk_size: input });
    return false;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return error.errors?.[0]?.message;
    }
    return 'Invalid Input';
  }
};
