import _ from 'lodash';

import type { AgentResponse, AgentUpdateRequest } from '../api';

export type TurnDetectorEnum = 'on' | 'off';
export type AgentFormSettingsType = {
  keywords?: string;
  activation_phrases?: string;
  who_speaks_first?: NonNullable<
    AgentUpdateRequest['settings']
  >['who_speaks_first'];
};
export type PipelineSettings = {
  max_llm_timeout?: number;
  use_agent_steps?: boolean;
  domains_whitelist?: string;
  empty_room_timeout?: number;
  request_cleaner_code?: string;
  is_record_audio_allowed?: boolean;
  stt_listen_only_mainuser?: boolean;

  check_violations?: string;
  check_agent_violations?: string;

  automute_timeout_sec?: number;
  min_endpointing_delay?: number;
  turn_detector?: boolean | null;
  turn_detector_debug_mode?: boolean | null;

  interrupt_min_words?: number;
  interrupt_speech_duration?: number;

  rag_agent_id?: number;
  rag_collection_id?: number;

  max_endpointing_delay?: number;
  groq_turn_detector_prompt?: string;

  room_life_time_limit?: number;

  speechmatics_max_delay?: number;
  speechmatics_additional_vocab?: string;
  clean_user_input_code_box?: string;
  code_box_debug_on?: boolean;
};

export type AgentForm = Omit<
  AgentUpdateRequest,
  'widget_settings' | 'settings' | 'pipeline_settings'
> &
  AgentFormSettingsType &
  PipelineSettings & {
    interrupt_min_words?: number;
  } & {
    id?: string;
    conversation_flow?: string;
    domains_white_list?: string;
    link_mic_audio?: boolean;
    preemptive_synthesis?: boolean;
    speechmatics_additional_vocab?: string;
    speechmatics_max_delay?: number;
    clean_user_input_code_box?: string;
    code_box_debug_on?: boolean;
    tts_fallback_settings?: string;
  };
export type { AgentUpdateRequest };

const arrayToString = (array: Array<string>) => {
  return (array || []).join('\n');
};
export const stringToArray = (string: string) => {
  return (string || '').split('\n').filter(s => !!s.length);
};

export const stringToArrayQuote = (input: string): string[] => {
  if (!input) return [];

  return input.split(',').map(item => `${item.trim()}`);
};

export const arrayToCommaSeparatedString = (arr: string[]): string => {
  return arr.join(', ');
};

const convertKeywordsToString = (
  keywords: NonNullable<AgentUpdateRequest['settings']>['keywords']
) => {
  return (keywords || [])
    .map(([word, count]: any) => `${word}:${count}`)
    .join('\n');
};
const convertStringToKeywords = (string: string): any => {
  return (string || '')
    .split('\n')
    .filter(s => !!s.length)
    .map(string => {
      const [word, count] = string.split(':');
      if (!word || !count) return null;

      return [word, parseInt(count)];
    })
    .filter(w => !!w);
};

export const formatAgentToForm = (agentFromApi: AgentResponse): AgentForm => {
  const {
    settings,
    pipeline_settings,
    widget_settings,

    active,
    agent_name,
    agent_master_id,

    language,
    llm_primary_model_id,
    stt_primary_model_id,
    tts_primary_model_id,
    stt_primary_model_settings,
    stt_backup_model_settings,
    tts_primary_model_settings,
    tts_backup_model_settings,
    temperature,
    conversation_flow,

    ...rest
  } = agentFromApi;

  return {
    ...rest,
    ...settings,
    ...pipeline_settings,
    temperature: temperature ?? 0.3,

    active: active ?? true,
    agent_name: agent_name || '',
    agent_master_id: agent_master_id || '',

    language: (language || 'en') as any,
    llm_primary_model_id: llm_primary_model_id || '',
    stt_primary_model_id: stt_primary_model_id || '',
    tts_primary_model_id: tts_primary_model_id || '',
    stt_primary_model_settings,
    stt_backup_model_settings,
    tts_primary_model_settings,
    tts_backup_model_settings,
    conversation_flow: !_.isEmpty(conversation_flow)
      ? JSON.stringify(conversation_flow)
      : '',

    speechmatics_max_delay: pipeline_settings?.speechmatics_max_delay ?? 0.7,
    speechmatics_additional_vocab:
      pipeline_settings?.speechmatics_additional_vocab
        ? JSON.stringify(pipeline_settings?.speechmatics_additional_vocab)
        : '',

    keywords: convertKeywordsToString(settings?.keywords ?? []),
    activation_phrases: arrayToString(settings?.activation_phrases ?? []),
    check_violations: arrayToString(pipeline_settings?.check_violations ?? []),
    check_agent_violations: arrayToString(
      pipeline_settings?.check_agent_violations ?? []
    ),
    domains_white_list: arrayToCommaSeparatedString(
      pipeline_settings?.domains_white_list ?? []
    ),
    tts_fallback_settings: pipeline_settings?.tts_fallback_settings
      ? JSON.stringify(pipeline_settings?.tts_fallback_settings)
      : '',
    link_mic_audio: pipeline_settings?.link_mic_audio ?? false,
    preemptive_synthesis: pipeline_settings?.preemptive_synthesis ?? true,
    clean_user_input_code_box: pipeline_settings?.clean_user_input_code_box,
    code_box_debug_on: pipeline_settings?.code_box_debug_on ?? false,
  };
};

export const formatFormToAgent = (form: AgentForm): AgentUpdateRequest => {
  const {
    keywords,
    who_speaks_first,
    activation_phrases,

    turn_detector,
    turn_detector_debug_mode,

    check_violations,
    check_agent_violations,

    use_agent_steps,
    max_llm_timeout,
    empty_room_timeout,
    domains_whitelist,
    request_cleaner_code,
    is_record_audio_allowed,
    stt_listen_only_mainuser,

    automute_timeout_sec,
    min_endpointing_delay,

    interrupt_min_words,
    interrupt_speech_duration,

    rag_agent_id,
    rag_collection_id,

    max_endpointing_delay,
    groq_turn_detector_prompt,

    room_life_time_limit,
    domains_white_list,
    stt_primary_model_settings,
    stt_backup_model_settings,
    tts_primary_model_settings,
    tts_backup_model_settings,
    temperature,
    link_mic_audio,
    preemptive_synthesis,
    speechmatics_max_delay,
    speechmatics_additional_vocab,
    clean_user_input_code_box,
    code_box_debug_on,
    tts_fallback_settings,
    ...rest
  } = form;

  return {
    ...rest,
    temperature: temperature ?? 0.3,
    stt_primary_model_settings,
    stt_backup_model_settings,
    tts_primary_model_settings,
    tts_backup_model_settings,

    pipeline_settings: {
      use_agent_steps,
      max_llm_timeout,
      domains_whitelist,
      empty_room_timeout,
      request_cleaner_code,
      is_record_audio_allowed,
      stt_listen_only_mainuser,

      interrupt_min_words,
      automute_timeout_sec,
      min_endpointing_delay,
      interrupt_speech_duration,

      rag_agent_id,
      turn_detector,
      turn_detector_debug_mode,

      rag_collection_id,

      max_endpointing_delay,
      groq_turn_detector_prompt,

      room_life_time_limit,
      speechmatics_max_delay: Number(speechmatics_max_delay) ?? 0.7,
      speechmatics_additional_vocab: speechmatics_additional_vocab
        ? JSON.parse(speechmatics_additional_vocab || '')
        : null,
      check_violations: stringToArray(check_violations || ''),
      check_agent_violations: stringToArray(check_agent_violations || ''),
      domains_white_list: stringToArrayQuote(domains_white_list || ''),
      link_mic_audio: link_mic_audio ?? false,
      preemptive_synthesis: preemptive_synthesis ?? true,
      clean_user_input_code_box,
      code_box_debug_on: code_box_debug_on ?? false,
      tts_fallback_settings: tts_fallback_settings
        ? JSON.parse(tts_fallback_settings || '')
        : null,
    },
    settings: {
      who_speaks_first,
      keywords: convertStringToKeywords(keywords || ''),
      activation_phrases: stringToArray(activation_phrases || ''),
    },
  };
};
