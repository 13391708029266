import React from 'react';

import { Form } from '../../components';
import type { AgentForm as AgentFormType } from '../../utilities';
import type { AiModelData } from '../../api';
import AgentFormInputs from './AgentFormInputs';

export type AgentFormProps = {
  children?: React.ReactNode;

  formId?: string;
  isEdit?: boolean;
  form: AgentFormType;
  onChange: (newForm: AgentFormType) => void;
  onUpdateFlow?: (newFlow: string) => Promise<any>;

  disabled?: boolean;
  updatingFlow?: boolean;
  agent?: any;
  llmModels: AiModelData[];
  ttsModels: AiModelData[];
  sttModels: AiModelData[];
  languages: OptionType[];
  ttsPrimary?: AiModelData;
  ttsBackup?: AiModelData;
  sttPrimary?: AiModelData;
  sttBackup?: AiModelData;
  llmPrimary?: AiModelData;
  llmBackup?: AiModelData;
  turnDetector?: AiModelData;
  validity?: any;
};

const AgentForm: React.FC<AgentFormProps> = ({
  form,
  formId,
  isEdit = true,
  agent,
  onChange,
  onUpdateFlow,
  children,
  updatingFlow,
  ...rest
}) => {
  return (
    <Form key={String(formId || 'NEW')} initialValue={form} onChange={onChange}>
      <AgentFormInputs
        isEdit={isEdit}
        form={form}
        formId={formId}
        updatingFlow={updatingFlow}
        onUpdateFlow={onUpdateFlow}
        onChange={onChange}
        {...rest}
      ></AgentFormInputs>
      {children}
    </Form>
  );
};

export default React.memo(AgentForm) as typeof AgentForm;
