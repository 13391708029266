import React, { useRef, useState } from 'react';

type FileUploadProps = {
  onChange?: (file: File) => void;
  loading?: boolean;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  loading = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setSelectedFile(file);
    setError(null);

    try {
      onChange?.(file);
    } catch (err) {
      setError('File upload failed. Please try again.');
      console.error('File upload error:', err);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      <label className="bg-buttonPrimary hover:bg-buttonPrimaryHover text-contrast font-bold text-small px-4 py-2 rounded-lg cursor-pointer transition">
        Select File
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleFileChange}
          disabled={loading}
        />
      </label>

      {selectedFile && (
        <p className="text-medium text-mainText">{selectedFile.name}</p>
      )}

      {error && <p className="text-sm text-danger">{error}</p>}
    </div>
  );
};
