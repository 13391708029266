import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { useAuth } from '../../hooks';
import { PermissionCheck } from '../../shared';

type ProtectedRouteProps = Omit<
  RouteProps,
  'render' | 'component' | 'children'
> & {
  action?: SpPermission | SpPermission[];
  children?: React.ReactNode;
  component?: React.ComponentType;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  action,
  children,
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) return null;
  if (!isAuthenticated) return <Redirect to="/login" />;

  if (!action || !action.length) {
    return <Route {...rest} children={children} component={Component} />;
  }

  return (
    <Route {...rest}>
      <PermissionCheck action={action} fallback={<Redirect to="/404" />}>
        {Component ? <Component /> : children}
      </PermissionCheck>
    </Route>
  );
};

export default React.memo(ProtectedRoute);
