import React from 'react';

const statusConfig: Record<string, { label: string; color: string }> = {
  SUCCESS: { label: 'Completed', color: 'bg-[#EFFEF0] text-[#0AA619]' },
  FAILURE: { label: 'Failed', color: 'bg-[#FEF5EE] text-[#D5441C]' },
  IN_PROCESS: { label: 'In Progress', color: 'bg-[#F3FBFF] text-[#0261B6]' },
  STARTED: { label: 'STARTED', color: 'bg-[#F3FBFF] text-[#0261B6]' },
  UNKNOWN: { label: 'Unknown', color: 'bg-[#F5F7F9] text-[#667085]' },
  REVOKED: { label: 'Revoked', color: 'bg-yellow-100 text-yellow-500' },
};

type BadgeProps = {
  status: string;
  config?: Record<string, { label: string; color: string }>;
};

export const Badge: React.FC<BadgeProps> = ({
  status,
  config = statusConfig,
}) => {
  const { label, color } = config[status] || statusConfig.UNKNOWN;

  return (
    <span className={`px-4 py-2 rounded-full text-sm font-medium ${color}`}>
      {label}
    </span>
  );
};
