export enum CompressingTaskStatusKey {
  IN_PROCESS = 'processing',
  UNKNOWN = 'unknown',
  SUCCESS = 'completed',
  FAILED = 'failed',
}

export enum TaskType {
  File = 'File',
  Text = 'Text',
}

export enum CompressingTaskType {
  ANALYZE_TEXT = 'analyze_text',
  ANALYZE_FILE = 'analyze_file',
  COMPRESS_FILE = 'compress_file_with_prompt',
  COMPRESS_TEXT = 'compress_text_with_prompt',
}

export const processingTypes = [
  CompressingTaskType.ANALYZE_TEXT,
  CompressingTaskType.ANALYZE_FILE,
];
export const completedTypes = [
  CompressingTaskType.COMPRESS_FILE,
  CompressingTaskType.COMPRESS_TEXT,
];

export const fileTypes = [
  CompressingTaskType.COMPRESS_FILE,
  CompressingTaskType.ANALYZE_FILE,
];

export const statusConfig: Record<
  CompressingTaskStatusKey,
  { label: string; color: string }
> = {
  [CompressingTaskStatusKey.SUCCESS]: {
    label: 'Completed',
    color: 'bg-[#EFFEF0] text-[#0AA619]',
  },
  [CompressingTaskStatusKey.FAILED]: {
    label: 'Failed',
    color: 'bg-[#FEF5EE] text-[#D5441C]',
  },
  [CompressingTaskStatusKey.IN_PROCESS]: {
    label: 'In Progress',
    color: 'bg-[#F3FBFF] text-[#0261B6]',
  },
  [CompressingTaskStatusKey.UNKNOWN]: {
    label: 'Unknown',
    color: 'bg-[#F5F7F9] text-[#667085]',
  },
};
