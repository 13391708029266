import React, { useMemo, useState } from 'react';

import { notify } from '../../app';
import { assignAgentToOrganization } from '../../api';
import { Button, Dropdown, Modal } from '../../components';
import { useAuth, useModal, useRequest } from '../../hooks';

type AssignAgentProps = {
  agentId: string;
};

type AssignAgentPropsListProps = {
  updaing: boolean;
  defaultValue: string;

  onClose: () => void;
  onSubmit: (value: string) => void;
};

const AssignAgentPropsList: React.FC<AssignAgentPropsListProps> = ({
  updaing,
  defaultValue,
  onClose,
  onSubmit,
}) => {
  const [value, setValue] = useState(defaultValue || '');

  const { orgs } = useAuth();
  const options = useMemo<OptionType[]>(() => {
    return (orgs || [])
      .map(o => ({
        value: o.id ?? '',
        label: o.name ?? '',
      }))
      .filter(({ value }) => !!value);
  }, [orgs]);

  return (
    <>
      <Dropdown
        value={value}
        options={options}
        onChange={setValue}
        disabled={updaing}
      />
      <div className="flex flex-col gap-y-md justify-center items-center mt-lg">
        <Button
          label="Save"
          size="large"
          className="w-full"
          onClick={() => onSubmit(value)}
          disabled={!value.length || updaing}
        />
        <Button
          size="large"
          type="light"
          label="Cancel"
          className="w-full"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export const AssignAgent: React.FC<AssignAgentProps> = ({ agentId }) => {
  const { isSuper, organization_id } = useAuth();
  const { isOpened, onOpen, onClose } = useModal();
  const [handleAssignAgent, updaing] = useRequest(
    (_signal, organization_id: string) =>
      assignAgentToOrganization(agentId, organization_id),
    {
      deps: [agentId],
      onSuccess: () => {
        notify.success('Agent assigned to organization');
        onClose();
      },
      onError: () => {
        notify.error('Failed to assign agent to organization');
      },
    }
  );

  if (!isSuper) return null;

  return (
    <>
      <Button
        size="medium"
        type="contrast"
        onClick={onOpen}
        label="Change Agent's Organization"
      />
      <Modal
        size="small"
        open={isOpened}
        onClose={onClose}
        title="Change Agent's Organization"
      >
        <AssignAgentPropsList
          key={String(isOpened)}
          updaing={updaing}
          onClose={onClose}
          onSubmit={handleAssignAgent}
          defaultValue={organization_id || ''}
        />
      </Modal>
    </>
  );
};
