/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalyzeTextRequest } from '../models/AnalyzeTextRequest';
import type { Body_analyze_file_compressor_api_v1_analyze_file_post } from '../models/Body_analyze_file_compressor_api_v1_analyze_file_post';
import type { Body_compress_with_custom_prompt_compressor_api_v1_compress_with_prompt_post } from '../models/Body_compress_with_custom_prompt_compressor_api_v1_compress_with_prompt_post';
import type { Body_upload_file_compressor_api_v1_upload_post } from '../models/Body_upload_file_compressor_api_v1_upload_post';
import type { CompressTextRequest } from '../models/CompressTextRequest';
import type { StatusResponse } from '../models/StatusResponse';
import type { UploadResponse } from '../models/UploadResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CompressorEndpointsService {
    /**
     * Compress Text
     * Compress text asynchronously
     * @param requestBody
     * @returns UploadResponse Successful Response
     * @throws ApiError
     */
    public static compressTextCompressorApiV1CompressTextPost(
        requestBody: CompressTextRequest,
    ): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compressor/api/v1/compress/text',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Upload File
     * Upload a file and start asynchronous compression
     * @param formData
     * @returns UploadResponse Successful Response
     * @throws ApiError
     */
    public static uploadFileCompressorApiV1UploadPost(
        formData: Body_upload_file_compressor_api_v1_upload_post,
    ): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compressor/api/v1/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Analyze Text
     * Analyze text asynchronously
     * @param requestBody
     * @returns UploadResponse Successful Response
     * @throws ApiError
     */
    public static analyzeTextCompressorApiV1AnalyzeTextPost(
        requestBody: AnalyzeTextRequest,
    ): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compressor/api/v1/analyze/text',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Analyze File
     * Upload a file for entity analysis
     * @param formData
     * @returns UploadResponse Successful Response
     * @throws ApiError
     */
    public static analyzeFileCompressorApiV1AnalyzeFilePost(
        formData: Body_analyze_file_compressor_api_v1_analyze_file_post,
    ): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compressor/api/v1/analyze/file',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Compress With Custom Prompt
     * Unified endpoint to compress content from a previous analysis task with a custom prompt.
     * Automatically detects if the original task was a file or text analysis and processes accordingly.
     * @param formData
     * @returns UploadResponse Successful Response
     * @throws ApiError
     */
    public static compressWithCustomPromptCompressorApiV1CompressWithPromptPost(
        formData: Body_compress_with_custom_prompt_compressor_api_v1_compress_with_prompt_post,
    ): CancelablePromise<UploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compressor/api/v1/compress/with-prompt',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel Or Delete Task
     * Cancel a running task or delete a completed/failed task by its ID
     * @param taskId
     * @returns StatusResponse Successful Response
     * @throws ApiError
     */
    public static cancelOrDeleteTaskCompressorApiV1TaskTaskIdDelete(
        taskId: string,
    ): CancelablePromise<StatusResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compressor/api/v1/task/{task_id}',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Tasks
     * List all tasks and their statuses
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listTasksCompressorApiV1TasksGet(): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/tasks',
        });
    }
    /**
     * Get Unified Task Result
     * Get the result of any completed task (compression or analysis)
     * @param taskId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getUnifiedTaskResultCompressorApiV1TaskTaskIdGetResultGet(
        taskId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/task/{task_id}/get-result',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Mongodb Tasks
     * List all tasks from MongoDB with pagination
     *
     * Parameters:
     * - page: Page number (minimum: 1)
     * - limit: Number of items per page (minimum: 1, maximum: 100)
     * - task_name: Filter tasks by task name/type
     * - status: Filter tasks by status
     * @param page
     * @param limit
     * @param taskName
     * @param status
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listMongodbTasksCompressorApiV1MongodbTasksGet(
        page: number = 1,
        limit: number = 10,
        taskName?: (string | null),
        status?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/mongodb/tasks',
            query: {
                'page': page,
                'limit': limit,
                'task_name': taskName,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Mongodb Task
     * Get a specific task from MongoDB by ID
     * @param taskId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getMongodbTaskCompressorApiV1MongodbTasksTaskIdGet(
        taskId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/mongodb/tasks/{task_id}',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Mongodb Tasks By Type
     * List tasks from MongoDB by type with pagination
     * @param taskType
     * @param limit
     * @param skip
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listMongodbTasksByTypeCompressorApiV1MongodbTasksTypeTaskTypeGet(
        taskType: string,
        limit: number = 100,
        skip?: number,
    ): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/mongodb/tasks/type/{task_type}',
            path: {
                'task_type': taskType,
            },
            query: {
                'limit': limit,
                'skip': skip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * List Mongodb Tasks By Status
     * List tasks from MongoDB by status with pagination
     * @param status
     * @param limit
     * @param skip
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listMongodbTasksByStatusCompressorApiV1MongodbTasksStatusStatusGet(
        status: string,
        limit: number = 100,
        skip?: number,
    ): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compressor/api/v1/mongodb/tasks/status/{status}',
            path: {
                'status': status,
            },
            query: {
                'limit': limit,
                'skip': skip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
