import { useState } from 'react';

import { notify } from '../../../app';
import { updatePermissions } from '../../../api';
import { getPermissionsList } from '../../../api';
import { deleteRole, duplicateRole } from '../../../api';
import { getRoles, addRole, updateRole } from '../../../api';
import { useFetch, useModal, useRequest } from '../../../hooks';
import type { RoleDto, PermissionDto, RoleRequest } from '../../../api';

export type RoleType = RoleDto;
export type RoleFormType = RoleRequest;
export type PermissionType = PermissionDto;

export const useRoles = () => {
  const [search, setSearch] = useState('');
  const { isOpened, onClose, onOpen } = useModal();
  const [roleForAction, setRoleForAction] = useState<string | undefined>();

  const {
    isOpened: openedDeleteModal,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useModal();
  const handleOpenDeleteRoleModal = (roleId: string) => {
    setRoleForAction(roleId);
    openDeleteModal();
  };
  const {
    isOpened: openedDuplicateModal,
    onOpen: openDuplicateModal,
    onClose: closeDuplicateModal,
  } = useModal();
  const handleOpenDuplicateRoleModal = (roleId: string) => {
    setRoleForAction(roleId);
    openDuplicateModal();
  };

  const [currentForm, setCurrentForm] = useState<Partial<RoleType> | undefined>(
    undefined
  );
  const currentFormId = currentForm?.id;
  const handleClose = () => {
    onClose();
    setCurrentForm(undefined);
  };

  const {
    data: roles = [],
    intializing: loading,
    loading: reloading,
    refresh,
  } = useFetch(() => getRoles(), []);
  const {
    data: allPermissions = [],
    intializing: loadingPermissions,
    loading: reloadingPermissions,
  } = useFetch(() => getPermissionsList(), []);

  const [handleAddRole, adding] = useRequest(
    (_signal, values: RoleFormType) => addRole(values),
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Role created');
      },
      onError: () => {
        notify.error('Failed to create new role');
      },
      deps: [],
    }
  );
  const [handleUpdateRole, updatingRole] = useRequest(
    (_signal, values: RoleFormType) => {
      return currentFormId
        ? updateRole(currentFormId, {
            name: values.name,
            description: values.description,
          })
        : null;
    },
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Role updated');
      },
      onError: () => {
        notify.error('Failed to update role');
      },
      deps: [currentFormId],
    }
  );
  const [handleUpdateRolePermissions, updating] = useRequest(
    (_signal, roleId: string, newPermissionsIds: string[]) => {
      return updatePermissions(roleId, newPermissionsIds);
    },
    {
      onSuccess: () => {
        refresh();
        notify.success('Role permissions updated');
      },
      onError: () => {
        notify.error('Failed to update role permissions');
      },
      deps: [],
    }
  );
  const [handleDeleteRole, deletingRole] = useRequest(
    _signal => (roleForAction ? deleteRole(roleForAction) : null),
    {
      deps: [roleForAction],
      onSuccess: () => {
        refresh();
        closeDeleteModal();
        notify.success('Role deleted');
      },
      onError: () => {
        notify.error('Failed to delete role');
      },
    }
  );
  const [handleDuplicateRole, duplicating] = useRequest(
    _signal => (roleForAction ? duplicateRole(roleForAction) : null),
    {
      deps: [roleForAction],
      onSuccess: () => {
        refresh();
        closeDuplicateModal();
        notify.success('Role duplicated');
      },
      onError: () => {
        notify.error('Failed to duplicate role');
      },
    }
  );

  return {
    currentForm,
    currentFormId,
    setCurrentForm,

    roles: roles || [],
    allPermissions: allPermissions || [],

    openedDeleteModal,
    closeDeleteModal,
    onDeleteRole: handleDeleteRole,
    openDeleteModal: handleOpenDeleteRoleModal,

    openedDuplicateModal,
    closeDuplicateModal,
    onDuplicateRole: handleDuplicateRole,
    openDuplicateModal: handleOpenDuplicateRoleModal,

    loading: loading || loadingPermissions,
    reloading: reloading || reloadingPermissions,
    updating: duplicating || adding || updating || deletingRole || updatingRole,

    search,
    setSearch,

    isOpened,

    onOpen,
    refresh,
    handleAddRole,
    handleUpdateRole,
    handleUpdateRolePermissions,

    onClose: handleClose,
  };
};
