import { useState, useMemo } from 'react';
import { Clear } from '@mui/icons-material';

import { Input } from '../Input';
import { Dropdown } from '../Dropdown';

interface AutoCompleteProps {
  options: OptionType[];

  value?: string;
  placeholder?: string;
  onChange?: (newValue: string) => void;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  options,

  value,
  onChange,
  ...rest
}) => {
  const [query, setQuery] = useState<string>(value || '');

  const filteredOptions = useMemo(() => {
    if (!query) return options;

    const lowerCaseQuery = query.toLocaleLowerCase('en');
    const collator = new Intl.Collator('en', { sensitivity: 'base' });

    return options.filter(option => {
      const label =
        typeof option.label === 'string'
          ? option.label
          : String(option.label ?? '');

      return (
        collator.compare(label.toLocaleLowerCase('en'), lowerCaseQuery) === 0 ||
        label.toLocaleLowerCase('en').includes(lowerCaseQuery)
      );
    });
  }, [query, options]);

  return (
    <Dropdown
      {...rest}
      emptyMessage="No Options found"
      options={filteredOptions}
      value={value}
      onChange={newValue => {
        onChange?.(newValue);
        setQuery(newValue);
      }}
      renderValue={({ ...props }) => (
        <Input
          {...props}
          label=""
          withBorder
          value={query}
          className="w-full group/autocomplete"
          onChange={setQuery}
          suffix={
            !!query.length && (
              <div
                className="mr-sm pr-xs cursor-pointer shrink-0 invisible group-hover/autocomplete:visible"
                onClick={e => {
                  e.stopPropagation();
                  setQuery('');
                  onChange?.('');
                }}
              >
                <Clear className="hover:bg-buttonLight rounded-full" />
              </div>
            )
          }
          inputClassName="pr-2xl cursor-pointer"
        />
      )}
    />
  );
};
