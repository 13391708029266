import { getCurrentOrg } from '.';
import { ParticipantApiService } from '../services';
import { ConversationApiService } from '../services';
import type { ConversationAudioFile, ParticipantInfoDto } from '../services';
import type { ConversationDto, MessageDto } from '../services';

export type MessageResponse = MessageDto;
export type ConversationResponse = ConversationDto;
export type ParticipantInfoResponse = ParticipantInfoDto;
export type ConversationAudioFileResponse = ConversationAudioFile;
export type GetConversationsFilters = {
  startedAt?: string;
  endedAt?: string;
  completedConversations?: boolean;
  conversationId?: string;
  userId?: string;
  userName?: string;
  agentId?: string;
  agentName?: string;
  agentTitle?: string;
  country?: string;
};

export const getConversationCountries = () => {
  return ConversationApiService.getConversationCountries(getCurrentOrg());
};

export const getConversation = (conversationId: string) => {
  return ConversationApiService.getConversation(
    conversationId,
    getCurrentOrg()
  );
};
export const getConversationParticipantInfo = (externalId: string) => {
  return ParticipantApiService.getParticipantByExternalId(externalId);
};
export const getConversationMessages = (conversationId: string) => {
  return ConversationApiService.getMessagesByConversationId(
    getCurrentOrg(),
    conversationId
  );
};
export const getConversationAudio = (conversationId: string) => {
  return ConversationApiService.getAudioFiles(conversationId);
};
export const getConversations = (
  botMasterId: string,
  page: number,
  size: number,
  filters: GetConversationsFilters,
  sort?: SortState<string>
) => {
  const {
    userId,
    userName,
    endedAt,
    agentId,
    startedAt,
    agentName,
    agentTitle,
    conversationId,
    country,
    completedConversations,
  } = filters;

  return ConversationApiService.getConversations(
    getCurrentOrg(),
    botMasterId,
    startedAt,
    endedAt,
    completedConversations,
    conversationId,
    userId,
    userName,
    agentId,
    agentTitle,
    agentName,
    country,
    page,
    size,
    sort?.name ? [`${sort.name},${sort.direction}`] : []
  );
};
