import { createPortal } from 'react-dom';

import { useNode } from '../../hooks';

type DynamicPageTitleProps = {
  children: React.ReactNode;
};

export const DynamicPageTitle: React.FC<DynamicPageTitleProps> = ({
  children,
}) => {
  const [container] = useNode('#dynamic-page-title');

  return <>{container ? createPortal(children, container) : children}</>;
};
