import React from 'react';

type SliderProps = {
  value?: number;
  min: number;
  max: number;
  step?: number;
  stepOptions: string[];
  onChange?: (value: number) => void;
};

export const Slider: React.FC<SliderProps> = ({
  value = 0,
  min,
  max,
  step = 1,
  stepOptions = [],
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(Number(e.target.value));
  };

  return (
    <div className="w-full flex flex-col items-center">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="w-full appearance-none h-2 bg-gray-300 rounded-lg outline-none transition-all"
      />

      <div className="relative w-full flex justify-between text-gray-500 text-sm mt-2">
        {stepOptions.map((label, index) => {
          const position = (index / (stepOptions.length - 1)) * 100;
          return (
            <span
              key={index}
              className="absolute -translate-x-1/2"
              style={{ left: `${position}%` }}
            >
              {label}
            </span>
          );
        })}
      </div>
    </div>
  );
};
