import clsx from 'clsx';
import React from 'react';

type IconProps = {
  onClick?: () => void;
};

const GridIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      onClick={onClick}
      fill="transparent"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.5"
        y="4"
        width="7"
        height="7"
        rx="2.5"
        strokeWidth="1.5"
        stroke="currentColor"
      />
      <rect
        x="3.5"
        y="14"
        width="7"
        height="7"
        rx="2.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="13.5"
        y="4"
        width="7"
        height="7"
        rx="2.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="13.5"
        y="14"
        width="7"
        height="7"
        rx="2.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

const ListIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      onClick={onClick}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6L4 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 12L4 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 18H4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export type ViewSelectProps = {
  view: ViewType;
  onChangeView: (newView: ViewType) => void;
};

const options: {
  value: ViewType;
  component: React.ComponentType<IconProps>;
}[] = [
  { value: 'grid', component: GridIcon },
  { value: 'list', component: ListIcon },
];

export const ViewSelect: React.FC<ViewSelectProps> = ({
  view = options[0].value,
  onChangeView,
}) => {
  return (
    <div className="flex shrink-0 bg-contrast rounded-card shadow-sm border border-solid border-border overflow-hidden">
      {options.map(option => {
        const active = option.value === view;

        return (
          <div
            key={option.value}
            onClick={() => onChangeView(option.value)}
            className={clsx(
              active ? 'bg-main text-contrast' : 'bg-transparent text-main',
              'cursor-pointer delay-sm transition-default hover:bg-buttonPrimaryHover hover:text-contrast px-6 py-[0.8rem]'
            )}
          >
            <option.component />
          </div>
        );
      })}
    </div>
  );
};
