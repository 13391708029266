export type FormErrorType = string | null | boolean | undefined;

type DefaultErrorProps = {
  error: FormErrorType;
};

export const DefaultError: React.FC<DefaultErrorProps> = ({ error }) => {
  if (!error) return null;

  return <>{error === 'required' ? 'This field is required.' : error}</>;
};
