// Shared cache outside of the function
const cache = new WeakMap<object, Map<string, any[]>>();

export const searchArray = <T>(
  array: T[],
  searchTerm: string,
  keys: (keyof T | string)[]
): T[] => {
  if (!searchTerm.trim()) return array; // Return the full array if the search term is empty

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  // Check if the array is already cached
  let termCache = cache.get(array);

  if (!termCache) {
    termCache = new Map<string, T[]>();
    cache.set(array, termCache);
  }

  // Check if the result for the specific search term is cached
  if (termCache.has(lowerCaseSearchTerm)) {
    return termCache.get(lowerCaseSearchTerm) as T[];
  }

  // Compute the filtered result
  const result = array.filter(item =>
    keys.some(key => {
      const value = getValueByKey(item, key as any);
      if (typeof value === 'string') {
        return value.toLowerCase().includes(lowerCaseSearchTerm);
      }
      if (Array.isArray(value)) {
        const flatArray = flattenArray(value);
        return flatArray.some(
          v =>
            typeof v === 'string' &&
            v.toLowerCase().includes(lowerCaseSearchTerm)
        );
      }
      return false;
    })
  );

  // Cache the result for the specific search term and array reference combination
  termCache.set(lowerCaseSearchTerm, result);
  return result;
};

const getValueByKey = <T>(obj: T, key: string): any => {
  // Support nested keys like 'settings.keywords'
  return key
    .split('.')
    .reduce((o: any, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
};

const flattenArray = (array: any[]): any[] =>
  array.reduce(
    (flat, item) =>
      flat.concat(Array.isArray(item) ? flattenArray(item) : item),
    []
  );
