import { useState, useEffect } from 'react';

type UseDelayParams<T> = {
  value?: T;
  defaultValue?: T;
  delay?: number;
  initWithDefault?: boolean;
};

export const useDelay = <T>({
  value,
  defaultValue,
  delay = 200,
  initWithDefault = false,
}: UseDelayParams<T> = {}) => {
  const [delayedValue, setDelayedValue] = useState<T | undefined>(
    initWithDefault ? defaultValue : (value ?? defaultValue)
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value, defaultValue, delay]);

  return delayedValue;
};
