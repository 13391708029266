import React, { useState } from 'react';

import {
  Form,
  FormItem,
  SubmitButton,
  Button,
  Input,
  Slider,
  Toggle,
} from '../../../../components';
import { sliderStepOptions } from '../const';
import { validateDomains } from '../../../../components/AgentForm/utils/validators';
import { useAuth } from '../../../../hooks';

const MAX_DEPTH = 6;

export type FormProps = {
  is_include_links?: boolean;
  depth?: number | null;
  take_screenshot?: boolean;
  url: string;
  requested_by?: string | null;
};
type TaskFormProps = {
  saving: boolean;
  form?: FormProps | null;
  onCancel: () => void;
  onSave: (form: any) => void;
};

const defaultFormValues: FormProps = {
  is_include_links: true,
  depth: 3,
  take_screenshot: true,
  url: '',
};

const TaskForm: React.FC<TaskFormProps> = ({ saving, onSave, onCancel }) => {
  const { user } = useAuth();

  const [values, setValues] = useState<FormProps>(defaultFormValues);
  const { url, depth, is_include_links } = values;

  const depthValue = depth === MAX_DEPTH ? null : depth;

  const taskData = {
    url,
    depth: depthValue,
    is_include_links,
    requested_by: user?.name,
  };

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<FormProps>
          required
          name="url"
          label="Website URL"
          inline={false}
          validate={validateDomains(['http', 'https'])}
        >
          <Input placeholder="URL" />
        </FormItem>
        <FormItem<FormProps> name="depth" label="" inline={false}>
          <Slider min={0} max={6} stepOptions={sliderStepOptions} />
        </FormItem>

        <FormItem<FormProps>
          name="is_include_links"
          label="Output"
          inline={false}
        >
          <Toggle disabled={false} label="Include links" />
        </FormItem>
        <FormItem<FormProps>
          name="take_screenshot"
          label="Screenshot"
          inline={false}
        >
          <Toggle disabled={false} label="Take screenshot" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            disabled={saving}
            className="w-full"
            label="Scrape"
            onClick={() => onSave(taskData)}
          />
          <Button
            size="large"
            type="contrast"
            label="Cancel"
            className="w-full text-main"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};

export default TaskForm;
