import React, { useState } from 'react';

import { Input, Toggle } from '../../../components';
import { Form, FormItem, SubmitButton, Button } from '../../../components';
import type { OrganizationCreatePayload } from './useOrganizations';

type FormType = OrganizationCreatePayload;
type PartialFormType = Partial<OrganizationCreatePayload>;

type NewOrganizationFormProps = {
  saving: boolean;
  isEdit?: boolean;
  form?: PartialFormType | null;

  onCancel: () => void;
  onSave: (form: FormType) => void;
};

export const NewOrganizationForm: React.FC<NewOrganizationFormProps> = ({
  form,
  saving,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialFormType>(
    form ?? { active: true }
  );

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<PartialFormType>
          required
          name="name"
          label="Name"
          inline={false}
        >
          <Input withBorder placeholder="Enter Organization Name" />
        </FormItem>
        <FormItem<PartialFormType>
          required
          name="active"
          label="Active"
          inline={false}
          labelClassName="max-w-[10rem] min-w-[10rem]"
        >
          <Toggle />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            disabled={saving}
            className="w-full"
            label={form ? 'Update' : 'Create'}
            onClick={() => onSave(values as FormType)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
