import ReactDOM from 'react-dom';
import { useRef } from 'react';
import clsx from 'clsx';
import useOnClickOutside from 'use-onclickoutside';

import { useDelay } from '../../hooks';

type ModalProps = {
  open: boolean;
  onClose?: () => void;

  size?: 'small' | 'medium' | 'big' | 'full';
  title?: string;
  titleClassName?: string;
  headerClassName?: string;
  content?: React.ReactNode;
  contentClassName?: string;
  children?: React.ReactNode;

  headerActions?: React.ReactNode;
};

const sizesMap: { [x in NonNullable<ModalProps['size']>]: string } = {
  full: 'w-full',
  small: 'max-w-[44rem]',
  big: '2xl:max-w-[140rem] xl:max-w-[110rem] lg:max-w-[80rem] sm:max-w-5xl sm:w-full',
  medium: 'lg:max-w-7xl sm:max-w-3xl sm:w-full',
};

const emptyFunction = () => {};

export const Modal: React.FC<ModalProps> = ({
  open,
  title,
  content,
  children,
  size = 'medium',
  headerActions,
  headerClassName,
  titleClassName = 'font-bold text-mainText text-section',
  contentClassName = 'p-7',

  onClose,
}) => {
  // render content first, then open modal
  const delayedOpen = useDelay({
    delay: 50,
    value: open,
    defaultValue: false,
  });

  const ref = useRef<HTMLDivElement>(null as any as HTMLDivElement);
  useOnClickOutside(ref, onClose || emptyFunction);

  const modalElement = (
    <div
      className={clsx(
        delayedOpen
          ? 'visible opacity-100'
          : 'invisible opacity-0 pointer-events-none',
        'duration-500 ease-out transition-all',
        'fixed size-full top-0 start-0 z-[2000] overflow-x-hidden overflow-y-auto bg-black bg-opacity-25'
      )}
      role="dialog"
      tabIndex={!open ? -1 : undefined}
    >
      <div
        className={clsx(
          open ? 'mt-7 opacity-100' : 'opacity-0 -mt-60',
          'flex flex-col items-center justify-center',
          sizesMap[size],
          'duration-500 mt-0 ease-out transition-all h-[calc(100%-5rem)] sm:mx-auto'
        )}
      >
        {!!open && (
          <div
            ref={ref}
            key={String(open)}
            className="max-h-full w-full overflow-hidden flex flex-col bg-contrast shadow-sm rounded-xl pointer-events-auto"
          >
            <div
              className={clsx(
                headerClassName,
                'flex justify-between items-center py-5 px-7'
              )}
            >
              <h3 className={clsx(titleClassName)}>{title}</h3>
              {headerActions && (
                <div className="ml-auto mr-lg pl-lg flex flex-row gap-x-md">
                  {headerActions}
                </div>
              )}
              {!!onClose && (
                <button
                  type="button"
                  onClick={onClose}
                  className="shrink-0 size-12 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="shrink-0 size-6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              )}
            </div>
            <div className={clsx(contentClassName, 'overflow-y-auto')}>
              {children ?? content}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalElement, document.body);
};
