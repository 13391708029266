import clsx from 'clsx';
import { ChevronRightOutlined } from '@mui/icons-material';

import { Link } from '../Link';

type GoToDetailsProps = {
  url: string;
  className?: string;
};

export const GoToDetails: React.FC<GoToDetailsProps> = ({ url, className }) => {
  return (
    <Link
      url={url}
      withIcon={false}
      withDecoration={false}
      className={clsx('px-md', className)}
    >
      <ChevronRightOutlined fontSize="large" />
    </Link>
  );
};
