import { useCallback, useState, useRef } from 'react';

import { useSort } from './useSort';
import { useFetch } from './useFetch';
import { useUrlParam } from './useUrlParam';
import { useUpdateEffect } from './useUpdateEffect';
import { useDebouncedValue } from './useDebouncedValue';

type UseListFetchConfig = {
  initialPage?: number;
  initialSearch?: string;
  initialPageSize?: number;
  initialSort?: SortState<string>;
  initialFilters?: Record<any, any>;

  resultKey?: string;
  settingsKey?: string;
  withQueryParams?: boolean;
  additionalParams?: Record<string, string>;
  deps?: any[];

  loadMore?: boolean;
};

type PageParams = {
  page?: number;
  pageSize?: number;
};

type Pagination = {
  page?: number;
  totalPages?: number;
  perPage?: number;
  totalItems?: number;

  limit: number;
  offset: number;
};

export type WithPaginationResult<T> = {
  total?: number;
  totalElements?: number;
  content?: T[];
};

declare type ListParamsType = {
  search?: string;
  sort?: SortState<string>;
  filters?: Record<any, any>;
  pagination?: { page: number; perPage: number; limit: number; offset: number };

  additionalParams?: Record<string, string>;
};

declare type ListFetchRequestType<T> = (
  params?: ListParamsType,
  cancelToken?: AbortSignal
) => Promise<WithPaginationResult<T>> | WithPaginationResult<T>;

type UseListFetchResult<T> = {
  nodes: T[];
  loading: boolean;
  intializing: boolean;
  reloading: boolean;
  error: Error | null;
  refresh: () => void;

  search: string;
  pagination: Pagination;
  sort?: SortState<string>;
  filters?: Record<any, any>;

  setPage: (page: number) => void;
  setSort: (sort: string) => void;
  setSearch: (search: string) => void;

  setPageSize: (page: number) => void;
  setFilters: (filters: Record<any, any>) => void;
  setPaginationChange: (params: PageParams) => void;
};

const getNodes = <T>(data: any, key: string): T[] => {
  return key ? (data as any)?.[key] : (data?.content ?? []);
};

const useListSort = (config?: UseListFetchConfig) => {
  const [urlSort, setUrlSort] = useUrlParam({
    name: 'sort',
    enable: !!config?.withQueryParams,
    defaultValue: config?.initialSort ?? undefined,
  });
  const { sort, setSort: toggleSort } = useSort(urlSort);

  const handleChange = useCallback(
    (newSort: string) => {
      toggleSort(newSort);
    },
    [toggleSort]
  );

  useUpdateEffect(
    () => {
      setUrlSort(sort.name ? sort : undefined);
    },
    [sort],
    true
  );

  return [sort, handleChange] as const;
};

export const useListFetch = <T>(
  request: ListFetchRequestType<T>,
  config?: UseListFetchConfig
): UseListFetchResult<T> => {
  const [page, setPage] = useUrlParam({
    name: 'page',
    enable: !!config?.withQueryParams,
    defaultValue: config?.initialPage ?? 1,
  });
  const [pageSize, setPageSize] = useUrlParam({
    name: 'pageSize',
    enable: !!config?.withQueryParams,
    defaultValue: config?.initialPageSize ?? 10,
  });

  const loadMoreRef = useRef(false);
  const [sort, setSort] = useListSort(config);
  const [localSearch, setSearch] = useState(config?.initialSearch ?? '');
  const search = useDebouncedValue(localSearch, 500);
  const [filters, setFilters] = useUrlParam({
    name: 'filters',
    enable: !!config?.withQueryParams,
    defaultValue: config?.initialFilters ?? {},
  });
  const setNonEmptyFilters = useCallback<typeof setFilters>(
    (filters = {}) => {
      const nonEmpty = Object.entries(filters).filter(
        ([_k, val]) => val !== ''
      );
      if (!nonEmpty.length) return setFilters(undefined);

      setFilters(Object.fromEntries(nonEmpty));
    },
    [setFilters]
  );
  const loadMore = config?.loadMore;
  const resultKey = config?.resultKey || '';

  const params: ListParamsType = {
    sort,
    search,
    filters,
    additionalParams: config?.additionalParams,
    pagination: {
      page: (page ?? 1) >= 1 ? (page ?? 1) - 1 : 0,
      perPage: pageSize ?? 20,

      limit: pageSize || 1,
      offset: ((page || 1) - 1) * (pageSize || 1),
    },
  };

  const { data, refresh, ...rest } = useFetch(
    token => request?.(params, token),
    [
      page,
      pageSize,
      search,
      sort,
      filters,
      loadMore,
      config?.additionalParams,
      ...(config?.deps ?? []),
    ],
    {
      delay: 250,
      transformData: (newData, old): any => {
        if (!loadMore || !loadMoreRef.current) {
          return newData || null;
        }

        loadMoreRef.current = false;

        const newNodes = getNodes<T>(newData, resultKey);
        const oldNodes = getNodes<T>(old, resultKey);

        return {
          ...old,
          nodes: [...oldNodes, ...newNodes],
        };
      },
    }
  );

  const handleSetPaginationChange = (params: PageParams) => {
    if (params.page !== undefined) {
      setPage(params.page);
    }
    if (params.pageSize !== undefined) {
      setPageSize?.(params.pageSize);
    }
  };

  const handleSetSearch: UseListFetchResult<T>['setSearch'] = (...params) => {
    setPage(1);
    setSearch(...params);
  };

  const handleSetSort: UseListFetchResult<T>['setSort'] = (...params) => {
    setPage(1);
    setSort(...params);
  };

  const handleSetFilters: UseListFetchResult<T>['setFilters'] = (...params) => {
    setPage(1);
    setNonEmptyFilters(...params);
  };
  const handleRefresh = useCallback(
    () => {
      if (!loadMore) return refresh?.();

      setPage(1);
      refresh?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadMore, refresh]
  );

  const totalItems = data?.total ?? data?.totalElements ?? 0;
  const pagination: Pagination = {
    page,
    perPage: pageSize,
    totalItems: totalItems,
    totalPages: totalItems / (pageSize || 1),

    limit: pageSize || 1,
    offset: ((page || 1) - 1) * (pageSize || 1),
  };

  return {
    nodes: getNodes<T>(data, resultKey),

    setPage,
    setPageSize,
    setSort: handleSetSort,
    setSearch: handleSetSearch,
    setFilters: handleSetFilters,
    setPaginationChange: handleSetPaginationChange,

    sort,
    filters,
    search: localSearch,
    pagination: pagination,
    refresh: handleRefresh,

    ...rest,
  };
};
