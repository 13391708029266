/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConversationParticipantDto } from '../models/ConversationParticipantDto';
import type { ConversationParticipantRequest } from '../models/ConversationParticipantRequest';
import type { Pageable } from '../models/Pageable';
import type { ParticipantInfoDto } from '../models/ParticipantInfoDto';
import type { ParticipantInfoRequest } from '../models/ParticipantInfoRequest';
import type { ParticipantWidgetSettingsDto } from '../models/ParticipantWidgetSettingsDto';
import type { ParticipantWidgetSettingsRequest } from '../models/ParticipantWidgetSettingsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ParticipantApiService {
    /**
     * @param externalId
     * @param agentId
     * @returns ParticipantWidgetSettingsDto Success response
     * @throws ApiError
     */
    public static getParticipantWidgetSettings(
        externalId: string,
        agentId: string,
    ): CancelablePromise<ParticipantWidgetSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/participants/{externalId}/widget-settings/{agentId}',
            path: {
                'externalId': externalId,
                'agentId': agentId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param externalId
     * @param agentId
     * @param requestBody
     * @returns ParticipantWidgetSettingsDto Success response
     * @throws ApiError
     */
    public static updateParticipantWidgetSettings(
        externalId: string,
        agentId: string,
        requestBody: ParticipantWidgetSettingsRequest,
    ): CancelablePromise<ParticipantWidgetSettingsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/participants/{externalId}/widget-settings/{agentId}',
            path: {
                'externalId': externalId,
                'agentId': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param externalId
     * @param agentId
     * @param requestBody
     * @returns ParticipantWidgetSettingsDto Success response
     * @throws ApiError
     */
    public static createParticipantWidgetSettings(
        externalId: string,
        agentId: string,
        requestBody: ParticipantWidgetSettingsRequest,
    ): CancelablePromise<ParticipantWidgetSettingsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/participants/{externalId}/widget-settings/{agentId}',
            path: {
                'externalId': externalId,
                'agentId': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param externalId
     * @returns ParticipantInfoDto Success response
     * @throws ApiError
     */
    public static getParticipantByExternalId(
        externalId: string,
    ): CancelablePromise<ParticipantInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/participants/participants/{externalId}',
            path: {
                'externalId': externalId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param externalId
     * @param requestBody
     * @returns ParticipantInfoDto Success response
     * @throws ApiError
     */
    public static updateParticipantByExternalId(
        externalId: string,
        requestBody: ParticipantInfoRequest,
    ): CancelablePromise<ParticipantInfoDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/participants/participants/{externalId}',
            path: {
                'externalId': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param participationId
     * @returns ConversationParticipantDto Success response
     * @throws ApiError
     */
    public static getConversationParticipant(
        participationId: string,
    ): CancelablePromise<ConversationParticipantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/participants/conversation-participants/{participationId}',
            path: {
                'participationId': participationId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param participationId
     * @param requestBody
     * @returns ConversationParticipantDto Success response
     * @throws ApiError
     */
    public static updateConversationParticipant(
        participationId: string,
        requestBody: ConversationParticipantRequest,
    ): CancelablePromise<ConversationParticipantDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/participants/conversation-participants/{participationId}',
            path: {
                'participationId': participationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param pageable
     * @returns ParticipantInfoDto Success response
     * @throws ApiError
     */
    public static getPageableParticipants(
        pageable: Pageable,
    ): CancelablePromise<Array<ParticipantInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/participants/participants',
            query: {
                'pageable': pageable,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ParticipantInfoDto Success response
     * @throws ApiError
     */
    public static createParticipant(
        requestBody: ParticipantInfoRequest,
    ): CancelablePromise<ParticipantInfoDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/participants/participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param pageable
     * @returns ConversationParticipantDto Success response
     * @throws ApiError
     */
    public static getConversationParticipants(
        pageable: Pageable,
    ): CancelablePromise<Array<ConversationParticipantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/participants/conversation-participants',
            query: {
                'pageable': pageable,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ConversationParticipantDto Success response
     * @throws ApiError
     */
    public static createConversationParticipant(
        requestBody: ConversationParticipantRequest,
    ): CancelablePromise<ConversationParticipantDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/participants/conversation-participants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
