import React, { useContext, useMemo } from 'react';

import { useToken } from './useToken';
import { PermissionsProvider } from '../Permissions';
import { useSetPermissions } from './useSetPermissions';
import type { UserOrganizationDto } from '../../api';

export type UserType = {
  name?: string;
  picture?: string;
  app_metadata?: { superAdmin?: boolean };
};

export type UseAuthResult = {
  token: string;
  user?: UserType | null;

  isSuper: boolean;
  isLoading: boolean;
  isAuthorized: boolean;
  isAuthenticated: boolean;

  orgs: UserOrganizationDto[];

  organization_id?: string;
  permissions: SpPermission[];
  setSelectedOrg: (value?: string) => void;

  logout: () => void;
  loginWithRedirect: () => void;
};

const UserContext = React.createContext<UseAuthResult>({
  token: '',

  isSuper: false,
  isLoading: false,
  isAuthorized: false,
  isAuthenticated: false,

  logout: () => {},
  loginWithRedirect: () => {},

  orgs: [],
  permissions: [],
  organization_id: '',
  setSelectedOrg: () => {},
});

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const tokenValue = useToken();
  const userValue = useSetPermissions(
    tokenValue.token,
    tokenValue.isLoading,
    tokenValue.loginWithRedirect
  );

  const value = useMemo(() => {
    const isSuper = !!tokenValue?.user?.app_metadata?.superAdmin;
    return {
      ...tokenValue,
      ...userValue,
      isSuper: isSuper,
      isLoading: tokenValue.isLoading || !userValue.inited,
      isAuthorized:
        isSuper ||
        !!userValue.orgs.some(o => o.roles?.some(r => !!r.permissions?.length)),
    };
  }, [tokenValue, userValue]);

  return (
    <UserContext.Provider value={value}>
      <PermissionsProvider
        key={value.organization_id}
        permissions={value.permissions.map(p => ({
          action: p,
          allowed: true,
        }))}
      >
        {children}
      </PermissionsProvider>
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);
export { useUserSettings } from './useUserSettings';
