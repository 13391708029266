import { AddSharp } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';

import { formatDate } from '../../utilities';
import { Status, Table, MoreActions } from '../../components';
import type { UserListType } from '../../api';

type UsersTabProps = {
  loading: boolean;
  updating: boolean;

  users: UserListType[];
  onOpenAddRoleModal: (user: UserListType) => void;
  onOpenRemoveRoleModal: (user: UserListType) => void;

  onOpenUnassignModal: (user: UserListType) => void;
};

export const UsersTab: React.FC<UsersTabProps> = ({
  users,
  loading,
  updating,

  onOpenAddRoleModal,
  onOpenRemoveRoleModal,

  onOpenUnassignModal,
}) => {
  return (
    <>
      <Table<UserListType>
        data={users}
        loading={loading}
        emptyMessage="There are no users."
        config={{
          columns: [
            {
              sortName: 'title',
              header: 'Name',
              cellClassName: 'min-w-[20rem]',
              getValue: ({ username }) => <span>{username}</span>,
            },
            {
              header: 'Roles',
              cellClassName: 'min-w-[35rem]',
              getValue: ({ organizationRoles }) => (
                <>
                  {organizationRoles?.map(organization => (
                    <div key={organization.id}>
                      {organization.name}:{' '}
                      {organization.roles?.map(r => r.name).join(', ') ||
                        'No Roles'}
                    </div>
                  ))}
                </>
              ),
            },
            {
              header: 'Last Active',
              getValue: ({ lastActivity }) => formatDate(lastActivity),
            },
            {
              header: 'Status',
              getValue: ({ enabled }) => <Status active={enabled} />,
            },
            {
              header: '',
              getValue: row => (
                <MoreActions
                  options={[
                    {
                      icon: <AddSharp />,
                      label: 'Assign Role',
                      loading: updating,
                      onClick: () => onOpenAddRoleModal(row),
                    },
                    {
                      icon: <RemoveIcon />,
                      label: 'Remove Role',
                      loading: updating,
                      onClick: () => onOpenRemoveRoleModal(row),
                    },
                    {
                      icon: <DeleteIcon />,
                      label: 'Remove from Org',
                      loading: updating,
                      onClick: () => onOpenUnassignModal(row),
                    },
                  ]}
                />
              ),
            },
          ],
        }}
      />
    </>
  );
};
