import React from 'react';
import { Avatar } from '@mui/material';
import { format } from 'date-fns';

import { Badge, Table } from '../../../../components';
import CompressorActions from './CompressorActions';
import { formatDate, getTaskTypeLabel } from '../utils/utils';
import { statusConfig } from '../utils/const';
import type { TaskProps } from './TextCompressorTabs';
import type { CompressTaskData } from './PreprocessingList';

export type CompletedListProps = {
  getTaskResultWithPromptById: (taskId: string) => Promise<any>;
  loading?: boolean;
  completedTasks: TaskProps[];
  openDeleteModal: (id: string) => void;
  actionLoading: boolean;
  handleCompressClick: (data: CompressTaskData) => void;
};

export const CompletedList: React.FC<CompletedListProps> = ({
  getTaskResultWithPromptById,
  loading,
  completedTasks,
  openDeleteModal,
  actionLoading,
  handleCompressClick,
}) => {
  const handleDownload = async (taskId: string) => {
    const response = await getTaskResultWithPromptById(taskId);

    if (response?.compressed_text) {
      saveCompressedTextToFile(response.compressed_text);
    }
  };

  const saveCompressedTextToFile = (text: string) => {
    const formattedDate = format(new Date(), 'dd-MM-yyyy_HH-mm');
    const filename = `compressed_${formattedDate}.txt`;
    const blob = new Blob([text], { type: 'text/plain' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4">
      <div className="overflow-x-auto">
        <Table
          data={completedTasks}
          loading={loading}
          emptyMessage="There are no tasks yet."
          config={{
            columns: [
              {
                header: 'ID',
                cellClassName: 'min-w-[5rem] max-w-[25rem] ',
                getValue: ({ task_id }) => (
                  <div className="truncate">{task_id}</div>
                ),
              },
              {
                header: 'Type',
                cellClassName: 'min-w-[6rem] text-left',
                getValue: ({ task_type }) => (
                  <p>{getTaskTypeLabel(task_type)}</p>
                ),
              },

              {
                header: 'Requested by',
                sortName: 'requested_by',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ user_name }) => (
                  <div className="flex items-center">
                    <Avatar
                      sx={{
                        bgcolor: '#3b82f6',
                        width: 28,
                        height: 28,
                        fontSize: '1.5rem',
                        mr: 1,
                      }}
                    >
                      {user_name?.[0]}
                    </Avatar>
                    <span>{user_name || 'Unknown'}</span>
                  </div>
                ),
              },
              {
                header: 'Date & Time',
                sortName: 'created_at',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ created_at }) => formatDate(created_at),
              },
              {
                header: 'Status',
                sortName: 'status',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ status }) => (
                  <Badge config={statusConfig} status={status} />
                ),
              },
              {
                header: 'Actions',
                cellClassName: 'min-w-[5rem]',
                getValue: ({ status, task_id }) => (
                  <div className="flex items-center justify-end h-full min-h-[3rem]">
                    <CompressorActions
                      loading={actionLoading}
                      status={status}
                      onDownload={() => handleDownload(task_id)}
                      onDelete={() => openDeleteModal(task_id)}
                      onCompress={() => handleCompressClick({ task_id })}
                    />
                  </div>
                ),
              },
            ],
          }}
        />
      </div>
      {/* <div className="mt-sm flex justify-between items-center">
        <SearchPanel
          totalItems={pagination?.totalItems}
          page={pagination?.page}
          perPage={pagination?.perPage}
          withPaginate
          setPage={setPage}
        />
      </div> */}
    </div>
  );
};

export default CompletedList;
