import React from 'react';
import clsx from 'clsx';
import ReactPaginate from 'react-paginate';

import { Left } from './Left';
import { Right } from './Right';

type PaginatedItemsProps = {
  totalItems?: number;
  itemsPerPage?: number;
  className?: string;

  page?: number;
  setPage?: (page: number) => void;
};

export const Paginate: React.FC<PaginatedItemsProps> = ({
  className,
  totalItems = 1,
  itemsPerPage = 1,

  setPage,
  page = 1,
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (event: any) => {
    setPage?.(event.selected + 1);
  };

  return (
    <>
      <ReactPaginate
        className={clsx(className, 'flex gap-x-3 items-center text-medium')}
        disabledClassName="opacity-20 cursor-not-allowed"
        pageClassName="rounded-sm hover:bg-dark cursor-pointer"
        activeClassName="border-2 border-main hover:bg-contrast"
        pageLinkClassName="flex items-center justify-center p-2 size-11"
        nextClassName="hover:bg-dark p-2 rounded-sm"
        previousClassName="hover:bg-dark p-2 rounded-sm"
        breakLabel="..."
        nextLabel={<Left />}
        pageCount={pageCount || 1}
        pageRangeDisplayed={3}
        previousLabel={<Right />}
        renderOnZeroPageCount={null}
        onPageChange={handlePageClick}
        forcePage={page - 1}
      />
    </>
  );
};
