/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManagementSettingsDto } from '../models/ManagementSettingsDto';
import type { ManagementSettingsRequest } from '../models/ManagementSettingsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManagementSettingsApiService {
    /**
     * @param name
     * @returns ManagementSettingsDto OK
     * @throws ApiError
     */
    public static getManagementSettings(
        name: string,
    ): CancelablePromise<ManagementSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings',
            query: {
                'name': name,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ManagementSettingsDto OK
     * @throws ApiError
     */
    public static updateSettings(
        requestBody: ManagementSettingsRequest,
    ): CancelablePromise<ManagementSettingsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns ManagementSettingsDto OK
     * @throws ApiError
     */
    public static createSettings(
        requestBody: ManagementSettingsRequest,
    ): CancelablePromise<ManagementSettingsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
