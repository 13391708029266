import React from 'react';

type LoadingImgIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const LoadingImgIcon: React.FC<LoadingImgIconProps> = ({
  color = '#5861FA',
  width = 14,
  height = 12,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C8.3768 0 7.87183 0.507605 7.87183 1.1308V3.54098C7.87183 4.16155 8.3771 4.66915 9 4.66915C9.6229 4.66915 10.1282 4.16155 10.1282 3.54098V1.1308C10.1282 0.507605 9.6232 0 9 0Z"
      fill={color}
    />
    <path
      d="M9 13.3308C8.3768 13.3308 7.87183 13.8385 7.87183 14.4616V16.8718C7.87183 17.4924 8.3771 18 9 18C9.6229 18 10.1282 17.4924 10.1282 16.8718V14.4616C10.1282 13.8385 9.6232 13.3308 9 13.3308Z"
      fill={color}
    />
    <path
      d="M4.66915 9C4.66915 8.3768 4.16155 7.87183 3.54098 7.87183H1.1308C0.507605 7.87183 0 8.3771 0 9C0 9.6229 0.507605 10.1282 1.1308 10.1282H3.54098C4.16155 10.1282 4.66915 9.6232 4.66915 9Z"
      fill={color}
    />
    <path
      d="M16.8718 7.87183H14.4616C13.8385 7.87183 13.3308 8.3771 13.3308 9C13.3308 9.6229 13.8385 10.1282 14.4616 10.1282H16.8718C17.4924 10.1282 18 9.6229 18 9C18 8.3771 17.4924 7.87183 16.8718 7.87183Z"
      fill={color}
    />
    <path
      d="M5.13852 11.7333C4.83845 11.7333 4.55385 11.8512 4.34106 12.064L2.63581 13.7666C2.19739 14.2077 2.19739 14.9231 2.63581 15.3642C2.8486 15.577 3.1332 15.6949 3.4359 15.6949C3.73596 15.6949 4.02056 15.577 4.23335 15.3642L5.9386 13.6589C6.1514 13.4461 6.26932 13.1615 6.26932 12.8615C6.26932 12.5588 6.1514 12.2768 5.9386 12.064C5.72319 11.8512 5.44092 11.7333 5.13852 11.7333Z"
      fill={color}
    />
    <path
      d="M12.8615 6.26932C13.1615 6.26932 13.4461 6.1514 13.6589 5.9386L15.3642 4.23335C15.8026 3.7923 15.8026 3.07687 15.3642 2.63581C15.1514 2.42302 14.8668 2.3051 14.5641 2.3051C14.264 2.3051 13.9794 2.42302 13.7666 2.63581L12.064 4.34106C11.8486 4.55385 11.7333 4.83845 11.7333 5.13852C11.7333 5.44122 11.8486 5.72319 12.064 5.9386C12.2768 6.1511 12.5591 6.26932 12.8615 6.26932Z"
      fill={color}
    />
    <path
      d="M13.6589 12.064C13.4461 11.8512 13.1615 11.7333 12.8615 11.7333C12.5588 11.7333 12.2768 11.8512 12.064 12.064C11.8486 12.2768 11.7333 12.5588 11.7333 12.8615C11.7333 13.1615 11.8486 13.4461 12.064 13.6589L13.7666 15.3642C13.9794 15.577 14.264 15.6949 14.5641 15.6949C14.8668 15.6949 15.1514 15.577 15.3642 15.3642C15.8026 14.9231 15.8026 14.2077 15.3642 13.7666L13.6589 12.064Z"
      fill={color}
    />
    <path
      d="M4.23335 2.63581C4.02056 2.42302 3.73596 2.3051 3.4359 2.3051C3.1332 2.3051 2.8486 2.42302 2.63581 2.63581C2.42302 2.8486 2.30772 3.1332 2.30772 3.4359C2.30772 3.73596 2.42302 4.02056 2.63581 4.23335L4.34106 5.9386C4.55385 6.1514 4.83845 6.26932 5.13852 6.26932C5.44122 6.26932 5.72319 6.1514 5.9386 5.9386C6.1514 5.72319 6.26932 5.44122 6.26932 5.13852C6.26932 4.83845 6.1514 4.55385 5.9386 4.34106L4.23335 2.63581Z"
      fill={color}
    />
  </svg>
);
