import { useState } from 'react';

import { notify } from '../../../app';
import { useListFetch, useModal, useRequest } from '../../../hooks';
import {
  addScrapingTask,
  getScrapingList,
  downloadScrapingTask,
  retryScrapingTask,
  cancelScrapingTask,
} from '../../../api';
import type { FormProps } from './components/TaskForm';
import type { TaskPayload, TaskStatusValue } from '../../../api';

const processStringValue = (string: string): string | undefined => {
  if (typeof string !== 'string') return string;
  return string?.length ? string : undefined;
};

export const useScraping = () => {
  const { isOpened, onClose, onOpen } = useModal();

  const [currentForm, setCurrentForm] = useState<
    Partial<TaskPayload> | undefined
  >(undefined);
  const handleClose = () => {
    onClose();
    setCurrentForm(undefined);
  };

  const {
    nodes: scrapingList = [],
    intializing: loadingScraping,
    loading: reloadingScraping,
    refresh,
    ...rest
  } = useListFetch(
    params =>
      getScrapingList(
        (params?.pagination?.page ?? 1) + 1,
        params?.pagination?.perPage ?? 10,
        processStringValue(params?.filters?.url) || null,
        (processStringValue(params?.filters?.status) as TaskStatusValue) || null
      ),
    {
      deps: [],
      withQueryParams: true,
    }
  );

  const [handleAddScrapingTask, addingScraping] = useRequest(
    (
      _signal,
      { url, depth, is_include_links, requested_by, take_screenshot }: FormProps
    ) =>
      addScrapingTask(
        [url],
        depth,
        is_include_links,
        requested_by,
        take_screenshot
      ),
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('New Scraping task created');
      },
      onError: () => {
        notify.error('Failed to create new Scraping task');
      },
      deps: [],
    }
  );

  const [handleretryScrapingTask, retringScrapingTask] = useRequest(
    (_signal, taskId: string) => retryScrapingTask(taskId),
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Scraping task recreated successfully');
      },
      onError: () => {
        notify.error('Failed to recreate Scraping task');
      },
      deps: [],
    }
  );

  const [handleCancelScrapingTask, cancelingScrapingTask] = useRequest(
    (_signal, taskId: string) => cancelScrapingTask(taskId),
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('Scraping task camceled successfully');
      },
      onError: () => {
        notify.error('Failed to cancel task');
      },
      deps: [],
    }
  );

  const [handleDownloadScrapingTask, downloadingScraping] = useRequest(
    async (_signal, taskId: string) => await downloadScrapingTask(taskId),
    {
      onSuccess: blob => {
        if (!(blob instanceof Blob)) {
          console.error('Unexpected response:', blob);
          notify.error('Invalid response received.');
          return;
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `scraping-task-${Date.now()}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        notify.success('Scraping data downloaded successfully');
      },
      onError: error => {
        console.error('Download error:', error);
        notify.error('Failed to download Scraping data');
      },
      deps: [],
    }
  );

  return {
    currentForm,
    currentFormId: 'scraping',
    setCurrentForm,
    handleCancelScrapingTask,
    cancelingScrapingTask,
    scrapingList: scrapingList || [],
    loading: loadingScraping || reloadingScraping || retringScrapingTask,
    updating: addingScraping,
    downloading: downloadingScraping,

    isOpened,
    onOpen,
    refresh,
    handleAddScrapingTask,
    handleDownloadScrapingTask,
    handleretryScrapingTask,
    onClose: handleClose,
    ...rest,
  };
};
