import { Edit, DeleteOutline } from '@mui/icons-material';

import { PermissionForm } from './PermissionForm';
import { usePermissions } from './usePermissions';
import { ConfirmModal } from '../../../components';
import { DynamicPageTitle } from '../../../components';
import { Table, SearchPanel } from '../../../components';
import { Button, Modal, MoreActions } from '../../../components';

export const PermissionsTab = () => {
  const {
    loading,
    permissions,

    search,
    setSearch,

    isEdit,
    isOpened,
    currentForm,
    currentFormId,
    onOpen,
    onClose,

    updating,
    handleAddPermission,
    handleUpdatePermission,

    setCurrentForm,

    openedDeleteModal,
    closeDeleteModal,
    openDeleteModal,
    deletePermission,
  } = usePermissions();

  return (
    <>
      <DynamicPageTitle>Permissions</DynamicPageTitle>
      <ConfirmModal
        title="Do you want to delete this permission?"
        open={openedDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={deletePermission}
      />
      <div className="flex justify-between items-center mb-sm">
        <SearchPanel
          search={search}
          onSearch={setSearch}
          withPaginate={false}
        />

        <Button
          label="Add Permission"
          onClick={() => {
            onOpen();
            setCurrentForm(undefined);
          }}
          size="medium"
        />
      </div>
      <Modal
        size="small"
        open={isOpened}
        onClose={onClose}
        title={isEdit ? 'Edit permission' : 'Create new permission'}
      >
        <PermissionForm
          key={currentFormId ?? 'new'}
          form={currentForm}
          onCancel={onClose}
          saving={updating}
          isEdit={!!currentFormId}
          onSave={isEdit ? handleUpdatePermission : handleAddPermission}
        />
      </Modal>
      <Table
        data={permissions}
        loading={loading}
        emptyMessage="There are no permissions."
        config={{
          columns: [
            { header: 'Name', getValue: ({ name }) => name },
            { header: 'Mnemonic', getValue: ({ mnemonic }) => mnemonic },
            {
              header: 'Description',
              getValue: ({ description }) => description,
            },
            {
              header: '',
              getValue: row => (
                <MoreActions
                  options={[
                    {
                      label: 'Edit',
                      icon: <Edit />,
                      loading: loading || updating,
                      onClick: () => {
                        setCurrentForm(row);
                        onOpen();
                      },
                    },
                    {
                      label: 'Delete',
                      loading: loading || updating,
                      icon: <DeleteOutline />,
                      onClick: () => {
                        openDeleteModal(row.id!);
                      },
                    },
                  ]}
                />
              ),
            },
          ],
        }}
      />
    </>
  );
};

export default PermissionsTab;
