import DatePicker from 'react-datetime-picker';
import clsx from 'clsx';
import type { DateTimePickerProps } from 'react-datetime-picker';

import { Clear } from './Clear';
import { Calendar } from './Calendar';

export type DatepickerComponentProps = {
  value?: Date;
  onChange?: (newValue: Date | null) => void;
} & Omit<
  DateTimePickerProps,
  | 'value'
  | 'format'
  | 'locale'
  | 'onChange'
  | 'clearIcon'
  | 'disableClock'
  | 'calendarIcon'
  | 'calendarClassName'
>;

export const DatepickerComponent: React.FC<DatepickerComponentProps> = ({
  value,
  onChange,

  disabled,
  className,
  ...rest
}) => {
  return (
    <DatePicker
      {...rest}
      disabled={disabled}
      value={value}
      disableClock
      onChange={onChange!}
      clearIcon={<Clear />}
      format="y/MM/dd HH:mm"
      calendarIcon={<Calendar />}
      className={clsx(
        className,
        'rounded-bt',
        'shadow-sm border border-solid border-border',
        'py-[0.55rem] px-6 bg-contrast outline-none w-full text-mainText text-medium appearance-none',
        disabled ? 'opacity-40' : ''
      )}
    />
  );
};
