import { DynamicPageTitle } from '../../components';

const Page404 = () => {
  return (
    <>
      <DynamicPageTitle>404</DynamicPageTitle>
      <div className="text-section font-bold text-mainText">Page Not Found</div>
    </>
  );
};

export default Page404;
