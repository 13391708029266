import { useState } from 'react';

import { notify } from '../../../app';
import { languages } from '../../../utilities';
import { useFetch, useModal } from '../../../hooks';
import { useRequest, useModels } from '../../../hooks';
import { getAgent, editAgent, editAgentFlow } from '../../../api';
import { formatAgentToForm, formatFormToAgent } from '../../../utilities';
import type { AgentResponse } from '../../../api';
import type { AgentForm } from '../../../utilities';
import { useModelsById } from '../../../components/AgentForm/utils/getModelById';
import { useSyncedModelSettings } from './useSyncedModelSettings';

export const useAgent = (id: string) => {
  const [formId, setFormId] = useState(1);
  const { isOpened, onClose, onOpen } = useModal();
  const [form, setForm] = useState<AgentForm | null>(null);

  const { llmModels, ttsModels, sttModels, loadingModels } = useModels();

  const {
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
    validity,
  } = useModelsById({
    form,
    ttsModels,
    sttModels,
    llmModels,
  });

  const [handleCopyFromAgent, copying] = useRequest(
    (_token, id: string) => getAgent(id),
    {
      deps: [],
      onSuccess: copyFromAgent => {
        setFormId(f => f + 1);
        setForm(old => ({
          ...formatAgentToForm(copyFromAgent),
          id: old?.id ?? '',
          title: old?.title ?? '',
        }));
        onClose();
      },
    }
  );

  const {
    intializing,
    data,
    refresh: refreshAgent,
    setData: setAgentData,
  } = useFetch(() => getAgent(id), [id], {
    onSuccess: newData => {
      setForm(formatAgentToForm(newData));
      setFormId(f => f + 1);
    },
  });
  const [handleUpdateAgent, editing] = useRequest(
    () => {
      if (!form) return null;
      const payload = formatFormToAgent(form);

      return editAgent(id, payload);
    },
    {
      deps: [id, form, data],
      onSuccess: result => {
        if (!result) return notify.info('No changes to save');

        notify.success('Agent updated');
        refreshAgent();
      },
      onError: () => notify.error('Failed to update agent'),
    }
  );
  const [handleUpdateFlow, updatingFlow] = useRequest(
    async (_signal, conversation_flow: string, needMessage = false) => {
      await editAgentFlow(data?.id!, conversation_flow);

      setAgentData(old => {
        const thisOld: AgentResponse = old as any;

        return {
          ...thisOld,
          conversation_flow: conversation_flow
            ? JSON.parse(conversation_flow)
            : undefined,
        };
      });

      return { needMessage };
    },
    {
      deps: [data?.pipeline_settings, data?.id],
      onSuccess: ({ needMessage }) => {
        needMessage && notify.success('Flow saved');
      },
    }
  );
  useSyncedModelSettings({
    form,
    data,
    setForm,
    setFormId,
    models: {
      ttsPrimary,
      ttsBackup,
      sttPrimary,
      sttBackup,
      llmPrimary,
      llmBackup,
      turnDetector,
    },
  });

  const handleCopy = (id: string) => handleCopyFromAgent(id);

  return {
    showCopyModal: isOpened,
    openCopyModal: onOpen,
    closeCopyModal: onClose,

    form,
    formId: String(formId),

    copying,
    updatingFlow,
    editing: editing || updatingFlow,
    loading: intializing || loadingModels,

    agent: data,

    onChange: setForm,
    onCopy: handleCopy,
    onSubmit: handleUpdateAgent,

    onUpdateFlow: handleUpdateFlow,
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
    validity,
    languages,
    llmModels: llmModels || [],
    ttsModels: ttsModels || [],
    sttModels: sttModels || [],
  };
};
