import { useMemo, useState } from 'react';
import { useAgents } from './useAgents';
import {
  Table,
  Button,
  Link,
  PermissionCheck,
  Toggle,
  SearchPanel,
  DynamicPageTitle,
  GoToDetails,
} from '../../components';

const AgentsPage = () => {
  const {
    agents,
    loading,

    sort,
    setSort,

    search,
    setSearch,
  } = useAgents();
  const [showDisabledAgents, setShowDisabledAgents] = useState(false);

  const filteredAgents = useMemo(
    () => (showDisabledAgents ? agents : agents?.filter(a => a.active)),
    [agents, showDisabledAgents]
  );

  return (
    <>
      <DynamicPageTitle>Agents List</DynamicPageTitle>
      <div className="flex justify-between items-center mb-sm">
        <div className="flex gap-x-md">
          <SearchPanel
            search={search}
            onSearch={setSearch}
            withPaginate={false}
          />
          <Toggle
            value={showDisabledAgents}
            onChange={() => setShowDisabledAgents(prev => !prev)}
            label="Show Disabled Agents"
          />
        </div>
        <PermissionCheck action="create_agents">
          <Button label="Add New" url="/agents/new" size="medium" />
        </PermissionCheck>
      </div>
      <Table
        sort={sort}
        onSort={setSort}
        loading={loading}
        data={filteredAgents ?? []}
        verticalAlign="top"
        emptyMessage="No Agents"
        config={{
          columns: [
            // {
            //   header: 'Name',
            //   sortName: 'name',
            //   cellClassName: 'font-semibold max-w-[16rem] min-w-[14rem] px-sm',
            //   getValue: ({ id, title, active }) => (
            //     <>
            //       {active ? '✅' : '❌'}
            //       <Link
            //         withIcon
            //         withDecoration
            //         children={title}
            //         url={`/agents/${id}`}
            //         className="ml-xs"
            //       />
            //     </>
            //   ),
            // },
            {
              header: 'Active',
              sortName: 'active',
              cellClassName: 'max-w-[5rem] min-w-[4rem]',
              getValue: ({ active }) => <>{active ? '✅' : '❌'}</>,
            },
            {
              header: 'Title',
              sortName: 'title',
              cellClassName: 'font-semibold max-w-[16rem] min-w-[14rem]',
              getValue: ({ id, title }) => (
                <Link
                  withIcon
                  withDecoration
                  children={title}
                  url={`/agents/${id}`}
                />
              ),
            },
            {
              header: 'Models',
              cellClassName: 'min-w-[20rem] max-w-[25rem]',
              getValue: ({ llm_model, stt_model, tts_model }) => {
                return (
                  <>
                    <div>{llm_model}</div>
                    <div className="mt-3">{stt_model}</div>
                    <div className="mt-3">{tts_model}</div>
                  </>
                );
              },
            },
            {
              header: 'Settings',
              cellClassName: 'max-w-[20rem]',
              getValue: ({ settings }) => {
                return (
                  <div className="flex flex-col gap-y-sm">
                    <div>Speaks first: {settings.who_speaks_first}</div>
                    <div className="truncate">
                      Keywords: {settings.keywords?.join(';')}
                    </div>
                    <div className="truncate">
                      Activation phrases:{' '}
                      {settings.activation_phrases?.join(';')}
                    </div>
                  </div>
                );
              },
            },
            {
              header: '',
              cellClassName: 'max-w-[8rem] min-w-[8rem] align-middle',
              getValue: ({ id }) => <GoToDetails url={`/agents/${id}`} />,
            },
          ],
        }}
      />
    </>
  );
};

export default AgentsPage;
