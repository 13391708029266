import { useState } from 'react';
import { notify } from '../../../app/Notifications';
import { useRequest } from '../../../hooks';
import {
  getAgentWidgetSettings,
  updateAgentWidgetSettings,
} from '../../../api';
import type {
  AgentWidgetSettings,
  AgentWidgetSettingsRequestBody,
} from '../../../api';

export const useAgentWidgetSettings = () => {
  const [widgetData, setWidgetData] = useState<AgentWidgetSettings | null>(
    null
  );

  const [getWidgetSettings, initializing] = useRequest(
    (_signal, agentId: string) => getAgentWidgetSettings(agentId),
    {
      onSuccess: (data: AgentWidgetSettings) => {
        setWidgetData(data?.widgetSettings || {});
      },
      onError: () => {
        notify.error('Failed to retrieve widget settings');
      },
    }
  );

  const [handleUpdateWidgetSettings, updating] = useRequest(
    (_signal, agentId: string, data: AgentWidgetSettingsRequestBody) => {
      return updateAgentWidgetSettings(agentId, data);
    },
    {
      onSuccess: (data: AgentWidgetSettings) => {
        notify.success('Widget settings updated successfully');
        setWidgetData(data?.widgetSettings || {});
      },
      onError: () => {
        notify.error('Failed to update widget settings');
      },
    }
  );

  return {
    loading: initializing || updating,
    data: widgetData,
    handleUpdateWidgetSettings,
    getWidgetSettings,
    setWidgetData,
  };
};
