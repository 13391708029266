import React, { useState, useEffect } from 'react';
import BlockIcon from '@mui/icons-material/Block';

import { LoadingImgIcon } from '../../assets/icons';

type ImageProps = {
  src: string;
  alt?: string;
  className?: string;
  loadingSrc?: boolean;
};

export const Image: React.FC<ImageProps> = ({
  loadingSrc = false,
  src,
  alt = 'Image',
  className,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!src) {
      setLoading(false);
      setError(true);
      return;
    }

    const img = new window.Image();
    img.src = src;

    img.onload = () => {
      setLoading(false);
      setError(false);
    };

    img.onerror = () => {
      setLoading(false);
      setError(true);
    };
  }, [src]);

  return (
    <div
      className={`relative w-40 h-20 bg-gray-200 rounded-lg overflow-hidden flex justify-center items-center ${className}`}
    >
      {(loading || loadingSrc) && (
        <div
          className="absolute inset-0 bg-gray-100 flex justify-center items-center transition-opacity duration-700"
          style={{ opacity: loading ? 0.9 : 0 }}
        >
          <div className="w-full h-full bg-gray-200 backdrop-blur-md filter blur-md" />
          <div className="absolute">
            <LoadingImgIcon />
          </div>
        </div>
      )}

      {!loading && !error && (
        <img src={src} alt={alt} className="w-full h-full object-cover" />
      )}

      {error && (
        <div className="absolute inset-0 flex flex-col justify-center items-center bg-gray-300 text-gray-600">
          <BlockIcon fontSize="large" />
        </div>
      )}
    </div>
  );
};
