/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignRoleRequest } from '../models/AssignRoleRequest';
import type { OrganizationCreateRequest } from '../models/OrganizationCreateRequest';
import type { OrganizationDto } from '../models/OrganizationDto';
import type { OrganizationUpdateRequest } from '../models/OrganizationUpdateRequest';
import type { UnassignRoleRequest } from '../models/UnassignRoleRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OrganizationManagementApiService {
    /**
     * @param id
     * @returns OrganizationDto OK
     * @throws ApiError
     */
    public static getOrganizationById(
        id: string,
    ): CancelablePromise<OrganizationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns OrganizationDto OK
     * @throws ApiError
     */
    public static updateOrganization(
        id: string,
        requestBody: OrganizationUpdateRequest,
    ): CancelablePromise<OrganizationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/management/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteOrganization(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/organizations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns OrganizationDto OK
     * @throws ApiError
     */
    public static getAllOrganizations(): CancelablePromise<Array<OrganizationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/organizations',
        });
    }
    /**
     * @param requestBody
     * @returns OrganizationDto OK
     * @throws ApiError
     */
    public static createOrganization(
        requestBody: OrganizationCreateRequest,
    ): CancelablePromise<OrganizationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/organizations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add user to organization
     * Add user to organization
     * @param organizationId
     * @param userId
     * @returns any No content
     * @throws ApiError
     */
    public static addUserToOrganization(
        organizationId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/organizations/{organizationId}/users',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'userId': userId,
            },
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param organizationId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static assignUserRole(
        organizationId: string,
        requestBody: AssignRoleRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/organizations/{organizationId}/roles',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove user's role from organization
     * Remove related organization's roles from user
     * @param organizationId
     * @param requestBody
     * @returns any No content
     * @throws ApiError
     */
    public static unassignUserRole(
        organizationId: string,
        requestBody: UnassignRoleRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/organizations/{organizationId}/roles',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Remove user from organization
     * Remove user from organization and removes related organization's roles from user
     * @param organizationId
     * @param userId
     * @returns any No content
     * @throws ApiError
     */
    public static unassignUserFromOrganization(
        organizationId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/organizations/{organizationId}/users/{userId}',
            path: {
                'organizationId': organizationId,
                'userId': userId,
            },
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }
}
