import { CircularProgress, Box } from '@mui/material';

type Props = {
  className?: string;
  height?: string | number;
};

export const Loading: React.FC<Props> = ({ className, height = '100vh' }) => {
  return (
    <Box
      display="flex"
      height={height}
      alignItems="center"
      justifyContent="center"
      className={className}
    >
      <CircularProgress />
    </Box>
  );
};
