import React from 'react';

import { FlowInput } from './FlowInput';
import {
  Card,
  FormItem,
  SettingsBuilder,
  Dropdown,
  Input,
  Toggle,
  Text,
  EditableInput,
} from '..';
import {
  validateSpeechmaticsMaxDelay,
  validateSpeechmaticsString,
} from './utils/validateSpeechmatics';
import { validateJSONInput } from './utils/validateTtsFallbackSettings';
import { validateDomains, validateViolations } from './utils/validators';
import type { AiModelData } from '../../api';
import type { AgentForm as AgentFormType } from '../../utilities';

export type AgentFormProps = {
  children?: React.ReactNode;
  agent?: any;
  formId?: string;
  isEdit?: boolean;
  form: AgentFormType;
  onChange: (newForm: AgentFormType) => void;
  onUpdateFlow?: (newFlow: string) => Promise<any>;

  disabled?: boolean;
  updatingFlow?: boolean;

  llmModels: AiModelData[];
  ttsModels: AiModelData[];
  sttModels: AiModelData[];
  languages: OptionType[];
  ttsPrimary?: AiModelData;
  ttsBackup?: AiModelData;
  sttPrimary?: AiModelData;
  sttBackup?: AiModelData;
  llmPrimary?: AiModelData;
  llmBackup?: AiModelData;
  turnDetector?: AiModelData;
  validity?: any;
};

const AgentFormInputs: React.FC<AgentFormProps> = ({
  form,
  isEdit = true,
  onUpdateFlow,

  children,
  disabled,
  updatingFlow,

  llmModels,
  ttsModels,
  sttModels,
  languages,
  ttsPrimary,
  ttsBackup,
  sttPrimary,
  sttBackup,
  llmPrimary,
  llmBackup,
  turnDetector,
  validity,
}) => {
  return (
    <>
      <Card
        collapsable
        name="agentpage.general"
        defaultCollapsed={false}
        size="unset"
        title="General"
        withOverflow={false}
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure general Agent settings"
      >
        {isEdit && (
          <FormItem<AgentFormType> name="id" label="Agent ID">
            <Input disabled />
          </FormItem>
        )}

        <FormItem<AgentFormType> name="agent_name" label="Agent Name" required>
          <Input disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="title" label="Agent Title" required>
          <Input disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="use_agent_steps" label="Use Agent Steps">
          <Toggle disabled={disabled} />
        </FormItem>
        {!!isEdit && (
          <FormItem<AgentFormType>
            name="conversation_flow"
            label=" Agent steps flow JSON"
          >
            <FlowInput
              onSave={onUpdateFlow}
              loading={updatingFlow}
              disabled={disabled || updatingFlow}
            />
          </FormItem>
        )}
        <FormItem<AgentFormType>
          name="initial_user_prompt"
          label="Initial User Prompt"
        >
          <Input disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="system_prompt" label="System Prompt">
          <EditableInput multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="rag" label="Inprompt knowledge base">
          <EditableInput multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="active" label="Agent Active">
          <Toggle disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="preemptive_synthesis"
          label="Preemtive synthesis"
        >
          <Toggle disabled={disabled} />
        </FormItem>
      </Card>

      {/* LLM Model Section */}
      <Card
        collapsable
        size="unset"
        title="Agent LLM"
        name="agentpage.Agent LLM"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure Agent LLMs"
      >
        <FormItem<AgentFormType>
          name="llm_primary_model_id"
          label="Primary LLM Model"
          required
        >
          <Dropdown options={llmModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Primary LLM Model Settings Builder"
          name="agentpage.imaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.llmPrimary}
        >
          <SettingsBuilder
            name="llm_primary_model_settings"
            schemaData={{
              jsonSchema: llmPrimary?.jsonSchema ?? {},
              uiSchema: llmPrimary?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="llm_backup_model_id"
          label="Secondary LLM Model"
          required
        >
          <Dropdown options={llmModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Primary LLM Model Settings Builder"
          name="agentpage.imaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.llmBackup}
        >
          <SettingsBuilder
            name="llm_backup_model_settings"
            schemaData={{
              jsonSchema: llmBackup?.jsonSchema ?? {},
              uiSchema: llmBackup?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="max_llm_timeout"
          label="LLM failover switch timeout(seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="temperature"
          label="Temperature"
          required
        >
          <Input numeric disabled={disabled} />
        </FormItem>
      </Card>

      {/* STT Model Section */}
      <Card
        collapsable
        size="unset"
        title="STT"
        name="agentpage.STT"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure speech-to-text models and their settings"
      >
        <FormItem<AgentFormType>
          name="stt_primary_model_id"
          label="Primary STT Model"
          required
        >
          <Dropdown options={sttModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Primary STT Model Settings Builder"
          name="agentpage.imaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.sttPrimary}
        >
          <SettingsBuilder
            name="stt_primary_model_settings"
            schemaData={{
              jsonSchema: sttPrimary?.jsonSchema ?? {},
              uiSchema: sttPrimary?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="stt_backup_model_id"
          label="Secondary STT Model"
          required
        >
          <Dropdown options={sttModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Secondary TTS Model Settings Builder"
          name="agentpage.condaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.sttBackup}
        >
          <SettingsBuilder
            name="stt_backup_model_settings"
            schemaData={{
              jsonSchema: sttBackup?.jsonSchema ?? {},
              uiSchema: sttBackup?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="stt_listen_only_mainuser"
          label="STT listens to only main user (Speechmatics)"
        >
          <Toggle />
        </FormItem>
        <FormItem<AgentFormType> name="keywords" label="Keywords (deepgram)">
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="language" label="Default language">
          <Dropdown disabled={disabled} options={languages} />
        </FormItem>
        <FormItem<AgentFormType>
          name="request_cleaner_code"
          label="User transcribe cleaner code(python)"
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="clean_user_input_code_box"
          label="User input cleaner code box"
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="code_box_debug_on"
          label="User input code box debug mode"
        >
          <Toggle />
        </FormItem>
        <FormItem<AgentFormType>
          name="speechmatics_additional_vocab"
          validate={validateSpeechmaticsString}
          label="Speechmatics additional vocab"
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="speechmatics_max_delay"
          validate={validateSpeechmaticsMaxDelay}
          label="Speechmatics max thinking time"
          required
        >
          <Input numeric disabled={disabled} />
        </FormItem>
      </Card>

      {/* TTS Model Section */}
      <Card
        collapsable
        size="unset"
        title="TTS"
        name="agentpage.TTS"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure speech-to-text models and their settings"
      >
        <FormItem<AgentFormType>
          name="tts_primary_model_id"
          label="Primary TTS Model"
          required
        >
          <Dropdown options={ttsModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Primary TTS Model Settings Builder"
          name="agentpage.imaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.ttsPrimary}
        >
          <SettingsBuilder
            name="tts_primary_model_settings"
            schemaData={{
              jsonSchema: ttsPrimary?.jsonSchema ?? {},
              uiSchema: ttsPrimary?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="tts_backup_model_id"
          label="Secondary TTS Model"
          required
        >
          <Dropdown options={ttsModels} disabled={disabled} />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Secondary TTS Model Settings Builder"
          name="agentpage.condaryModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.ttsBackup}
        >
          <SettingsBuilder
            name="tts_backup_model_settings"
            schemaData={{
              jsonSchema: ttsBackup?.jsonSchema ?? {},
              uiSchema: ttsBackup?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="tts_fallback_settings"
          label="TTS Fallback settings"
          validate={validateJSONInput}
        >
          <Input multiline disabled={disabled} />
        </FormItem>
      </Card>

      {/* Speech recognition settings  */}
      <Card
        collapsable
        size="unset"
        title="Speech recognition settings"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure the model for the assistant"
      >
        <FormItem<AgentFormType>
          name="who_speaks_first"
          label="Who speaks first"
        >
          <Dropdown
            disabled={disabled}
            options={[
              { value: 'AGENT', label: 'Agent' },
              { value: 'USER', label: 'User' },
            ]}
          />
        </FormItem>
        <FormItem<AgentFormType>
          name="activation_phrases"
          label="Activation Phrases"
        >
          <Input multiline disabled={disabled} />
        </FormItem>

        <FormItem<AgentFormType>
          name="interrupt_min_words"
          label="Interrupt min words"
        >
          <Input numeric disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="interrupt_speech_duration"
          label="Interrupt speech duration (seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="min_endpointing_delay"
          label="Min end of speech delay (seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="max_endpointing_delay"
          label="Max end of speech delay (seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType> name="turn_detector" label="Turn Detector">
          <Toggle />
        </FormItem>

        <FormItem<AgentFormType>
          name="groq_turn_detector_prompt"
          label="Turn Detector Prompt"
        >
          <EditableInput disabled={disabled} multiline />
        </FormItem>
        <FormItem<AgentFormType>
          name="llm_turn_detector_model_id"
          label="Turn detector LLM Model"
          required={!!form?.turn_detector}
        >
          <Dropdown
            options={llmModels}
            disabled={disabled || !form?.turn_detector}
          />
        </FormItem>
        <Card
          collapsable
          size="unset"
          subtitle="Turn detector Settings Builder"
          name="agentpage.etectorModelSettingsBuilder"
          contentClassName="flex flex-col gap-y-md"
          disabled={!validity?.turnDetector || !form?.turn_detector}
        >
          <SettingsBuilder
            name="llm_turn_detector_model_settings"
            schemaData={{
              jsonSchema: turnDetector?.jsonSchema ?? {},
              uiSchema: turnDetector?.uiSchema ?? {},
            }}
          />
        </Card>
        <FormItem<AgentFormType>
          name="turn_detector_debug_mode"
          label="Turn Detector Debug Mode"
        >
          <Toggle />
        </FormItem>
      </Card>

      <Card
        collapsable
        size="unset"
        title="Constraints and Limitations"
        contentClassName="flex flex-col gap-y-md"
        name="agentpage.Constraints and Limitations"
        subtitle="This section allows you to configure various Agent constraints and limitations"
      >
        <Text className="text-small font-bold text-mainText pt-4">
          Guardrails
        </Text>
        <FormItem<AgentFormType>
          name="check_violations"
          validate={validateViolations}
          label="GROQ User Input Guardrails"
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="check_agent_violations"
          label="GROQ Agent Output Guardrails"
          validate={validateViolations}
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <Text className="text-small font-bold text-mainText pt-4">
          Data retention
        </Text>
        <FormItem<AgentFormType>
          name="store_user_data"
          label="Store user data(data retention)"
        >
          <Toggle disabled={true} />
        </FormItem>
        <FormItem<AgentFormType>
          name="is_record_audio_allowed"
          label="Store conversation audio recording"
        >
          <Toggle disabled={disabled} />
        </FormItem>
        <Text className="text-small font-bold text-mainText pt-4">
          Room management
        </Text>
        <FormItem<AgentFormType>
          name="room_life_time_limit"
          label="Room life time limit(minutes)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="empty_room_timeout"
          label="Empty room lifetime limit(seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
      </Card>

      {/* Prompts, Code & Web hooks */}
      <Card
        collapsable
        size="unset"
        title="Events Web hooks"
        name="agentpage.Events Web hooks"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure webhooks for some events"
      >
        <FormItem<AgentFormType>
          name="conversation_metadata_extractor_prompt"
          label="End of conversation metadata extractor prompt"
        >
          <EditableInput multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="end_of_conversation_event_handler_endpoint"
          label="End of conversation event handler endpoint"
        >
          <Input disabled={disabled} />
        </FormItem>
      </Card>

      {/* Widget Settings */}
      <Card
        collapsable
        size="unset"
        title="Widget Settings"
        name="agentpage.Widget Settings"
        contentClassName="flex flex-col gap-y-md"
        subtitle="This section allows you to configure widgets"
      >
        <FormItem<AgentFormType>
          name="initial_widget_code_box"
          label="Initial widget code box (overrides initial widget settings JSON ↓)"
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="automute_timeout_sec"
          label="Automute timeout (seconds)"
        >
          <Input numeric roundTo={4} disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="domains_white_list"
          label="Domains white list"
          validate={validateDomains()}
        >
          <Input multiline disabled={disabled} />
        </FormItem>
        <FormItem<AgentFormType>
          name="link_mic_audio"
          label="Sync mic and speaker state"
        >
          <Toggle disabled={disabled} />
        </FormItem>
      </Card>
      {children}
    </>
  );
};

export default React.memo(AgentFormInputs) as typeof AgentFormInputs;
