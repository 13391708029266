/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from './JsonNode';
export type IntentDto = {
    id?: string;
    agent_id?: string;
    function_number?: number;
    handler_function?: IntentDto.handler_function;
    description?: string;
    handler_params?: JsonNode;
};
export namespace IntentDto {
    export enum handler_function {
        CALL_REQUEST = 'call_request',
        AGENT_SWITCH = 'agent_switch',
        REST_API_CALL = 'rest_api_call',
        UPDATE_WIDGET_STATE = 'update_widget_state',
        UPDATE_TTS_OPTIONS = 'update_tts_options',
        CHANGE_LANGUAGE = 'change_language',
    }
}

