import clsx from 'clsx';

import type { ColumnType } from '.';

export const getTdClassName = (
  column: ColumnType<any>,
  withHoverBorder: boolean,
  verticalAlign: string
) => {
  return clsx(
    'py-7 px-4',
    'text-medium text-mainText',
    column.cellClassName,
    'transition-default',
    withHoverBorder ? 'group-hover/tablerow:border-main' : '',
    'border-solid border-b-2 border-t-2 border-transparent',
    column.align === 'right' ? 'text-right' : 'text-left',
    verticalAlign === 'top' ? 'align-top' : 'align-middle'
  );
};
