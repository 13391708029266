import Markdown from 'react-markdown';

import Button from '../Button';
import { Modal } from '../Modal';
import { useModal } from '../../hooks';
import type { ButtonProps } from '../Button';

type ButtonModalProps = Omit<ButtonProps, 'label'> & {
  label: string;
  content: string;
};

export const ButtonWithModal: React.FC<ButtonModalProps> = ({
  label,
  content,
}) => {
  const { isOpened, onOpen, onClose } = useModal();

  return (
    <>
      <Button label={label} onClick={onOpen} />
      <Modal open={isOpened} onClose={onClose} title={label}>
        <div className="prose max-w-none text-small text-gray-700">
          <Markdown>{content}</Markdown>
        </div>
      </Modal>
    </>
  );
};
