import clsx from 'clsx';

type StatusProps = {
  active?: boolean;
};

const classNames: { [x in string]: string } = {
  true: 'bg-[#EFFEF0] text-[#0AA619]',
  false: 'bg-[#FEF5EE] text-[#D5441C]',
};

export const Status: React.FC<StatusProps> = ({ active }) => {
  return (
    <div
      className={clsx(
        classNames[String(active ?? false)],
        'rounded-tablet px-md py-xs grow-0 w-fit text-tiny font-medium'
      )}
    >
      {active ? 'Active' : 'Inactive'}
    </div>
  );
};
