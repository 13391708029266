import { IntentsAndHandlersApiService } from '../services';
import type { IntentDto, IntentFunctionRequest } from '../services';

export type IntentRowType = IntentDto;
export type IntentPayloadType = IntentFunctionRequest;
export type HandlerFunctionsEnum = IntentFunctionRequest.handler_function;

export const getIntentsAndHandlers = (agentId: string) => {
  return IntentsAndHandlersApiService.getIntentsByAgentId(agentId);
};
export const addIntent = (
  intent: Partial<IntentPayloadType>,
  agentId: string
) => {
  return IntentsAndHandlersApiService.addIntent({
    ...intent,
    agent_id: agentId,
  } as IntentPayloadType);
};
export const editIntent = (intent: IntentPayloadType, intentId: string) => {
  return IntentsAndHandlersApiService.updateIntent(intentId, intent);
};
export const deleteIntent = (intentId: string) => {
  return IntentsAndHandlersApiService.deleteIntent(intentId);
};
