import { DynamicPageTitle } from '../../../components';
import TextCompressorTabs from './components/TextCompressorTabs';

export const CompressorTab = () => {
  return (
    <div>
      <DynamicPageTitle>Text Compressor</DynamicPageTitle>
      <TextCompressorTabs />
    </div>
  );
};

export default CompressorTab;
