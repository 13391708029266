import {
  Dropdown,
  Form,
  FormItem,
  Input,
  Datepicker,
  AutoComplete,
  Container,
} from '../../components';
import { useConversationCountries } from './useConversations';

type FiltersEnum =
  | 'conversation_id'
  | 'conversation_title'
  | 'country'
  | 'user_id'
  | 'user_name'
  | 'agent_id'
  | 'agent_title'
  | 'started_at'
  | 'ended_at'
  | 'less_than'
  | 'more_than'
  | 'equal'
  | 'completed_conversations';

type FilterProps = {
  agentsOptions: OptionType[];

  values: Record<FiltersEnum, any>;
  onChange: (newValues: Record<FiltersEnum, any>) => void;
};

export const Filter: React.FC<FilterProps> = ({
  agentsOptions,
  values,
  onChange,
}) => {
  const countries = useConversationCountries();

  return (
    <div className="mb-sm grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-md">
      <Form initialValue={values} onChange={onChange}>
        {/* <FormItem inline={false} name="bot_master_id" label="Bot master ID">
          <Input />
        </FormItem> */}
        <Container>
          <FormItem
            inline={false}
            name="conversation_id"
            label="Conversation ID"
          >
            <Input />
          </FormItem>
          <FormItem
            inline={false}
            name="conversation_title"
            label="Conversation Title"
          >
            <Input disabled />
          </FormItem>

          <FormItem inline={false} label="Country" name="country">
            <AutoComplete options={countries} />
          </FormItem>
        </Container>

        <Container>
          <FormItem inline={false} name="user_id" label="User ID">
            <Input />
          </FormItem>
          <FormItem inline={false} name="user_name" label="User Name">
            <Input />
          </FormItem>
        </Container>

        <Container>
          <FormItem inline={false} name="agent_id" label="Agent ID">
            <Input />
          </FormItem>
          <FormItem
            inline={false}
            name="agent_title"
            label="Agent Title"
            className="col-span-1 md:col-span-2"
          >
            <AutoComplete options={agentsOptions || []} />
          </FormItem>
        </Container>

        <Container>
          <FormItem inline={false} name="started_at" label="Started at">
            <Datepicker />
          </FormItem>
          <FormItem inline={false} name="ended_at" label="Ended at">
            <Datepicker
              disabled={!values.completed_conversations}
              cleanOnDisable
            />
          </FormItem>
          <div>
            <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">
              Duration in minutes
            </p>
            <div className="flex gap-x-sm justify-between">
              <FormItem inline={false} name="less_than" label="Less than">
                <Input defaultSizeClass="max-w-[8rem]" numeric />
              </FormItem>
              <FormItem inline={false} name="more_than" label="More than">
                <Input defaultSizeClass="max-w-[8rem]" numeric />
              </FormItem>
              <FormItem inline={false} name="equal" label="Equal">
                <Input defaultSizeClass="max-w-[8rem]" numeric />
              </FormItem>
            </div>
          </div>
        </Container>

        <Container>
          <FormItem
            inline={false}
            label="Completed status"
            name="completed_conversations"
          >
            <Dropdown<string | boolean>
              options={[
                { value: true, label: 'Past' },
                { value: false, label: 'Ongoing' },
              ]}
            />
          </FormItem>
        </Container>
      </Form>
    </div>
  );
};
