import React from 'react';
import type { SchemaConfig } from '../types';

interface Props {
  schema: SchemaConfig;
  highlightedProperty: string | null;
}

const SchemaPreview: React.FC<Props> = ({ schema, highlightedProperty }) => {
  return (
    <div className="w-2/3 pl-6">
      <h3 className="font-semibold">Updated Schema:</h3>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-gray-50 p-4 border rounded-md">
          <h4 className="font-semibold">Schema Object</h4>
          <pre
            className={`text-sm overflow-auto p-3 rounded-md  ${
              highlightedProperty ? 'border-2 border-yellow-400' : ''
            }`}
          >
            {JSON.stringify(schema.jsonSchema, null, 2)}
          </pre>
        </div>

        <div className="bg-gray-50 p-4 border rounded-md">
          <h4 className="font-semibold">UI Schema</h4>
          <pre
            className={`text-sm overflow-auto p-3 rounded-md  ${
              highlightedProperty ? 'border-2 border-yellow-400' : ''
            }`}
          >
            {JSON.stringify(schema.uiSchema, null, 2)}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default SchemaPreview;
