import { z } from 'zod';

const SpeechmaticsItemSchema = z
  .object({
    content: z.string().min(1, { message: 'Must not be empty string' }),
    sounds_like: z
      .array(z.string().min(1, { message: 'Must not be empty string' }), {
        message: '"sounds_like" Must be array ',
      })
      .min(1, { message: 'Must not be empty array' })
      .optional(),
  })
  .strict({
    message: 'Allowed keys: "content", "sounds_like"(optional)',
  });

const SpeechmaticsSchema = z
  .array(SpeechmaticsItemSchema)
  .min(1, { message: 'Must not be empty Array' });

export function validateSpeechmaticsString(input: string | undefined) {
  if (!input) return false;

  try {
    const parsedData = JSON.parse(input);
    SpeechmaticsSchema.parse(parsedData);

    return false;
  } catch (error) {
    let message = 'Invalid JSON';
    if (error instanceof z.ZodError) {
      message =
        error.errors?.[0]?.message === 'Required'
          ? 'The key "content" is required'
          : (error.errors?.[0]?.message ?? message);
    } else if (error instanceof SyntaxError) {
      message = 'JSON Syntax Error';
    }
    return message;
  }
}

export const SpeechmaticsMaxDelaySchema = z
  .number()
  .min(0, { message: 'Delay must be a non-negative number' });

export const validateSpeechmaticsMaxDelay = (input?: number) => {
  if (!input) return 'The field is required';

  try {
    SpeechmaticsMaxDelaySchema.parse(Number(input));

    return false;
  } catch (error) {
    let message = 'Invalid JSON';

    if (error instanceof z.ZodError) {
      message = error.errors?.[0]?.message;
    } else if (error instanceof SyntaxError) {
      message = 'JSON Syntax Error';
    }
    return message;
  }
};
