/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentSettingsDto } from './AgentSettingsDto';
import type { JsonNode } from './JsonNode';
export type BaseAgentRequest = {
    agent_master_id: string;
    title?: string;
    agent_name: string;
    description?: string;
    system_prompt?: string;
    initial_user_prompt?: string;
    rag?: string;
    version?: string;
    active: boolean;
    settings?: AgentSettingsDto;
    temperature?: number;
    llm_primary_model_id: string;
    llm_primary_model_settings?: JsonNode;
    llm_backup_model_id?: string;
    llm_backup_model_settings?: JsonNode;
    llm_turn_detector_model_id?: string;
    llm_turn_detector_model_settings?: JsonNode;
    stt_primary_model_id: string;
    stt_primary_model_settings?: JsonNode;
    stt_backup_model_id?: string;
    stt_backup_model_settings?: JsonNode;
    tts_primary_model_id: string;
    tts_primary_model_settings?: JsonNode;
    tts_backup_model_id?: string;
    tts_backup_model_settings?: JsonNode;
    pipeline_settings?: JsonNode;
    end_of_conversation_event_handler_endpoint?: string;
    initial_widget_code_box?: string;
    conversation_metadata_extractor_prompt?: string;
    language: BaseAgentRequest.language;
    store_user_data?: boolean;
    models_settings?: Record<string, Array<JsonNode>>;
};
export namespace BaseAgentRequest {
    export enum language {
        AR = 'ar',
        EN = 'en',
        DE = 'de',
        ES = 'es',
        UK = 'uk',
        PL = 'pl',
        IT = 'it',
        RU = 'ru',
        ZH = 'zh',
        ZH_CN = 'zh-CN',
        ZH_TW = 'zh-TW',
        DA = 'da',
        NL = 'nl',
        EN_US = 'en-US',
        EN_AU = 'en-AU',
        EN_GB = 'en-GB',
        EN_NZ = 'en-NZ',
        EN_IN = 'en-IN',
        FR = 'fr',
        FR_CA = 'fr-CA',
        HI = 'hi',
        HI_LATN = 'hi-Latn',
        PT = 'pt',
        PT_BR = 'pt-BR',
        ES_419 = 'es-419',
        JA = 'ja',
        KO = 'ko',
        NO = 'no',
        ES_LATAM = 'es-LATAM',
        SV = 'sv',
        TA = 'ta',
        TAQ = 'taq',
        TR = 'tr',
        ID = 'id',
        TH = 'th',
    }
}

