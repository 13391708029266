import React, { useContext, useEffect, useState } from 'react';
import { useCreateSignal } from 'use-create-signal';
import { fromPairs } from 'lodash';
import type { Provider } from 'react';

import { DefaultError } from './DefaultError';
import type { FormErrorType } from './DefaultError';

type FormProps<T> = {
  initialValue?: T;
  onChange?: (newValue: T) => void;
  renderError?: (error: FormErrorType) => React.ReactNode;
  children: React.ReactNode;
};

type ItemMetadata<T, K extends StringKeyOf<T>> = {
  required?: boolean;
  validate?: (value: T[K], values: T) => FormErrorType;
};

type ErrorsType<T extends {}> = {
  [x in StringKeyOf<T>]?: FormErrorType;
};

type FormContextType<T extends {}> = {
  value?: T;
  canSubmit: boolean;
  getValue?: () => T;
  getErrors?: () => ErrorsType<T>;
  onChange?: (newValue: T) => void;
  unregister?: <K extends StringKeyOf<T>>(name: K) => void;
  renderError?: (error: FormErrorType) => React.ReactNode;
  register?: <K extends StringKeyOf<T>>(
    name: K,
    meta?: ItemMetadata<T, K>
  ) => void;
  setError?: (name: StringKeyOf<T>, error: FormErrorType | undefined) => void;
};

type ValidationsType<T extends {}, K extends StringKeyOf<T>> = {
  name: StringKeyOf<T>;
  validate: ItemMetadata<T, K>['validate'];
};

const FormContext = React.createContext<FormContextType<any>>({
  canSubmit: false,
});

const isEmpty = (value: any) => {
  return value === '' || value === null || value === undefined;
};

const defaultRenderError = (error: FormErrorType): React.ReactNode => (
  <DefaultError error={error} />
);

export const Form = <T extends {}>({
  initialValue,
  children,
  onChange,
  renderError = defaultRenderError,
}: FormProps<T>) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [getValues, setValues, value] = useCreateSignal<T>(
    (initialValue ?? {}) as T
  );
  const [getManualErrors, setManualErrors] = useCreateSignal<ErrorsType<T>>({});
  const [getAutoErrors, setAutoErrors] = useCreateSignal<ErrorsType<T>>({});
  const [getValidations, setValidations] = useCreateSignal<
    ValidationsType<T, any>[]
  >([]);
  const [getRequiredFields, setRequiredFields] = useCreateSignal<
    StringKeyOf<T>[]
  >([]);

  const handleChange = (newValues: T) => {
    setValues(newValues);
    onChange?.(newValues);
  };

  const register = <K extends StringKeyOf<T>>(
    name: K,
    { required, validate }: ItemMetadata<T, K> = {}
  ) => {
    setRequiredFields(r =>
      required ? [...r, name] : r.filter(f => f !== name)
    );
    setValidations(r =>
      validate ? [...r, { name, validate }] : r.filter(f => f.name !== name)
    );
  };

  const unregister = (name: StringKeyOf<T>) => {
    setRequiredFields(r => r.filter(f => f !== name));
    setValidations(r => r.filter(f => f.name !== name));
    setManualErrors(prev => {
      const copy = { ...prev };
      delete copy[name];
      return copy;
    });
    setAutoErrors(prev => {
      const copy = { ...prev };
      delete copy[name];
      return copy;
    });
  };

  const validations = getValidations();
  const requiredFields = getRequiredFields();
  useEffect(() => {
    const names: StringKeyOf<T>[] = Array.from(
      new Set([...requiredFields, ...validations.map(v => v.name)])
    );
    const errors = names
      .map(n => {
        const fieldValue = value?.[n];

        if (requiredFields.includes(n)) {
          const empty = !(n in value) || isEmpty(fieldValue);
          if (empty) return [n, 'required'] as const;
        }

        const validate = validations.find(v => v.name === n)?.validate;
        if (!validate) return [n, undefined] as const;

        return [n, validate(fieldValue, value)] as const;
      })
      .filter(([n, e]) => !!n && !!e);

    setAutoErrors(fromPairs(errors as any) as ErrorsType<T>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validations, requiredFields, value]);

  const setError = (name: StringKeyOf<T>, error: FormErrorType | undefined) => {
    setManualErrors(prev => {
      const updated = { ...prev };
      if (error) updated[name] = error;
      else delete updated[name];
      return updated;
    });
  };

  const getErrors = () => ({
    ...getAutoErrors(),
    ...getManualErrors(),
  });

  const errors = getErrors();
  useEffect(() => {
    const hasErrors = Object.values(errors).some(Boolean);

    setCanSubmit(!hasErrors);
  }, [errors]);

  const Provider = FormContext.Provider as Provider<FormContextType<T>>;

  return (
    <Provider
      value={{
        value,
        canSubmit,
        register,
        unregister,
        getErrors,
        renderError,
        getValue: getValues,
        onChange: handleChange,
        setError,
      }}
    >
      {children}
    </Provider>
  );
};

export { FormItem } from './FormItem';
export const useForm = <T extends {} = any>() =>
  useContext<FormContextType<T>>(FormContext);

export type { FormErrorType } from './DefaultError';
