import React, { useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DescriptionIcon from '@mui/icons-material/Description';

import {
  Form,
  FormItem,
  SubmitButton,
  Button,
  Input,
  FileUpload,
} from '../../../../components';
import { useAuth } from '../../../../hooks';
import { validateChunkSize } from '../utils/validator';

export type FormProps = {
  text?: string;
  file?: File | null;
  requested_by?: string;
  chunk_size?: number;
};

type TaskFormProps = {
  saving: boolean;
  form?: FormProps | null;
  onCancel: () => void;
  onSave: (form: FormProps) => void;
};

const defaultFormValues: FormProps = {
  text: '',
  file: null,
  chunk_size: 1000,
};

export const CompressTaskForm: React.FC<TaskFormProps> = ({
  saving,
  onSave,
  onCancel,
}) => {
  const { user } = useAuth();
  const [values, setValues] = useState<FormProps>(defaultFormValues);
  const [selectedInput, setSelectedInput] = useState<'text' | 'file' | null>(
    null
  );

  const taskData = {
    requested_by: user?.name,
    ...values,
  };

  return (
    <div className="flex flex-col gap-y-6 w-full p-6 bg-white rounded-lg">
      <h2 className="text-xl font-semibold text-center">Select source type:</h2>
      <div className="flex justify-center gap-x-4">
        <button
          className={`p-4 flex flex-col items-center border rounded-lg w-36 ${
            selectedInput === 'file'
              ? 'bg-purple-500 text-white'
              : 'bg-gray-100'
          }`}
          onClick={() => setSelectedInput('file')}
        >
          <UploadFileIcon />
          <span>Upload File</span>
        </button>

        <button
          className={`p-4 flex flex-col items-center border rounded-lg w-36 ${
            selectedInput === 'text'
              ? 'bg-purple-500 text-white'
              : 'bg-gray-100'
          }`}
          onClick={() => setSelectedInput('text')}
        >
          <DescriptionIcon />
          <span>Free Text</span>
        </button>
      </div>

      <Form onChange={setValues} initialValue={values}>
        {selectedInput && (
          <FormItem<FormProps>
            name="chunk_size"
            label="Chunk Size"
            className="w-10"
            labelContainerClassName="min-w-[8rem] w-full"
            validate={validateChunkSize}
            errorBelowInput
            required
          >
            <Input size="small" numeric />
          </FormItem>
        )}
        {selectedInput === 'text' && (
          <FormItem<FormProps>
            required
            name="text"
            label="Text Input"
            inline={false}
          >
            <Input placeholder="Paste your text here" multiline />
          </FormItem>
        )}

        {selectedInput === 'file' && (
          <FormItem<FormProps>
            required
            name="file"
            label="Upload File"
            inline={false}
            renderError={() => null}
          >
            <FileUpload loading={false} />
          </FormItem>
        )}

        <div className="flex flex-col gap-y-4 justify-center items-center mt-4">
          <SubmitButton
            size="large"
            disabled={saving || !selectedInput}
            className="w-full bg-purple-500 text-white"
            label="Confirm"
            onClick={() => onSave(taskData)}
          />
          <Button
            size="large"
            type="contrast"
            label="Cancel"
            className="w-full text-main"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
