export const formatDate = (date?: string | null) => {
  if (!date) return '';
  if (typeof date !== 'string') return `${date}`;

  if (date.length === 19) {
    return date.replace('T', ' ');
  }

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) return '';

  return parsedDate.toISOString().replace('T', ' ').replace('Z', '');
};
