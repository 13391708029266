import { useState } from 'react';
import { Edit, DeleteOutline } from '@mui/icons-material';

import { useIntents } from './hooks/useIntents';
import { MoreActions } from '../../components';
import { ConfirmModal, Modal } from '../../components';
import { validateJSONforInput } from '../../utilities';
import { Card, Dropdown, Form, Table } from '../../components';
import { Button, FormItem, Input, SubmitButton } from '../../components';
import type { IntentFormType } from './hooks/useIntents';
import type { HandlerFunctionsEnum } from '../../api';

type SupportedHandlerFunctionType = `${HandlerFunctionsEnum}`;
type PartialForm = Partial<IntentFormType>;

type IntentsProps = {
  agentId: string;
  disabled?: boolean;
};

type IntentFormProps = {
  saving: boolean;
  isEdit?: boolean;
  form?: PartialForm | null;

  onCancel: () => void;
  onSave: (form: PartialForm) => void;
};

const functionOptions: OptionType<
  SupportedHandlerFunctionType | 'change_language' | 'update_tts_options'
>[] = [
  { value: 'agent_switch', label: 'Agent Switch' },
  { value: 'call_request', label: 'Call Request' },
  { value: 'rest_api_call', label: 'Rest api call' },
  { value: 'update_widget_state', label: 'Update Widget State' },
  { value: 'change_language', label: 'Change Language' },
  { value: 'update_tts_options', label: 'Update TTS Options' },
];

const IntentForm: React.FC<IntentFormProps> = ({
  saving,
  isEdit,
  form,
  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialForm>(form ?? {});

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form<PartialForm> initialValue={values} onChange={setValues}>
        <FormItem<PartialForm>
          required
          inline={false}
          name="description"
          label="Description"
        >
          <Input />
        </FormItem>
        <FormItem<PartialForm>
          required
          inline={false}
          name="function_number"
          label="Function number"
        >
          <Input numeric />
        </FormItem>
        <FormItem<PartialForm>
          required
          inline={false}
          name="handler_params"
          label="Handler Params"
          validate={validateJSONforInput}
        >
          <Input multiline />
        </FormItem>
        <FormItem<PartialForm>
          required
          inline={false}
          name="handler_function"
          label="Handler function"
        >
          <Dropdown options={functionOptions} />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            loading={saving}
            disabled={saving}
            className="w-full"
            onClick={() => onSave(values)}
            label={isEdit ? 'Update' : 'Create'}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};

export const Intents: React.FC<IntentsProps> = ({ agentId, disabled }) => {
  const {
    currentForm,
    currentFormId,
    loading,
    intents,
    isOpened,

    onOpen,
    onClose,
    onAddIntent,
    onEditIntent,
    onDeleteIntent,
    setCurrentForm,

    openedDeleteModal,
    openDeleteModal,
    closeDeleteModal,
  } = useIntents(agentId);

  return (
    <>
      <ConfirmModal
        title="Do you want to delete this intent?"
        open={openedDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={onDeleteIntent}
      />

      {!disabled && (
        <Modal
          key={String(isOpened)}
          size="small"
          open={isOpened}
          onClose={onClose}
          title={currentForm ? 'Edit Intent' : 'Add Intent'}
        >
          <IntentForm
            form={currentForm}
            isEdit={!!currentFormId}
            saving={loading}
            onCancel={onClose}
            onSave={
              currentFormId
                ? values =>
                    onEditIntent(currentFormId, values as IntentFormType)
                : onAddIntent
            }
          />
        </Modal>
      )}
      <Card
        actions={
          disabled
            ? []
            : [
                <Button
                  key="add"
                  label="Add"
                  loading={loading}
                  onClick={() => onOpen()}
                />,
              ]
        }
        collapsable
        size="unset"
        title="Intents and handlers"
        subtitle="This section allows you to configure the model for the assistant"
      >
        <Table
          loading={loading}
          verticalAlign="top"
          data={intents ?? []}
          config={{
            columns: [
              {
                header: 'Function number',
                getValue: ({ function_number }) => function_number,
              },
              {
                header: 'Handler function',
                getValue: ({ handler_function }) => handler_function,
              },
              {
                header: 'Handler parameter',
                cellClassName: 'max-w-[25rem] min-w[7rem]',
                getValue: ({ handler_params }) => handler_params,
              },
              {
                header: 'Description',
                getValue: ({ description }) => description,
              },
              ...(!disabled
                ? [
                    {
                      header: '',
                      cellClassName: 'max-w-[7rem] min-w[7rem]',
                      cellContentClassName:
                        'md:invisible md:group-hover/tablerow:visible',
                      getValue: (form: IntentFormType) => (
                        <MoreActions
                          options={[
                            {
                              label: 'Edit',
                              icon: <Edit />,
                              loading: loading,
                              onClick: () => {
                                setCurrentForm(form);
                                onOpen();
                              },
                            },
                            {
                              label: 'Delete',
                              loading: loading,
                              icon: <DeleteOutline />,
                              onClick: () => {
                                openDeleteModal(form.id!);
                              },
                            },
                          ]}
                        />
                      ),
                    },
                  ]
                : []),
            ],
          }}
          emptyMessage="There are no intents"
        />
      </Card>
    </>
  );
};
