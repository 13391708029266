import { useState } from 'react';

import { useModal, useRequest } from '../../../hooks';
import { removePermissionFromRole } from '../../../api';

export const useRemovePermission = (roleId: string, onRefresh: () => void) => {
  const [idForDelete, setIdForDelete] = useState<string | undefined>();
  const { isOpened, onOpen, onClose } = useModal();
  const [handleRemovePermission, loading] = useRequest(
    () => removePermissionFromRole(roleId, idForDelete!),
    {
      deps: [idForDelete, roleId],
      onSuccess: () => {
        onRefresh?.();
        onClose();
      },
    }
  );

  const handleOpen = (permissionId?: string) => {
    onOpen();
    setIdForDelete(permissionId);
  };

  return {
    loading,
    isOpened,

    onClose,
    onOpen: handleOpen,
    onRemovePermission: handleRemovePermission,
  };
};
