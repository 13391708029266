import { useState } from 'react';

import { notify } from '../../../app';
import { useRequest } from '../../../hooks';
import { useFetch, useModal } from '../../../hooks';
import { getIntentsAndHandlers } from '../../../api';
import { addIntent, editIntent, deleteIntent } from '../../../api';
import type { IntentRowType, IntentPayloadType } from '../../../api';

export type IntentRow = IntentRowType;
export type IntentFormType = Omit<IntentPayloadType, 'handler_params'> & {
  id?: string;
  handler_params: string;
};

export const useIntents = (agentId: string) => {
  const [intentForDelete, setIntentForDelete] = useState<string | undefined>();
  const { isOpened, onClose, onOpen } = useModal();

  const {
    isOpened: openedDeleteModal,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useModal();

  const handleClose = () => {
    onClose();
    setCurrentForm(undefined);
  };

  const [currentForm, setCurrentForm] = useState<
    Partial<IntentFormType> | undefined
  >(undefined);
  const currentFormId = currentForm?.id;

  const {
    data: dataIntents,
    intializing: loadingIntents,
    refresh: refreshIntents,
  } = useFetch(() => getIntentsAndHandlers(agentId), [agentId]);
  const [handleAddIntent, addingIntent] = useRequest(
    (_token, form: Partial<IntentFormType>) =>
      addIntent(
        {
          ...form,
          handler_params: form.handler_params
            ? JSON.parse(form.handler_params)
            : {},
        },
        agentId
      ),
    {
      deps: [agentId],
      onSuccess: () => {
        notify.success('Intent created');
        refreshIntents?.();
        handleClose();
      },
      onError: () => notify.error('Failed to create intent'),
    }
  );
  const [handleEditIntent, editingIntent] = useRequest(
    (_token, intentId: string, form: IntentFormType) =>
      editIntent(
        {
          ...form,
          handler_params: form.handler_params
            ? JSON.parse(form.handler_params)
            : {},
        },
        intentId
      ),
    {
      deps: [agentId],
      onSuccess: () => {
        notify.success('Intent updated');
        refreshIntents?.();
        handleClose();
      },
      onError: () => notify.error('Failed to update intent'),
    }
  );
  const [handleDeleteIntent, deleting] = useRequest(
    async _token => {
      if (!intentForDelete) return false;

      return await deleteIntent(intentForDelete);
    },
    {
      deps: [agentId, intentForDelete],
      onSuccess: () => {
        notify.success('Intent deleted');
        refreshIntents?.();
        closeDeleteModal();
      },
      onError: () => notify.error('Failed to delete intent'),
    }
  );
  const handleOpenDeleteIntentModal = (intentId: string) => {
    setIntentForDelete(intentId);
    openDeleteModal();
  };
  const intents: IntentFormType[] = (dataIntents || [])?.map((intent: any) => ({
    ...intent,
    handler_params: intent?.handler_params
      ? JSON.stringify(intent.handler_params)
      : '',
  }));

  return {
    currentForm,
    currentFormId,
    isOpened: isOpened,

    onOpen: onOpen,
    onClose: handleClose,
    setCurrentForm,

    loading: loadingIntents,
    intenting: addingIntent || editingIntent || deleting,

    intents: intents || [],

    onAddIntent: handleAddIntent,
    onEditIntent: handleEditIntent,
    onDeleteIntent: handleDeleteIntent,

    openedDeleteModal,
    closeDeleteModal,
    openDeleteModal: handleOpenDeleteIntentModal,
  };
};
