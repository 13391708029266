import React, { memo, useEffect, useRef } from 'react';
import { JsonForms } from '@jsonforms/react';
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import CountryGreetingControl from './Controls/CountryGreetingControl';
import { countryGreetingTester } from './Controls/countryGreetingTester';
import type { FormJsonData } from '../../api/form-controller';

const renderers = [
  ...materialRenderers,
  { tester: countryGreetingTester, renderer: CountryGreetingControl },
];

export type JsonBuilderProps = {
  onChange: (data: any) => void;
  formData: any;
  schemaData: FormJsonData;
};

const JsonFormBuilder: React.FC<JsonBuilderProps> = ({
  onChange,
  formData,
  schemaData,
}) => {
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (
        document.activeElement instanceof HTMLInputElement &&
        document.activeElement.type === 'number'
      ) {
        event.preventDefault();
      }
    };

    const inputElements = formRef.current?.querySelectorAll(
      'input[type="number"]'
    );

    inputElements?.forEach(input => {
      (input as HTMLInputElement).addEventListener(
        'wheel',
        handleWheel as EventListener
      );
    });

    return () => {
      inputElements?.forEach(input => {
        (input as HTMLInputElement).removeEventListener(
          'wheel',
          handleWheel as EventListener
        );
      });
    };
  }, []);

  return (
    <div className="flex flex-col items-center p-6" ref={formRef}>
      <JsonForms
        schema={(schemaData as any)?.jsonSchema}
        uischema={(schemaData as any)?.uiSchema}
        data={formData}
        renderers={renderers}
        cells={materialCells}
        validationMode="ValidateAndShow"
        onChange={onChange}
        config={{
          material: {
            variant: 'outlined',
            table: { disableHeader: false },
            popper: { disablePortal: false },
          },
        }}
      />
    </div>
  );
};

export default memo(JsonFormBuilder);
