import type { PipelineLanguage } from '../api';

export const languages: OptionType<`${PipelineLanguage}`>[] = [
  { value: 'ar', label: 'Arabic' },
  { value: 'da', label: 'Danish' },
  { value: 'de', label: 'German' },
  { value: 'en', label: 'English' },
  { value: 'en-AU', label: 'English (Australia)' },
  { value: 'en-GB', label: 'English (United Kingdom)' },
  { value: 'en-IN', label: 'English (India)' },
  { value: 'en-NZ', label: 'English (New Zealand)' },
  { value: 'en-US', label: 'English (United States)' },
  { value: 'es', label: 'Spanish' },
  { value: 'es-419', label: 'Spanish (Latin America)' },
  { value: 'es-LATAM', label: 'Spanish (Latin America)' },
  { value: 'fr', label: 'French' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hi-Latn', label: 'Hindi (Latin)' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'nl', label: 'Dutch' },
  { value: 'no', label: 'Norwegian' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'pt-BR', label: 'Portuguese (Brazil)' },
  { value: 'ru', label: 'Russian' },
  { value: 'sv', label: 'Swedish' },
  { value: 'ta', label: 'Tamil' },
  { value: 'taq', label: 'Tamasheq' },
  { value: 'th', label: 'Thai' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'zh', label: 'Chinese' },
  { value: 'zh-CN', label: 'Chinese (Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
];
