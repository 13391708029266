import { Avatar } from '@mui/material';

import { useScraping } from './useScraping';
import { Table, SearchPanel, Badge } from '../../../components';
import { Button, Modal } from '../../../components';
import { DynamicPageTitle, Image } from '../../../components';
import ScrapingActions from './components/ScrapingActions';
import TaskForm from './components/TaskForm';
import { CheckIcon } from '../../../assets/icons';
import { ScraperFilter } from './components/ScraperFilter';
import { format } from 'date-fns';
import ScrapingImageModal from './components/ScrapingImageModal';

export const ScrapingTab = () => {
  const {
    scrapingList,
    loading,
    reloading,
    isOpened,
    updating,
    filters,
    onOpen,
    onClose,
    setPage,
    setFilters,
    handleAddScrapingTask,
    handleCancelScrapingTask,
    handleDownloadScrapingTask,
    handleretryScrapingTask,
    setCurrentForm,
  } = useScraping();

  const inProgress = loading || reloading || updating;

  const formatDate = (dateString: string): string => {
    return format(new Date(dateString), 'dd MMM yyyy, HH:mm:ss');
  };

  const { tasks, pagination } = scrapingList?.[0] || [];

  return (
    <div className="p-4">
      <DynamicPageTitle>Scraping</DynamicPageTitle>

      <div className="flex flex-wrap justify-between items-center mb-4">
        <div className="flex gap-x-sm justify-between">
          <ScraperFilter values={filters ?? {}} onChange={setFilters} />
        </div>

        <Button
          label="Add Task"
          onClick={() => {
            setCurrentForm(undefined);
            onOpen();
          }}
          size="medium"
        />
      </div>

      <Modal size="small" open={isOpened} onClose={onClose} title="New Task">
        <TaskForm
          key={'new'}
          onCancel={onClose}
          saving={inProgress}
          onSave={handleAddScrapingTask}
        />
      </Modal>

      <div className="overflow-x-auto">
        <Table
          data={tasks}
          loading={loading}
          emptyMessage="There are no Scraping tasks yet."
          config={{
            columns: [
              {
                sortName: 'url',
                header: 'URL',
                cellClassName: 'min-w-[5rem] max-w-[25rem] ',
                getValue: ({ url }) => <div className="truncate">{url}</div>,
              },
              {
                header: 'Screenshot',
                cellClassName: 'min-w-[6rem] text-left',
                getValue: item => (
                  <ScrapingImageModal imageUrl={item?.screenshot_url || ''}>
                    <Image src={item?.screenshot_url || ''} />
                  </ScrapingImageModal>
                ),
              },
              {
                header: 'Depth',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ depth }) => (depth === null ? <p>∞</p> : depth),
              },
              {
                header: 'Output Mode',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ is_include_links }) => (
                  <CheckIcon color={is_include_links ? '#5861FA' : '#E8EBEF'} />
                ),
              },
              {
                header: 'Requested by',
                sortName: 'requested_by',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ requested_by }) => (
                  <div className="flex items-center">
                    <Avatar
                      sx={{
                        bgcolor: '#3b82f6',
                        width: 28,
                        height: 28,
                        fontSize: '1.5rem',
                        mr: 1,
                      }}
                    >
                      {requested_by?.[0]}
                    </Avatar>
                    <span>{requested_by || 'Unknown'}</span>
                  </div>
                ),
              },
              {
                header: 'Date & Time',
                sortName: 'created_at',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ created_at }) => formatDate(created_at),
              },
              {
                header: 'Status',
                sortName: 'status',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ status }) => <Badge status={status} />,
              },
              {
                header: 'Actions',
                cellClassName: 'min-w-[5rem]',
                getValue: ({ status, id }) => (
                  <div className="flex items-center justify-end h-full min-h-[3rem]">
                    <ScrapingActions
                      status={status}
                      onRetry={() => handleretryScrapingTask(id)}
                      onDownload={() => handleDownloadScrapingTask(id || '')}
                      onViewErrors={() => console.log('Viewing Errors...')}
                      onDelete={() => handleCancelScrapingTask(id || '')}
                    />
                  </div>
                ),
              },
            ],
          }}
        />
      </div>
      <div className="mt-sm flex justify-between items-center">
        <SearchPanel
          totalItems={pagination?.total_items}
          page={pagination?.page}
          perPage={10}
          withPaginate
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default ScrapingTab;
