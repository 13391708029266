import React from 'react';
import clsx from 'clsx';

import { SafeLink } from '../Link';
import { Spinner } from '../Spinner';

type ButtonType = 'primary' | 'light' | 'contrast' | 'transparent' | 'empty';
type ButtonAlign = 'left' | 'center';
type ButtonSize = 'small' | 'medium' | 'large' | 'empty' | (string & {});

export type ButtonProps = {
  url?: string;
  loading?: boolean;
  icon?: React.ReactNode;
  external?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  tooltip?: string;
  align?: ButtonAlign;
  label?: React.ReactNode;
  roundedLeft?: boolean;
  roundedRight?: boolean;
  stopPropagation?: boolean;
  preventDefault?: boolean;
};

const colors: { [x in ButtonType]: string } = {
  empty: '',
  contrast: 'bg-contrast hover:opacity-70 text-mainText border border-border',
  transparent: 'text-contrast',
  light: 'bg-buttonLight hover:opacity-70 text-main',
  primary:
    'bg-buttonPrimary hover:bg-buttonPrimaryHover border border-buttonPrimary text-contrast',
};

const sizes: { [x in ButtonSize]: string } = {
  empty: '',
  small: 'px-2 py-2 text-small',
  medium: 'px-[3rem] py-[0.8rem] text-small font-medium',
  large: 'px-[4rem] py-[0.9rem] text-medium font-medium',
};

const Button: React.FC<ButtonProps> = ({
  url,
  icon,
  label,
  tooltip,
  onClick,
  disabled,
  external,
  align = 'center',
  className: defaultClassName,
  size = 'medium',
  type = 'primary',
  loading = false,
  roundedLeft = true,
  roundedRight = true,
  preventDefault = false,
  stopPropagation = false,
}) => {
  const className = clsx(
    colors[type],
    defaultClassName,
    sizes[size] ?? size,
    roundedLeft ? 'rounded-l-bt' : '',
    roundedRight ? 'rounded-r-bt' : '',
    'inline-flex items-center gap-x-4',
    disabled ? 'disabled opacity-40 pointer-events-none' : '',
    'relative transition-all duration-150',
    align === 'center' ? 'justify-center' : 'justify-start'
  );

  const iconElement = loading ? <Spinner /> : icon;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    preventDefault && e.preventDefault();
    stopPropagation && e.stopPropagation();
    onClick?.();
  };

  if (url && external) {
    return (
      <SafeLink
        to={url}
        external
        className={className}
        onClick={stopPropagation ? e => e.stopPropagation?.() : undefined}
      >
        {iconElement}
        {label}
      </SafeLink>
    );
  }

  if (url && !external) {
    return (
      <SafeLink
        isNav
        to={url}
        external={false}
        className={className}
        onClick={stopPropagation ? e => e.stopPropagation?.() : undefined}
      >
        {iconElement}
        {label}
      </SafeLink>
    );
  }

  return (
    <button
      title={tooltip}
      disabled={disabled}
      className={className}
      onClick={disabled ? undefined : handleClick}
    >
      {iconElement}
      {label}
    </button>
  );
};

export default React.memo(Button) as typeof Button;
