import validator from 'validator';

import { stringToArray, stringToArrayQuote } from '../../../utilities';

export type ViolationType = `S${number}`;

const isViolation = (value: string): value is ViolationType => {
  return /^S\d+$/.test(value);
};

export const validateViolations = (string?: string) => {
  const arr = stringToArray(string || '');
  return arr.every(isViolation) ? false : 'Invalid violation structure';
};

const isValidDomain = (
  url: string,
  protocols: string[] = ['https']
): boolean => {
  return validator.isURL(url, {
    protocols,
    require_protocol: true,
    require_valid_protocol: true,
    allow_fragments: false,
    allow_query_components: false,
  });
};

export const validateDomains =
  (protocols: string[] = ['https']) =>
  (string?: string): string | false => {
    const arr = stringToArrayQuote(string || '');
    return arr.every(domain => isValidDomain(domain, protocols))
      ? false
      : 'Invalid violation structure';
  };
