const isEmpty = (value: any) => {
  return value === '' || value === null || value === undefined;
};

const parseParamFromUrl = <T>(p: any): T => JSON.parse(p);
const convertParamToUrl = <T>(p: T) => (!isEmpty(p) ? JSON.stringify(p) : '');

export type GetUrlParamActionsParams = {
  name: string;
  search?: string;
  history?: { push: (path: string) => void };
};

export const getUrlParamActions = <T>({
  name,
  search,
  history,
}: GetUrlParamActionsParams) => {
  const getParamFromUrl = () => {
    try {
      const param = new URLSearchParams(search).get(name);

      return parseParamFromUrl<T>(param);
    } catch (ex) {
      return undefined;
    }
  };

  const setParamToUrl = (param?: T) => {
    const params = new URLSearchParams(search);
    const urlParam = convertParamToUrl(param);

    urlParam ? params.set(name, urlParam) : params.delete(name);

    history?.push(`?${params.toString()}`);
  };

  return [getParamFromUrl, setParamToUrl] as const;
};
