import { OpenAPI } from '../services';
import { OpenAPI as ScraperOpenApi } from '../services/scraper';
import { OpenAPI as CompressorOpenApi } from '../services/compressor';

export { getCurrentOrg } from './getCurrentOrg';

export * from './agent';
export * from './convo';
export * from './intents';
export * from './management';
export * from './scraping';
export * from './text-compressor';
export * from './form-controller';
export * from './management-settings';

if (process.env.NODE_ENV === 'development') {
  OpenAPI.BASE = '/api';
  ScraperOpenApi.BASE = '/scraper/api';
  CompressorOpenApi.BASE = '/compressor/api';
} else {
  OpenAPI.BASE = process.env.REACT_APP_API_URL;
  ScraperOpenApi.BASE = process.env.REACT_APP_BASE_API_URL;
  CompressorOpenApi.BASE = process.env.REACT_APP_BASE_API_URL;
}

export { OpenAPI, ScraperOpenApi };
