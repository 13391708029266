import clsx from 'clsx';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  const defaultClassName =
    'border rounded-card p-md bg-[#F5F7F9] flex flex-col gap-y-md';
  return <div className={clsx(className, defaultClassName)}>{children}</div>;
};
