import { format } from 'date-fns';
import { CompressingTaskType, TaskType } from './const';

export const formatDate = (dateString: string): string => {
  try {
    const cleanedDateString = dateString?.replace(/(\.\d{3})\d+/, '$1');
    const parsedDate = new Date(cleanedDateString);

    if (isNaN(parsedDate.getTime())) {
      throw new Error('Invalid date');
    }

    return format(parsedDate, 'dd MMM yyyy, HH:mm:ss');
  } catch (error) {
    console.error('Error parsing date:', error);
    return 'Invalid Date';
  }
};

export const getTaskTypeLabel = (type: CompressingTaskType) => {
  const fileTypes = [
    CompressingTaskType.ANALYZE_FILE,
    CompressingTaskType.COMPRESS_FILE,
  ];
  return fileTypes.includes(type) ? TaskType.File : TaskType.Text;
};
