export const getLanguageDisplayName = (code: string): string => {
  try {
    const [lang, region] = code.split('-');
    const langDisplay = new Intl.DisplayNames(['en'], { type: 'language' });
    const regionDisplay = new Intl.DisplayNames(['en'], { type: 'region' });

    const langName = langDisplay.of(lang);
    const regionName = region ? regionDisplay.of(region) : null;

    if (langName && regionName) {
      return `${langName} - ${regionName} (${code})`;
    } else if (langName) {
      return `${langName} (${code})`;
    }
  } catch (e) {
    console.warn(`Failed to resolve display name for: ${code}`, e);
  }

  return code;
};
