import React from 'react';

import { Dropdown } from '../../../components';

interface Props {
  groupNames: string[];
  selectedGroup: string;
  setSelectedGroup: (group: string) => void;
}

const GroupSelector: React.FC<Props> = ({
  groupNames,
  selectedGroup,
  setSelectedGroup,
}) => {
  return (
    <Dropdown
      options={groupNames.map(group => ({ label: group, value: group }))}
      value={selectedGroup}
      onChange={value => setSelectedGroup(value)}
      placeholder="Select Group"
    />
  );
};

export default GroupSelector;
