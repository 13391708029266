import React from 'react';

import { Button } from '../../../components';
import type { SchemaConfig } from '../types';

interface Props {
  schema: SchemaConfig;
  selectedGroup: string;
  highlightedProperty: string | null;
  handleRemoveProperty: (propName: string) => void;
}

const PropertyList: React.FC<Props> = ({
  schema,
  selectedGroup,
  highlightedProperty,
  handleRemoveProperty,
}) => {
  return (
    <ul className="border p-3 rounded-md bg-gray-50">
      {schema.uiSchema.elements
        .find(g => g.label === selectedGroup)
        ?.elements?.map(layout =>
          layout.elements?.map(prop => {
            const propName = prop.scope.split('/').pop();
            return (
              <li
                key={propName}
                className={`flex justify-between items-center border-b py-2 ${highlightedProperty === propName ? 'bg-yellow-200' : ''}`}
              >
                <span>{propName}</span>
                <Button
                  label="Remove"
                  size="small"
                  className="bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-600"
                  onClick={() => handleRemoveProperty(propName!)}
                />
              </li>
            );
          })
        )}
    </ul>
  );
};

export default PropertyList;
