import { ManagementSettingsApiService } from '../services';
import type {
  ManagementSettingsDto,
  ManagementSettingsRequest,
} from '../services';

export type ManagementSettingsType = ManagementSettingsDto;
export type ManagementSettingsPayload = ManagementSettingsRequest;

export const createManagementSettings = (
  payload: ManagementSettingsPayload
) => {
  return ManagementSettingsApiService.createSettings(payload);
};

export const updateManagementSettings = (
  payload: ManagementSettingsPayload
) => {
  return ManagementSettingsApiService.updateSettings(payload);
};

export const getManagementSettings = (name: string) => {
  return ManagementSettingsApiService.getManagementSettings(name);
};
