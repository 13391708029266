import { useMemo } from 'react';
import {
  ExitToApp,
  AccountCircle,
  PersonAddOutlined,
  PeopleAltOutlined,
  AccountTreeOutlined,
  QuestionAnswerOutlined,
  FolderOpenOutlined,
} from '@mui/icons-material';
import SchemaIcon from '@mui/icons-material/Schema';

import { OrganizationPage } from '../../pages';
import { RolesAndPermissionsPage } from '../../pages';
import { UsersAndOrganizationsPage } from '../../pages';
import { AgentPage, AgentsPage, NewAgentPage } from '../../pages';
import { ConversationPage, ConversationsPage } from '../../pages';
import { KnowledgeManagementPage } from '../../pages';
import { SchemaEditorPage } from '../../pages';
import type { MenuItem } from './Layout';

export const getAuthenticatedMenuItems = (logout: Function): MenuItem[] => [
  {
    text: 'Manage Agents',
    url: '/agents',
    icon: <PeopleAltOutlined />,
    component: AgentsPage,

    action: 'view_agents',
  },
  {
    text: 'Conversations',
    url: '/conversation',
    icon: <QuestionAnswerOutlined />,
    component: ConversationsPage,

    action: 'view_conversations',
  },
  {
    url: '/conversation/:id',
    component: ConversationPage,

    action: 'view_conversations',
  },
  {
    url: '/agents/new',
    component: NewAgentPage,

    action: 'create_agents',
  },
  {
    url: '/agents/:agent_id',
    icon: <AccountCircle />,
    component: AgentPage,

    action: 'view_agents',
  },
  {
    url: '/role_management',
    text: 'Role Management',
    icon: <AccountTreeOutlined />,
    component: RolesAndPermissionsPage,

    action: 'super_admin',
  },
  {
    url: '/user_management',
    text: 'User Management',
    icon: <PersonAddOutlined />,
    component: UsersAndOrganizationsPage,

    action: 'super_admin',
  },
  {
    url: '/knowledge_management',
    text: 'Knowledge Management',
    icon: <FolderOpenOutlined />,
    component: KnowledgeManagementPage,

    action: 'super_admin',
  },
  {
    url: '/user_management/organization/:id',
    component: OrganizationPage,

    action: 'super_admin',
  },
  {
    url: '/json_management',
    component: SchemaEditorPage,
    text: 'JSON Management',
    icon: <SchemaIcon />,
    action: 'super_admin',
  },

  {
    text: 'Logout',
    onClick: () =>
      logout({ logoutParams: { returnTo: window.location.origin } }),
    icon: <ExitToApp />,
  },
];

export const getUnauthMenuItems = (loginWithRedirect: Function): MenuItem[] => {
  return [
    {
      text: 'Login',
      onClick: () => loginWithRedirect(),
      icon: null,
    },
  ];
};

export const useRoutes = (
  login: Function,
  logout: Function,
  isAuthenticated: boolean
) => {
  const authMenuItems = useMemo(
    () => getAuthenticatedMenuItems(logout),
    [logout]
  );

  const unauthMenuItems = useMemo(() => getUnauthMenuItems(login), [login]);

  const menuItems = isAuthenticated ? authMenuItems : unauthMenuItems;

  const [publicRoutes, privateRoutes] = useMemo(() => {
    const itemsWithRoutes = menuItems.filter(i => i.component && i.url);

    return [
      itemsWithRoutes.filter(i => i.isPublic),
      itemsWithRoutes.filter(i => !i.isPublic),
    ];
  }, [menuItems]);

  return { publicRoutes, privateRoutes, menuItems };
};
