import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import { Button, Spinner } from '../../../../components';
import { CompressingTaskStatusKey } from '../utils/const';

interface TaskActionsProps {
  status: string;
  onDelete?: () => void;
  onCompress?: () => void;
  onDownload?: () => void;
  loading?: boolean;
}

const CompressorActions: React.FC<TaskActionsProps> = ({
  status,
  onDelete,
  onCompress,
  loading = false,
  onDownload,
}) => {
  switch (status) {
    case CompressingTaskStatusKey.IN_PROCESS:
      return (
        <div className="flex items-center gap-2">
          <Spinner className="h-10 w-10" />
          <Button
            onClick={onDelete}
            size="small"
            className="px-2"
            type="contrast"
            icon={<DeleteIcon className="h-6 w-6 text-buttonPrimary" />}
          />
        </div>
      );

    case CompressingTaskStatusKey.SUCCESS:
      return (
        <div className="flex gap-4">
          {onCompress && (
            <Button
              label="Compress"
              onClick={onCompress}
              type="primary"
              size="small"
              className="px-4"
              disabled={loading}
            />
          )}
          {onDownload && (
            <Button
              label="Download"
              onClick={onDownload}
              type="primary"
              size="small"
              className="px-4"
              disabled={loading}
              icon={<DownloadIcon className="h-4 w-4" />}
            />
          )}
          <Button
            onClick={onDelete}
            size="small"
            className="px-2"
            disabled={loading}
            type="contrast"
            icon={<DeleteIcon className="h-6 w-6 text-buttonPrimary" />}
          />
        </div>
      );

    case CompressingTaskStatusKey.UNKNOWN:
    default:
      return null;
  }
};

export default CompressorActions;
