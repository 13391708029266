import JsonViewComponent from '@uiw/react-json-view';

import { Modal } from '..';
import Button from '../Button';
import { onCopy } from '../Copy';
import { useModal } from '../../hooks';
import type { ButtonProps } from '../Button';

type JsonViewProps = {
  value: object;
};

export const JsonView: React.FC<JsonViewProps> = ({ value }) => {
  return (
    <JsonViewComponent
      value={value}
      displayDataTypes={false}
      displayObjectSize={false}
      onCopied={onCopy}
    />
  );
};

type JsonViewModalProps = Omit<ButtonProps, 'label'> & {
  value: object;
  label: string;
};

export const JsonViewModal: React.FC<JsonViewModalProps> = ({
  value,
  label,
  ...rest
}) => {
  const { isOpened, onOpen, onClose } = useModal();

  return (
    <>
      <Button {...rest} label={label} onClick={onOpen} />
      <Modal open={isOpened} onClose={onClose} title={label}>
        <JsonView value={value} />
      </Modal>
    </>
  );
};
