import clsx from 'clsx';

import Button from '../Button';
import { Dropdown } from '../Dropdown';
import type { ButtonProps } from '../Button';

const renderIcon = (buttonProps?: Partial<ButtonProps>) => {
  return (
    <Button
      roundedLeft={false}
      roundedRight={false}
      align="left"
      type="empty"
      size="empty"
      label={
        <svg
          width="18"
          height="4"
          fill="none"
          viewBox="0 0 18 4"
          className="flex-none size-6"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4932 2H15.5067"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.99325 2H9.00675"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.49325 2H2.50675"
            stroke="currentColor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...buttonProps}
      className={clsx(
        buttonProps?.className,
        'ml-auto',
        'p-4 bg-contrast outline-none hover:bg-gray-50 focus:outline-none focus:bg-gray-50 rounded-sm text-main'
      )}
    />
  );
};

type ActionType = {
  url?: string;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
};

type MoreActionsProps = {
  options?: ActionType[];
  buttonProps?: Partial<ButtonProps>;
};

const remapOption = (action: ActionType, index: number): OptionType => {
  return {
    value: String(index),
    label: (
      <Button
        align="left"
        type="empty"
        size="empty"
        url={action.url}
        icon={action.icon}
        onClick={action.onClick}
        loading={action.loading}
        disabled={action.disabled}
        label={<span className="text-mainText">{action.label}</span>}
        className="text-medium text-main font-regular min-w-40"
      />
    ),
  };
};

export const MoreActions: React.FC<MoreActionsProps> = ({
  options,
  buttonProps,
}) => {
  const theseOptions: OptionType[] = options?.length
    ? options.map((action, index) => remapOption(action, index))
    : [{ value: '', label: 'No Options' }];

  return (
    <Dropdown
      options={theseOptions}
      withArrow={false}
      onChange={() => 1}
      renderValue={() => renderIcon(buttonProps)}
    />
  );
};
