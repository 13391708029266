import { useFetch } from './useFetch';
import { getTTSModels, getLLMModels, getSTTModels } from '../api';
import type { AiModelData } from '../api';

export const useModels = () => {
  const { data: llmModels, loading: loadingllmModels } = useFetch<
    AiModelData[]
  >(() => getLLMModels(), []);
  const { data: ttsModels, loading: loadingttsModels } = useFetch<
    AiModelData[]
  >(() => getTTSModels(), []);
  const { data: sttModels, loading: loadingsttModels } = useFetch<
    AiModelData[]
  >(() => getSTTModels(), []);

  return {
    llmModels,
    ttsModels,
    sttModels,

    loadingModels: loadingllmModels || loadingttsModels || loadingsttModels,
  };
};
