import { useEffect } from 'react';
import { parse, format } from 'date-fns';

import { DatepickerComponent } from './Component';
import type { DatepickerComponentProps } from './Component';

export type DatepickerProps = Omit<
  DatepickerComponentProps,
  'value' | 'onChange'
> & {
  value?: string;
  onChange?: (newValue: string) => void;

  valueFormat?: string;
  disabled?: boolean;
  cleanOnDisable?: boolean;
};

// example: '2024-12-24T20:14:17.111Z'
export const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export const Datepicker: React.FC<DatepickerProps> = ({
  value,
  onChange,
  valueFormat = ISO_FORMAT,
  disabled,
  cleanOnDisable = false,
  ...rest
}) => {
  const thisValue = value ? parse(value, valueFormat, new Date()) : undefined;
  const handleChange = (newValue: Date | null) => {
    onChange?.(newValue ? format(newValue, valueFormat) : '');
  };

  useEffect(
    () => {
      if (!cleanOnDisable || !disabled) return;

      onChange?.('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cleanOnDisable, disabled]
  );

  return (
    <DatepickerComponent
      disabled={disabled}
      {...rest}
      value={thisValue}
      onChange={handleChange}
    />
  );
};
