import { ScrapingTab } from './Scraping';
import { Tabs, Tab } from '../../components';
import { KnowledgeTab } from './Knowledge';
import CompressorTab from './TextCompressorNew';

type TabsEnum = 'knowledge' | 'scraping' | 'compressor';

const KnowledgeManagementPage = () => {
  return (
    <Tabs<TabsEnum>
      tabs={[
        { name: 'knowledge', label: 'Knowledge' },
        { name: 'scraping', label: 'Scraping' },
        { name: 'compressor', label: 'Text Compressor' },
      ]}
      contentClassName="mt-md"
    >
      <Tab name="knowledge">
        <KnowledgeTab />
      </Tab>
      <Tab name="scraping">
        <ScrapingTab />
      </Tab>
      <Tab name="compressor">
        <CompressorTab />
      </Tab>
    </Tabs>
  );
};

export default KnowledgeManagementPage;
