import { Edit, DeleteOutline, OfflineShare } from '@mui/icons-material';

import { useOrganizations } from './useOrganizations';
import { DynamicPageTitle } from '../../../components';
import { Table, SearchPanel } from '../../../components';
import { Status, MoreActions } from '../../../components';
import { copyToClipboard } from '../../../components/Copy';
import { NewOrganizationForm } from './NewOrganizationForm';
import { Button, Link, Modal, ConfirmModal } from '../../../components';

export const OrganizationsTab = () => {
  const {
    search,
    setSearch,

    loading,
    organizations,
    adding,
    updating,
    deleting,
    handleAddOrganization,
    handleEditOrganization,
    openDeleteModal,

    isOpened,
    onOpen,
    onClose,

    isEdit,
    currentForm,
    setCurrentForm,

    openedDeleteModal,
    closeDeleteModal,
    handleDeleteOrganization,
  } = useOrganizations();

  return (
    <>
      <DynamicPageTitle>Organizations</DynamicPageTitle>
      <div className="flex justify-between items-center mb-sm">
        <SearchPanel
          search={search}
          onSearch={setSearch}
          withPaginate={false}
        />

        <Button
          label="Add Organization"
          onClick={() => {
            onOpen();
            setCurrentForm(undefined);
          }}
          size="medium"
        />
      </div>
      <ConfirmModal
        title="Do you want to delete this organization?"
        open={openedDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteOrganization}
      />
      <Modal
        size="small"
        open={isOpened}
        onClose={onClose}
        title={isEdit ? 'Update organization' : 'Create new organization'}
      >
        <NewOrganizationForm
          onCancel={onClose}
          form={currentForm}
          saving={adding || updating || deleting}
          onSave={isEdit ? handleEditOrganization : handleAddOrganization}
        />
      </Modal>
      <Table
        loading={loading}
        data={organizations}
        emptyMessage="There are no organizations."
        config={{
          columns: [
            {
              header: 'Name',
              cellClassName: 'min-w-[20rem]',
              getValue: ({ id, name }) => (
                <Link
                  withIcon
                  withDecoration
                  className="font-semibold"
                  url={`/user_management/organization/${id}`}
                >
                  {name}
                </Link>
              ),
            },
            {
              header: 'Description',
              getValue: ({ description }) => description,
            },
            {
              header: 'Users',
              getValue: ({ userCount }) => userCount,
            },
            {
              header: 'Status',
              sortName: 'active',
              cellClassName: 'max-w-[5rem] min-w-[4rem]',
              getValue: ({ active }) => <Status active={active} />,
            },
            {
              header: '',
              cellClassName: 'max-w-[7rem] min-w[7rem]',
              cellContentClassName:
                'md:invisible md:group-hover/tablerow:visible',
              getValue: org => (
                <MoreActions
                  options={[
                    {
                      label: 'Edit',
                      icon: <Edit />,
                      loading: loading,
                      onClick: () => {
                        setCurrentForm(org);
                        onOpen();
                      },
                    },
                    {
                      label: 'Copy Link',

                      icon: <OfflineShare />,
                      onClick: () =>
                        copyToClipboard(
                          `${window.origin}/user_management/organization/${org.id}`,
                          'Link copied successfully.'
                        ),
                    },
                    {
                      label: 'Delete',
                      loading: loading,
                      icon: <DeleteOutline />,
                      onClick: () => {
                        openDeleteModal(org.id!);
                      },
                    },
                  ]}
                />
              ),
            },
            // {
            //   header: '',
            //   cellContentClassName: 'text-right',
            //   cellClassName: 'max-w-[8rem] min-w-[8rem]',
            //   getValue: ({ id }) => (
            //     <GoToDetails url={`/user_management/organization/${id}`} />
            //   ),
            // },
          ],
        }}
      />
      <div className="mt-sm flex justify-between items-center">
        <SearchPanel withPaginate />
      </div>
    </>
  );
};
