import { rankWith, isObjectControl, isControl } from '@jsonforms/core';

import type {
  UISchemaElement,
  JsonSchema,
  TesterContext,
} from '@jsonforms/core';

export const countryGreetingTester = rankWith(
  4,
  (uischema: UISchemaElement, schema: JsonSchema, context: TesterContext) =>
    isControl(uischema) &&
    isObjectControl(uischema, schema, context) &&
    uischema.scope.endsWith('initialStaticWidgetGreeting')
);
