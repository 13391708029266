/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiModelDto } from '../models/AiModelDto';
import type { AiModelRequest } from '../models/AiModelRequest';
import type { ModelSettingsDto } from '../models/ModelSettingsDto';
import type { ModelSettingsRequest } from '../models/ModelSettingsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ModelInventoryApiService {
    /**
     * Updates model
     * Updates model with the required fields: providerName, modelName, modelType.
     * @param id
     * @param requestBody
     * @returns AiModelDto Model updated successfully
     * @throws ApiError
     */
    public static updateModel(
        id: string,
        requestBody: AiModelRequest,
    ): CancelablePromise<AiModelDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/inventory/models/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Delete a model
     * Deletes a model by its unique identifier (UUID).
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteModel(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/inventory/models/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get model settings by id
     * @param xAiAgentOrganizationContext
     * @param settingsId
     * @returns ModelSettingsDto Successful response
     * @throws ApiError
     */
    public static getSettings(
        xAiAgentOrganizationContext: string,
        settingsId: string,
    ): CancelablePromise<ModelSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/models/model-settings/{settingsId}',
            path: {
                'settingsId': settingsId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates model settings
     * @param xAiAgentOrganizationContext
     * @param settingsId
     * @param requestBody
     * @returns ModelSettingsDto Successful response
     * @throws ApiError
     */
    public static updateSettings1(
        xAiAgentOrganizationContext: string,
        settingsId: string,
        requestBody: ModelSettingsRequest,
    ): CancelablePromise<ModelSettingsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/inventory/models/model-settings/{settingsId}',
            path: {
                'settingsId': settingsId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get all models by type
     * Fetches a list of all models by type.
     * @param type
     * @returns AiModelDto List of models retrieved successfully
     * @throws ApiError
     */
    public static getAvailableModels(
        type?: 'LLM' | 'TTS' | 'STT',
    ): CancelablePromise<Array<AiModelDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/models',
            query: {
                'type': type,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates a new model
     * Creates a new model with the required fields: providerName, modelName, modelType.
     * @param requestBody
     * @returns AiModelDto Model created successfully
     * @throws ApiError
     */
    public static createModel(
        requestBody: AiModelRequest,
    ): CancelablePromise<AiModelDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/inventory/models',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Get all model settings
     * @param xAiAgentOrganizationContext
     * @param modelId
     * @param modelType
     * @param name
     * @returns ModelSettingsDto Successful response
     * @throws ApiError
     */
    public static getSettings1(
        xAiAgentOrganizationContext: string,
        modelId?: string,
        modelType?: 'LLM' | 'TTS' | 'STT',
        name?: string,
    ): CancelablePromise<ModelSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/models/model-settings',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            query: {
                'modelId': modelId,
                'modelType': modelType,
                'name': name,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Creates model settings
     * @param xAiAgentOrganizationContext
     * @param requestBody
     * @returns ModelSettingsDto Successful response
     * @throws ApiError
     */
    public static createSettings1(
        xAiAgentOrganizationContext: string,
        requestBody: ModelSettingsRequest,
    ): CancelablePromise<ModelSettingsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/inventory/models/model-settings',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Gets model by id
     * Gets model by identifier.
     * @param modelId
     * @returns AiModelDto Model DTO. Successful response.
     * @throws ApiError
     */
    public static getModelById(
        modelId: string,
    ): CancelablePromise<AiModelDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/inventory/models/{modelId}',
            path: {
                'modelId': modelId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
