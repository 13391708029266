import clsx from 'clsx';
import { ContentCopyOutlined } from '@mui/icons-material';

import { notify } from '../../app';

export const onCopy = (text = 'Text copied successfully.') => {
  return text && notify.success(text);
};
export const copyToClipboard = async (
  text: string,
  successMessage?: string
): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);

    onCopy(successMessage);
    return true;
  } catch (err) {
    console.error('Failed to copy: ', err);
    return false;
  }
};

type CopyProps = {
  text?: string;
  label?: string;
  className?: string;
};

export const Copy: React.FC<CopyProps> = ({ text, label, className }) => {
  return (
    <span
      onClick={text ? () => copyToClipboard(text) : undefined}
      className={clsx(
        className,
        text ? 'cursor-pointer' : '',
        label ? 'flex items-center gap-x-sm' : ''
      )}
    >
      <ContentCopyOutlined />
      {label && <div className="shrink-0">{label}</div>}
    </span>
  );
};
