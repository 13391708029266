import React, { useState } from 'react';

import { Input } from '../../../components';
import { Form, FormItem, SubmitButton, Button } from '../../../components';
import type { RoleFormType } from './useRoles';

type PartialForm = Partial<RoleFormType>;

type RoleFormProps = {
  saving: boolean;
  isEdit?: boolean;
  form?: PartialForm | null;

  onCancel: () => void;
  onSave: (form: RoleFormType) => void;
};

export const RoleForm: React.FC<RoleFormProps> = ({
  form,
  isEdit,
  saving,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialForm>(form ?? {});

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<RoleFormType>
          required
          name="name"
          label="Name"
          inline={false}
        >
          <Input withBorder placeholder="Enter Role Name" />
        </FormItem>
        <FormItem<RoleFormType>
          required
          name="description"
          label="Description"
          inline={false}
        >
          <Input withBorder placeholder="Enter Role Description" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            disabled={saving}
            className="w-full"
            label={isEdit ? 'Update' : 'Create'}
            onClick={() => onSave(values as RoleFormType)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
