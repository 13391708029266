import clsx from 'clsx';

type ToggleProps = {
  disabled?: boolean;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  label?: string;
  labelPosition?: 'left' | 'right';
  labelClass?: string;
};

export const Toggle: React.FC<ToggleProps> = ({
  disabled,
  value,
  onChange,
  label,
  labelPosition = 'left',
  labelClass = 'text-gray-700 text-medium',
}) => {
  const positionClass =
    labelPosition === 'left' ? 'flex-row-reverse justify-end' : 'flex-row';

  return (
    <div className={clsx('flex items-center gap-2 justify-end', positionClass)}>
      {label && (
        <span
          className={clsx(
            labelClass,
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          )}
        >
          {label}
        </span>
      )}
      <input
        type="checkbox"
        checked={value}
        onChange={!disabled ? e => onChange?.(e.target.checked) : () => {}}
        className={clsx(
          disabled ? 'disabled opacity-40 pointer-events-none' : '',
          'relative appearance-none w-[4.75rem] h-10 p-[0.05rem]',
          'bg-contrast border border-border shadow-sm text-transparent rounded-full cursor-pointer',
          'transition-colors ease-in-out duration-200 focus:main disabled:opacity-50 disabled:pointer-events-none',
          'checked:bg-main checked:text-contrast checked:border-contrast focus:checked:contrast before:inline-block',
          'before:size-9 before:bg-main checked:before:bg-contrast before:translate-x-0 checked:before:translate-x-full',
          'before:opacity-80 checked:before:opacity-100',
          'before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200'
        )}
      />
    </div>
  );
};
