/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntentDto } from '../models/IntentDto';
import type { IntentFunctionRequest } from '../models/IntentFunctionRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class IntentsAndHandlersApiService {
    /**
     * @param intentId
     * @returns IntentDto Success response
     * @throws ApiError
     */
    public static getIntentById(
        intentId: string,
    ): CancelablePromise<IntentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/intents_and_handlers/{intentId}',
            path: {
                'intentId': intentId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param intentId
     * @param requestBody
     * @returns IntentDto Success response
     * @throws ApiError
     */
    public static updateIntent(
        intentId: string,
        requestBody: IntentFunctionRequest,
    ): CancelablePromise<IntentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/intents_and_handlers/{intentId}',
            path: {
                'intentId': intentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param intentId
     * @returns void
     * @throws ApiError
     */
    public static deleteIntent(
        intentId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/intents_and_handlers/{intentId}',
            path: {
                'intentId': intentId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns IntentDto Success response
     * @throws ApiError
     */
    public static addIntent(
        requestBody: IntentFunctionRequest,
    ): CancelablePromise<IntentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/intents_and_handlers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param agentId
     * @returns IntentDto Success response
     * @throws ApiError
     */
    public static getIntentsByAgentId(
        agentId: string,
    ): CancelablePromise<Array<IntentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/intents_and_handlers/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
