import { useState } from 'react';

import { notify } from '../../../app';
import { useFetch, useModal, useRequest } from '../../../hooks';
import { getPermissionsList, addPermission } from '../../../api';
import { deletePermission, updatePermission } from '../../../api';
import type { PermissionDto, PermissionCreateRequest } from '../../../api';

export type PermissionRowType = PermissionDto;
export type PermissionFormType = PermissionCreateRequest & { id?: string };

export const usePermissions = () => {
  const [search, setSearch] = useState('');
  const { isOpened, onClose, onOpen } = useModal();
  const [permissionForDelete, setPermissionForDelete] = useState<
    string | undefined
  >();

  const {
    isOpened: openedDeleteModal,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useModal();
  const handleOpenDeleteRoleModal = (roleId: string) => {
    setPermissionForDelete(roleId);
    openDeleteModal();
  };
  const [currentForm, setCurrentForm] = useState<
    Partial<PermissionFormType> | undefined
  >(undefined);
  const currentFormId = currentForm?.id;
  const handleClose = () => {
    onClose();
    setCurrentForm(undefined);
  };
  const [handleDeletePermission, deleting] = useRequest(
    _signal => {
      return permissionForDelete ? deletePermission(permissionForDelete) : null;
    },
    {
      deps: [permissionForDelete],
      onSuccess: () => {
        refresh();
        closeDeleteModal();
        notify.success('Permission deleted');
      },
      onError: () => {
        notify.error('Failed to delete permission');
      },
    }
  );

  const {
    data,
    intializing: loading,
    refresh,
  } = useFetch(() => getPermissionsList(), []);
  const [handleAddPermission, adding] = useRequest(
    (_signal, form: PermissionFormType) => addPermission(form),
    {
      deps: [],
      onSuccess: () => {
        notify.success('Permission created');
        refresh?.();
        onClose();
      },
      onError: () => {
        notify.error('Failed to create new permission');
      },
    }
  );
  const [handleUpdatePermission, updating] = useRequest(
    (_signal, form: PermissionFormType) =>
      currentFormId ? updatePermission(currentFormId, form) : null,
    {
      deps: [currentFormId],
      onSuccess: () => {
        notify.success('Permission updated');
        refresh?.();
        onClose();
      },
      onError: () => {
        notify.error('Failed to update permission');
      },
    }
  );
  return {
    permissions: data || [],
    loading,

    updating: adding || updating || deleting,
    handleAddPermission,
    handleUpdatePermission,

    isOpened,
    onOpen,
    currentForm,
    currentFormId,
    isEdit: !!currentFormId,
    onClose: handleClose,

    search,
    setSearch,

    openedDeleteModal,
    closeDeleteModal,

    setCurrentForm,
    deletePermission: handleDeletePermission,
    openDeleteModal: handleOpenDeleteRoleModal,
  };
};
