import React, { useEffect, useState } from 'react';

import {
  Form,
  FormItem,
  SubmitButton,
  Button,
  Input,
  Loading,
  Container,
  Toggle,
} from '../../../../components';
import { notify } from '../../../../app';
import { useAuth } from '../../../../hooks';
import { useCompressor } from '../useCompressor';
import { validateChunkSize } from '../utils/validator';

export type FormProps = {
  custom_prompt?: string;
  duplicate_prompt?: string;
  chunk_size?: number;
};

type TaskFormProps = {
  saving: boolean;
  onCancel: () => void;
  onSave: () => void;
  taskId: string;
  type: string;
};

const CompressPromptForm: React.FC<TaskFormProps> = ({
  saving,
  onSave,
  onCancel,
  taskId,
}) => {
  const { user } = useAuth();
  const {
    getTaskResultWithPromptById,
    handleCompressWithPrompt,
    getTasksList,
  } = useCompressor();

  const [formValues, setFormValues] = useState<FormProps>({
    custom_prompt: '',
  });
  const [loading, setLoading] = useState(true);
  const [showCustomPrompt, showCustomPromptField] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getTaskResultWithPromptById(taskId);
        const customPrompt = response?.generated_prompt;

        if (customPrompt) {
          setFormValues({
            custom_prompt: customPrompt,
            chunk_size: 1000,
            duplicate_prompt: response.duplicate_prompt || '',
          });
        }
      } catch (error) {
        console.error('Failed to fetch task result:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getTaskResultWithPromptById, taskId]);

  const handleSave = () => {
    console.log('formValues.chunk_size', typeof formValues.chunk_size);

    const data = {
      custom_prompt: formValues.custom_prompt || '',
      duplicate_prompt: formValues.duplicate_prompt || '',
      task_id: taskId,
      user_name: user?.name,
      chunk_size: formValues.chunk_size,
    };
    handleCompressWithPrompt(data);
    getTasksList();
    notify.success('Compressing task started');
    onSave();
  };

  return (
    <div>
      {loading ? (
        <Loading height="20vh" />
      ) : (
        <Form onChange={setFormValues} initialValue={formValues}>
          <Container>
            <div className="flex justify-between direction-row w-full">
              <FormItem<FormProps>
                name="chunk_size"
                label="Chunk Size"
                className="w-10"
                labelContainerClassName="min-w-[8rem] w-full"
                validate={validateChunkSize}
                errorBelowInput
                required
              >
                <Input size="small" disabled={loading} numeric />
              </FormItem>
              <Toggle
                label="Show Duplicate Prompt"
                disabled={loading}
                value={showCustomPrompt}
                onChange={() => showCustomPromptField(!showCustomPrompt)}
              />
            </div>
            <FormItem<FormProps>
              required
              name="custom_prompt"
              label=""
              inline={false}
              className="mt-4"
            >
              <Input
                multiline
                size="large"
                value={formValues.custom_prompt}
                disabled={loading}
              />
            </FormItem>
            {showCustomPrompt && (
              <FormItem<FormProps>
                name="duplicate_prompt"
                label="Duplicate Prompt"
                inline={false}
                className="mt-4"
              >
                <Input
                  multiline
                  size="large"
                  value={formValues.duplicate_prompt}
                  disabled={loading}
                />
              </FormItem>
            )}
          </Container>

          <div className="flex gap-x-4 justify-end mt-4">
            <Button
              size="large"
              type="contrast"
              label="Cancel"
              className="custom_prompt-main"
              onClick={onCancel}
              disabled={loading}
            />
            <SubmitButton
              size="large"
              disabled={saving || loading}
              className="bg-purple-500 custom_prompt-white"
              label={loading ? 'Loading...' : 'Compress'}
              onClick={() => handleSave()}
            />
          </div>
        </Form>
      )}
    </div>
  );
};

export default CompressPromptForm;
