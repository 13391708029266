import { useState } from 'react';

import { notify } from '../../../app';
import { getRoles, getOrganizations, inviteUser } from '../../../api';
import { getAllUsers, addUser, assignUserToRole } from '../../../api';
import { useFetch, useListFetch, useModal, useRequest } from '../../../hooks';
import type { UserListType } from '../../../api';
import type { UserFormType, AssignUserToRolePayload } from '../../../api';

export const useUsers = () => {
  const [search, setSearch] = useState('');

  const {
    nodes: users = [],
    intializing: loading,
    loading: reloading,
    refresh,

    ...rest
  } = useListFetch(
    params =>
      getAllUsers(
        params?.pagination?.page,
        params?.pagination?.perPage,
        params?.sort
      ),
    { deps: [] }
  );
  const { isOpened, onClose, onOpen } = useModal();
  const [handleAddUser, adding] = useRequest(
    async (_signal, values: UserFormType) => {
      await addUser(values);
    },
    {
      onSuccess: () => {
        refresh();
        onClose?.();
        notify.success('User created');
      },
      onError: () => {
        notify.error('Failed to create new user');
      },
      deps: [],
    }
  );

  const {
    isOpened: assignOpened,
    onClose: closeAssignModal,
    onOpen: openAssignModal,
  } = useModal();
  const [formForAction, setFormForAction] = useState<UserListType | undefined>(
    undefined
  );
  const { data: roles, intializing: loadingRoles } = useFetch(
    () => getRoles(),
    []
  );
  const rolesOptions: OptionType[] = (roles || [])
    .map(o => ({
      value: o.id ?? '',
      label: o.name ?? '',
    }))
    .filter(o => o.value && o.label);
  const { data: organizations, intializing: loadingOrganizations } = useFetch(
    () => getOrganizations(),
    []
  );
  const organizationsOptions: OptionType[] = (organizations || [])
    .map(o => ({
      value: o.id ?? '',
      label: o.name ?? '',
    }))
    .filter(o => o.value && o.label);
  const [handleAssignUser, assigning] = useRequest(
    (_signal, values: AssignUserToRolePayload) => assignUserToRole(values),
    {
      onSuccess: () => {
        refresh();
        closeAssignModal?.();
        notify.success('User assigned');
      },
      onError: (error: any) => {
        notify.error(
          `Failed to assign user: ${error?.body?.detail ?? 'No details'}`
        );
      },
      deps: [],
    }
  );
  const handleOpenAssignModal = (row: UserListType) => {
    setFormForAction(row);
    openAssignModal();
  };

  const [handleInviteUser, inviting] = useRequest(
    (_signal, userId?: string) => (userId ? inviteUser(userId) : null),
    {
      onSuccess: () => notify.success('User invited'),
      onError: () => notify.error('Failed invite user'),
      deps: [],
    }
  );

  return {
    ...rest,

    users: users || [],
    loading: loading || loadingRoles || loadingOrganizations,
    reloading,

    search,
    setSearch,

    updating: adding || assigning,
    isOpened,
    onClose,
    onOpen,
    refresh,

    handleAddUser,

    assignOpened,
    formForAction,
    closeAssignModal,
    handleAssignUser,
    handleOpenAssignModal,

    inviting,
    handleInviteUser,

    roles: rolesOptions,
    organizations: organizationsOptions,
  };
};

export type {
  UserListType,
  UserFormType,
  AssignUserToRolePayload,
} from '../../../api';
