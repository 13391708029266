import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Code, AccountTree } from '@mui/icons-material';
//@ts-ignore
import { FlowDesigner } from '@next-level-ai/ai-agents-conversation-workflow';

import { useInterval, useModal, useUserSettings } from '../../hooks';
import { Button, Modal, JsonViewModal, Toggle } from '../../components';

type FlowInputProps = {
  value?: string;
  onChange?: (newValue: string) => void;
  onSave?: (newValue: string, needMessage?: boolean) => Promise<void>;

  loading?: boolean;
  disabled?: boolean;
};

type DesignerComponentProps = FlowInputProps & {
  isOpened: boolean;
  onClose: () => void;
};

const AUTO_SAVE_PERIOD = 10 * 1000;

const DesignerComponent: React.FC<DesignerComponentProps> = ({
  isOpened,
  onClose,
  value,
  loading,

  onSave,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(value ?? '');
  const [autoSave, setAutoSave] = useUserSettings(false, 'is_auto_save_on');

  const title = value ? 'Edit flow' : 'Add flow';
  useInterval(
    () => onSave?.(localValue),
    AUTO_SAVE_PERIOD,
    value !== localValue && autoSave && !loading && !!onSave
  );

  useEffect(
    () => {
      if (value !== localValue && value) {
        setLocalValue(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  return (
    <Modal
      size="full"
      title={title}
      open={isOpened}
      onClose={onClose}
      key={String(isOpened)}
      headerActions={[
        !!onSave && (
          <div
            key="AutoSave"
            className="flex items-center justify-center gap-x-sm text-small"
          >
            <Toggle value={autoSave} onChange={() => setAutoSave(a => !a)} />
            <span>Auto-save</span>
          </div>
        ),
        <Button
          key="save"
          label="Save"
          loading={loading}
          onClick={() => {
            onChange?.(localValue);
            onSave?.(localValue, true);
            onClose();
          }}
        />,
      ]}
    >
      <FlowDesigner value={localValue} onChange={setLocalValue} />
    </Modal>
  );
};

export const FlowInput: React.FC<FlowInputProps> = ({
  value,
  onSave,
  onChange,

  disabled,
  ...rest
}) => {
  const { isOpened, onOpen, onClose } = useModal();
  const object = useMemo(() => {
    try {
      return value ? JSON.parse(value) : {};
    } catch {
      return {};
    }
  }, [value]);
  const hasValue = !_.isEmpty(value);
  const title = hasValue ? 'Edit flow' : 'Add flow';

  return (
    <div className="flex gap-x-md">
      <Button
        type="light"
        size="medium"
        label={title}
        onClick={onOpen}
        disabled={disabled}
        icon={<AccountTree />}
      />
      {hasValue && (
        <JsonViewModal
          type="light"
          size="medium"
          value={object}
          icon={<Code />}
          disabled={disabled}
          label="View flow json"
        />
      )}
      <DesignerComponent
        {...rest}
        key={String(isOpened)}
        value={value}
        isOpened={isOpened}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />
    </div>
  );
};
