import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { notify } from '../../app';
import { OpenAPI } from '../../services';
import { OpenAPI as ScraperOpenAPI } from '../../services/scraper';
import { OpenAPI as CompressorOpenAPI } from '../../services/compressor';
import type { UserType } from '.';

export const useToken = () => {
  const auth0 = useAuth0();
  const [token, setToken] = useState('');
  const [user, setUser] = useState<UserType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently, getIdTokenClaims, loginWithRedirect } = auth0;

  const auth0User = auth0.user;
  const auth0isLoading = auth0.isLoading;

  useEffect(
    () => {
      if (auth0isLoading) return;

      if (!auth0User?.sub) {
        setUser(null);
        setToken('');
        return setIsLoading(false);
      }

      let ignore = false;

      const getToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
              scope: 'read:current_user offline_access',
            },
          });

          const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${auth0User?.sub}`;

          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const tok = await getIdTokenClaims();
          const user = await metadataResponse.json();

          if (ignore) return;

          const apiToken = tok?.__raw ?? '';
          if (!apiToken || typeof apiToken !== 'string') {
            throw new Error('Please re-login');
          }

          setUser(user);
          setToken(apiToken);
          setIsLoading(false);

          OpenAPI.TOKEN = apiToken;
          ScraperOpenAPI.TOKEN = apiToken;
          CompressorOpenAPI.TOKEN = apiToken;
        } catch (ex) {
          if (ignore) return;

          notify.error('There was an error logging in: ' + ex);

          setToken('');
          setUser(null);
          setIsLoading(false);

          OpenAPI.TOKEN = '';
          ScraperOpenAPI.TOKEN = '';
          CompressorOpenAPI.TOKEN = '';

          if (String(ex).toLowerCase().includes('refresh token')) {
            loginWithRedirect?.();
          }
        }
      };

      getToken();

      return () => {
        ignore = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0User, auth0isLoading, getIdTokenClaims, getAccessTokenSilently]
  );

  useEffect(() => {
    if (!token) return;
    try {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenPayload.exp * 1000;

      const intervalId = setInterval(() => {
        const timeUntilExpiry = expirationTime - Date.now();

        if (timeUntilExpiry > 60_000) return;

        const getToken = async () => {
          const tok = await getIdTokenClaims();
          const apiToken = tok?.__raw ?? '';

          if (!apiToken || typeof apiToken !== 'string') {
            setToken('');
            setUser(null);
            setIsLoading(false);
            return;
          }

          setToken(apiToken);
        };

        getToken();
      }, 30_000);

      return () => {
        clearInterval(intervalId);
      };
    } catch {
      setUser(null);
      setToken('');
      return setIsLoading(false);
    }
  }, [token, getIdTokenClaims]);

  return { ...auth0, isLoading: isLoading || auth0.isLoading, user, token };
};
