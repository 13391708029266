/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from './JsonNode';
export type AiModelDto = {
    id?: string;
    modelName?: string;
    providerName?: string;
    type?: AiModelDto.type;
    settings?: JsonNode;
    settingsValidationSchema?: JsonNode;
    uiSchema?: JsonNode;
};
export namespace AiModelDto {
    export enum type {
        LLM = 'LLM',
        TTS = 'TTS',
        STT = 'STT',
    }
}

