import { z } from 'zod';

const jsonSchema = z.object({
  sample_rate: z.number().nullable().optional(),
  retry_interval: z
    .number()
    .int()
    .positive()
    .describe('Retry interval is required and must be a positive integer'),
  attempt_timeout: z
    .number()
    .int()
    .positive()
    .describe('Attempt timeout is required and must be a positive integer'),
  max_retry_per_tts: z
    .number()
    .int()
    .positive()
    .describe('Max retry per TTS is required and must be a positive integer'),
  no_fallback_after_audio_duration: z
    .number()
    .int()
    .positive()
    .describe(
      'No fallback after audio duration is required and must be a positive integer'
    ),
});

export const validateJSONInput = (jsonInput?: string) => {
  if (!jsonInput) return false;

  try {
    const parsedData = JSON.parse(jsonInput);
    jsonSchema.parse(parsedData);
    return false;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return error.errors
        .map(err => `${err.path.join('.')}: ${err.message}`)
        .join(', ');
    } else if (error instanceof SyntaxError) {
      return 'JSON Syntax Error';
    }
    return 'Invalid JSON';
  }
};
