import { AudioPlayer, ButtonWithModal, Card, Copy } from '../../components';
import { JsonViewModal } from '../../components';
import { formatDate, getDurationFromSeconds } from '../../utilities';
import type {
  ConversationAudioFileResponse,
  ConversationResponse,
} from '../../api';
import type { ParticipantInfoResponse } from '../../api';

type DetailsProps = {
  conversation: ConversationResponse | null;
  participant: ParticipantInfoResponse | null;
  audio: Array<ConversationAudioFileResponse>;
};

export const Details: React.FC<DetailsProps> = ({
  participant,
  conversation,
  audio = [],
}) => {
  const userName = participant
    ? [participant.first_name, participant?.last_name]
        .filter(n => !!n)
        .join(' ')
    : '';

  return (
    <Card
      size="medium"
      title="Details"
      actions={
        participant
          ? [
              <ButtonWithModal
                label="Message Summary"
                content={
                  conversation?.extracted_summary ?? '*No summary available.*'
                }
              />,
              <JsonViewModal
                key="data"
                value={participant}
                label="Participant Info"
              />,
            ]
          : []
      }
      className="shrink-0 sticky top-0 right-0 bg-buttonLight"
      contentClassName="-mr-md pr-sm overflow-y-scroll"
    >
      <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">
        Partipiciants info
      </p>
      <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
        <div className="flex justify-between">
          <span className="text-small font-medium">
            Agent: {conversation?.agent_title}
          </span>
        </div>
        <div className="flex justify-between mt-md bg-buttonLightText">
          <div>
            <span className="text-small font-medium text-light">ID: </span>
            <span className="text-small font-medium mt-xl">
              {conversation?.agent_id || '-'}
            </span>
          </div>
          {!!conversation?.agent_id && (
            <Copy className="shrink-0" text={conversation?.agent_id} />
          )}
        </div>
      </div>
      {/* User name */}
      {!!participant && (
        <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
          <div className="flex justify-between">
            <span className="text-small font-medium">
              User: {userName || '-'}
            </span>
          </div>
          {!!participant.id && (
            <div className="flex justify-between mt-md">
              <div>
                <span className="text-small font-medium text-light">ID: </span>
                <span className="text-small font-medium mt-xl">
                  {participant.id}
                </span>
              </div>
              <Copy className="shrink-0" text={participant.id} />
            </div>
          )}
        </div>
      )}
      <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">
        Timestamps
      </p>
      <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
        <div className="flex justify-between">
          <div>
            <span className="text-small font-medium text-light mr-xs">
              Started at:
            </span>
            <span className="text-small font-medium mt-xl">
              {formatDate(conversation?.started_at)}
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-md">
          <div>
            <span className="text-small font-medium text-light mr-xs">
              Finished at:
            </span>
            <span className="text-small font-medium mt-xl">
              {formatDate(conversation?.ended_at)}
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-md">
          <div>
            <span className="text-small font-medium text-light mr-xs">
              Duration:
            </span>
            <span className="text-small font-medium mt-xl">
              {getDurationFromSeconds(conversation?.duration)}
            </span>
          </div>
        </div>
      </div>

      <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">Audio</p>
      <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
        <div className="flex flex-col">
          {audio.length ? (
            audio.map(({ link, fileName }, index) => (
              <div key={fileName} className="flex my-2">
                <div className="text-small font-xs text-light mr-xs flex flex-row items-center">
                  <span className="mr-xs">Record:</span>
                  <span>{index + 1}</span>
                </div>
                <AudioPlayer src={`${link}`} />
              </div>
            ))
          ) : (
            <div className="text-small font-xs text-light">No audio</div>
          )}
        </div>
      </div>

      <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">Tokens</p>
      <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
        <div className="flex justify-between">
          <div>
            <span className="text-small font-medium text-light">
              Input token:
            </span>
            <span className="text-small font-medium mt-xl">
              {/* {conversation.input_token} */}
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-md">
          <div>
            <span className="text-small font-medium text-light">
              Output token:
            </span>
            <span className="text-small font-medium mt-xl">
              {/* {conversation.output_token} */}
            </span>
          </div>
        </div>
      </div>
      <p className="text-gray-500 dark:text-gray-400 text-tiny mt-md">
        Channels
      </p>
      <div className="relative border border-border rounded-card p-md mt-3 bg-buttonLightText">
        <div className="flex justify-between">
          <div>
            <span className="text-small font-medium text-light mr-2">
              Conversation channel:
            </span>
            <span className="text-small font-medium mt-xl">
              {conversation?.type || ''}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
