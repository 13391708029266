import { useMemo } from 'react';
import type { AiModelData } from '../../../api';

type UseModelsByIdProps = {
  form?: Record<string, any> | null;
  ttsModels?: AiModelData[] | null;
  sttModels?: AiModelData[] | null;
  llmModels?: AiModelData[] | null;
};

const isModelValid = (model?: AiModelData) => {
  if (!model || typeof model !== 'object') return false;
  const uiSchemaValid =
    !!model.uiSchema && Object.keys(model.uiSchema).length > 0;
  const jsonSchemaValid =
    !!model.jsonSchema && Object.keys(model.jsonSchema).length > 0;
  return uiSchemaValid && jsonSchemaValid;
};

export const useModelsById = ({
  ttsModels,
  sttModels,
  llmModels,
  form,
}: UseModelsByIdProps) => {
  const getModelByField = useMemo(() => {
    return (fieldKey: string): AiModelData => {
      const id = form?.[fieldKey];
      let modelList: AiModelData[] = [];

      if (fieldKey.startsWith('tts')) {
        modelList = ttsModels ?? [];
      } else if (fieldKey.startsWith('stt')) {
        modelList = sttModels ?? [];
      } else if (fieldKey.startsWith('llm')) {
        modelList = llmModels ?? [];
      }

      const found = modelList.find(model => model.value === id);
      return found || modelList[0] || ({} as AiModelData);
    };
  }, [form, ttsModels, sttModels, llmModels]);

  const models = useMemo(() => {
    const ttsPrimary = getModelByField('tts_primary_model_id');
    const ttsBackup = getModelByField('tts_backup_model_id');
    const sttPrimary = getModelByField('stt_primary_model_id');
    const sttBackup = getModelByField('stt_backup_model_id');
    const llmPrimary = getModelByField('llm_primary_model_id');
    const llmBackup = getModelByField('llm_backup_model_id');
    const turnDetector = getModelByField('llm_turn_detector_model_id');

    return {
      ttsPrimary,
      ttsBackup,
      sttPrimary,
      sttBackup,
      llmPrimary,
      llmBackup,
      turnDetector,
      validity: {
        ttsPrimary: isModelValid(ttsPrimary),
        ttsBackup: isModelValid(ttsBackup),
        sttPrimary: isModelValid(sttPrimary),
        sttBackup: isModelValid(sttBackup),
        llmPrimary: isModelValid(llmPrimary),
        llmBackup: isModelValid(llmBackup),
        turnDetector: isModelValid(turnDetector),
      },
    };
  }, [getModelByField]);

  return {
    getModelByField,
    ...models,
  };
};
