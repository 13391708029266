import { ManagementFormControllerService } from '../services';
import type { NodeJSON } from './agent';

export type FormJsonData = {
  name?: string;
  jsonSchema: NodeJSON;
  uiSchema: NodeJSON;
};

export const getFormByName = (name: string): Promise<FormJsonData | void> => {
  return ManagementFormControllerService.getFormByName(name);
};

export const getForm = (): Promise<any> =>
  ManagementFormControllerService.getForm();

export const updateForm = (form: FormJsonData): Promise<any> =>
  ManagementFormControllerService.updateForm(form);

export const createForm = (form: any): Promise<any> =>
  ManagementFormControllerService.createForm(form);
