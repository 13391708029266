/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParsePayload } from '../models/ParsePayload';
import type { SpiderResponse } from '../models/SpiderResponse';
import type { TaskResponse } from '../models/TaskResponse';
import type { TasksDetails } from '../models/TasksDetails';
import type { TasksResponse } from '../models/TasksResponse';
import type { TaskStatus } from '../models/TaskStatus';
import type { TaskStatusResponse } from '../models/TaskStatusResponse';
import type { TreeResultSpiderResponse } from '../models/TreeResultSpiderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SpiderService {
    /**
     * Submit Parse Request
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static submitParseRequestScraperApiV1SpiderParsePost(
        requestBody: ParsePayload,
    ): CancelablePromise<(TaskResponse | TasksResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scraper/api/v1/spider/parse/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel Task
     * @param taskId
     * @returns void
     * @throws ApiError
     */
    public static cancelTaskScraperApiV1SpiderTasksTaskIdCancelDelete(
        taskId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/scraper/api/v1/spider/tasks/{task_id}/cancel/',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Task Status
     * @param taskId
     * @returns TaskStatusResponse Successful Response
     * @throws ApiError
     */
    public static getTaskStatusScraperApiV1SpiderTasksTaskIdStatusGet(
        taskId: string,
    ): CancelablePromise<TaskStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scraper/api/v1/spider/tasks/{task_id}/status/',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Retry Task
     * Endpoint to retry a failed Celery task.
     * @param taskId
     * @returns TaskStatusResponse Successful Response
     * @throws ApiError
     */
    public static retryTaskScraperApiV1SpiderTasksTaskIdRetryPost(
        taskId: string,
    ): CancelablePromise<TaskStatusResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scraper/api/v1/spider/tasks/{task_id}/retry',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Tasks
     * @param page
     * @param limit
     * @param url Search tasks by URL
     * @param status Filter tasks by status
     * @returns TasksDetails Successful Response
     * @throws ApiError
     */
    public static getTasksScraperApiV1SpiderTasksGet(
        page: number = 1,
        limit: number = 10,
        url?: (string | null),
        status?: (TaskStatus | null),
    ): CancelablePromise<TasksDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scraper/api/v1/spider/tasks/',
            query: {
                'page': page,
                'limit': limit,
                'url': url,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Parsed Data
     * @param taskId
     * @param page
     * @param limit
     * @returns SpiderResponse Successful Response
     * @throws ApiError
     */
    public static getParsedDataScraperApiV1SpiderTasksTaskIdResultGet(
        taskId: string,
        page: number = 1,
        limit: number = 50,
    ): CancelablePromise<SpiderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scraper/api/v1/spider/tasks/{task_id}/result/',
            path: {
                'task_id': taskId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Download Parsed Data
     * @param taskId
     * @returns any Download parsed data as a ZIP file
     * @throws ApiError
     */
    public static downloadParsedDataScraperApiV1SpiderResultTaskIdDownloadGet(
        taskId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scraper/api/v1/spider/result/{task_id}/download/',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Result Tree
     * @param taskId
     * @returns TreeResultSpiderResponse Successful Response
     * @throws ApiError
     */
    public static getResultTreeScraperApiV1SpiderResultTaskIdTreeGet(
        taskId: string,
    ): CancelablePromise<TreeResultSpiderResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scraper/api/v1/spider/result/{task_id}/tree/',
            path: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
