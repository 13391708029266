import { withJsonFormsControlProps, useJsonForms } from '@jsonforms/react';
import { updateErrors } from '@jsonforms/core';
import { CountryGreetingField } from '../CustomComponents/CountryGreetingField';
import type { ControlProps } from '@jsonforms/core';

const CountryGreetingControl = ({
  data,
  handleChange,
  path,
  label,
  schema,
}: ControlProps) => {
  const { dispatch } = useJsonForms();

  const sendError = (errorMessage: string | null) => {
    dispatch?.(
      updateErrors(
        errorMessage
          ? [
              {
                message: errorMessage,
                instancePath: path.startsWith('/') ? path : `/${path}`,
                keyword: 'custom',
                schemaPath: '',
                params: {},
              },
            ]
          : []
      )
    );
  };

  const languages =
    ((schema as any)?.['x-options']?.languages as string[]) || [];

  return (
    <CountryGreetingField
      value={data || {}}
      label={label}
      updateValue={(newValue, error) => {
        handleChange(path, newValue);
        sendError(error);
      }}
      languages={languages}
      classes={{
        autocomplete: 'bg-white',
        chip: 'mt-2',
        iconButton: 'text-main',
        itemBox: 'hover:bg-gray-50',
        helperText: 'm-2 text-small text-gray-500',
        jsonInput: 'text-small',
      }}
    />
  );
};

export default withJsonFormsControlProps(CountryGreetingControl);
