import React, { useState, useEffect, useMemo } from 'react';
import { useFormSchema } from '../agent/hooks/useFormSchema';
import { formatSnakeCase, formatTitleCase } from './utils';
import { Button } from '../../components';
import type { SchemaConfig, SchemaProperty } from './types';

import GroupSelector from './components/GroupSelector';
import PropertyList from './components/PropertyList';
import SchemaPreview from './components/SchemaPreview';
import PropertyModal from './components/PropertyModal';

const SchemaEditorPage: React.FC = () => {
  const {
    data: jsonSchema,
    intializing,
    handleUpdateForm,
  } = useFormSchema('agentWidgetSettings');

  const [schema, setSchema] = useState<SchemaConfig | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedProperty, setHighlightedProperty] = useState<string | null>(
    null
  );

  const layout = useMemo(
    () =>
      schema?.uiSchema.elements
        .find(g => g.label === selectedGroup)
        ?.elements.filter(el => el.type === 'HorizontalLayout')
        .map((el, index) => `Layout ${index + 1}`),
    [schema, selectedGroup]
  );

  useEffect(() => {
    if (jsonSchema) {
      setSchema(jsonSchema as SchemaConfig);
    }
  }, [jsonSchema]);

  if (intializing || !schema) {
    return (
      <div className="p-6 bg-gray-100 min-h-screen flex justify-center items-center">
        <p className="text-lg font-semibold text-gray-600">Loading schema...</p>
      </div>
    );
  }

  const handleRemoveProperty = (propName: string) => {
    if (!selectedGroup || !schema) return;

    const updatedSchema = { ...schema };
    const group = updatedSchema.uiSchema.elements.find(
      g => g.label === selectedGroup
    );

    if (group) {
      group.elements = group.elements
        ?.map(layout => ({
          ...layout,
          elements:
            layout.elements?.filter(el => !el.scope.includes(propName)) || [],
        }))
        .filter(layout => layout.elements.length > 0);

      if (updatedSchema.jsonSchema.properties[propName]) {
        delete updatedSchema.jsonSchema.properties[propName];
      }

      setSchema(updatedSchema);
      setHighlightedProperty(propName);
      setTimeout(() => setHighlightedProperty(null), 3000);
    }
  };

  const handleAddProperty = (
    name: string,
    type: string,
    layout: string,
    enumValues?: string
  ) => {
    if (!selectedGroup || !layout || !name.trim()) return;

    const formattedKey = formatSnakeCase(name);
    const formattedTitle = formatTitleCase(name);

    const updatedSchema = { ...schema };
    const group = updatedSchema.uiSchema.elements.find(
      g => g.label === selectedGroup
    );

    if (group) {
      const selectedLayout = group.elements[Number(layout) - 1];

      const existingPropertyIndex = selectedLayout.elements.findIndex(el =>
        el.scope.includes(formattedKey)
      );

      if (existingPropertyIndex !== -1) {
        selectedLayout.elements[existingPropertyIndex] = {
          type: 'Control',
          scope: `#/properties/${formattedKey}`,
        };
      } else {
        selectedLayout.elements.push({
          type: 'Control',
          scope: `#/properties/${formattedKey}`,
        });
      }

      updatedSchema.jsonSchema.properties[formattedKey] = {
        type: type as SchemaProperty['type'],
        title: formattedTitle,
        ...(type === 'enum' && {
          enum: enumValues ? enumValues.split(',').map(val => val.trim()) : [],
        }),
      };

      setSchema(updatedSchema);
      setHighlightedProperty(formattedKey);
      setIsOpen(false);

      setTimeout(() => setHighlightedProperty(null), 3000);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex">
      <div className="w-1/3 bg-white p-6 rounded-md shadow-md h-fit">
        <h1 className="text-2xl font-semibold mb-4">Schema Editor</h1>

        <GroupSelector
          groupNames={schema.uiSchema?.elements?.map(g => g.label) || []}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />

        {selectedGroup && (
          <PropertyList
            schema={schema}
            selectedGroup={selectedGroup}
            highlightedProperty={highlightedProperty}
            handleRemoveProperty={handleRemoveProperty}
          />
        )}

        <div className="mt-4 flex flex-col gap-3">
          <Button
            label="+ Add Property"
            onClick={() => setIsOpen(true)}
            disabled={!selectedGroup}
          />
          <Button
            label="Update Schema"
            onClick={() => handleUpdateForm(schema)}
            disabled={!selectedGroup}
          />
        </div>
      </div>

      <SchemaPreview
        schema={schema}
        highlightedProperty={highlightedProperty}
      />

      <PropertyModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={handleAddProperty}
        groupName={selectedGroup}
        layouts={layout || []}
      />
    </div>
  );
};

export default SchemaEditorPage;
