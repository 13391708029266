import React, { useState } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { Empty } from '../Empty';
import { getTdClassName } from './getTdClassName';

export type ColumnType<T> = {
  header: React.ReactNode;
  headerStyle?: React.CSSProperties;

  sortName?: string;
  getValue: (row: T, index: number) => React.ReactNode;

  truncate?: boolean;
  cellStyle?: React.CSSProperties;
  cellClassName?: string;
  cellContentClassName?: string;
  align?: 'left' | 'right';
};

export type ConfigType<T> = {
  columns: ColumnType<T>[];
};

type TableProps<T = any> = {
  data: T[];
  loading?: boolean;
  className?: string;
  clampCells?: boolean;
  withHeader?: boolean;
  config: ConfigType<T>;
  emptyMessage?: string;
  withHoverBorder?: boolean;
  cellsContentClassName?: string;
  verticalAlign?: 'top' | 'middle';

  cellsClassName?: string;
  headersClassName?: string;

  sort?: SortState;
  onSort?: (newSort: string) => void;

  renderExpandComponent?: (row: T, index?: number) => React.ReactNode;
};

const TableHeader = <T,>({
  sort,
  onSort,
  config,
  hasExpand,
  headersClassName,
}: Pick<TableProps<T>, 'config' | 'sort' | 'onSort' | 'headersClassName'> & {
  hasExpand: boolean;
}) => {
  return (
    <thead className="rounded-main text-mainTextText">
      <tr className={headersClassName}>
        {hasExpand && <th />}
        {config.columns.map((column, index) => {
          const { name, direction: dir } = sort ?? {};
          const sortName = column.sortName;
          const showSort = !!sortName && name === sortName && !!dir;
          const IconComponent = dir === 'asc' ? ArrowUpward : ArrowDownward;

          return (
            <th
              key={index}
              style={column.headerStyle}
              className={clsx(
                'text-medium font-bold bg-transparent truncate p-0',
                column.align === 'right' ? 'text-right' : 'text-left'
              )}
            >
              <div
                className={clsx(
                  sortName ? 'cursor-pointer' : '',
                  'flex items-center gap-x-xs w-fit py-1 px-4'
                )}
                onClick={sortName ? () => onSort?.(sortName) : undefined}
              >
                {showSort && <IconComponent />}
                {column.header}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const TableRows = <T,>({
  data,
  config,
  clampCells,
  cellsClassName,
  cellsContentClassName,
  withHoverBorder = true,
  verticalAlign = 'middle',
  renderExpandComponent,
}: Pick<
  TableProps<T>,
  | 'data'
  | 'clampCells'
  | 'config'
  | 'renderExpandComponent'
  | 'cellsClassName'
  | 'cellsContentClassName'
  | 'withHoverBorder'
  | 'verticalAlign'
>) => {
  const [expandedIndexes, setExpandedIndexes] = useState<{
    [x in number]: boolean;
  }>([]);

  return (
    <>
      {data?.map((row, rowIndex) => {
        const isExpanded = expandedIndexes[rowIndex];
        const handleExpandToggle = () => {
          setExpandedIndexes(old => ({
            ...old,
            [rowIndex]: !old[rowIndex],
          }));
        };
        // const className = rowIndex % 2 === 0 ? 'bg-transparent' : 'bg-border';

        return (
          <React.Fragment key={rowIndex}>
            <tr
              className={twMerge('bg-contrast group/tablerow', cellsClassName)}
            >
              {!!renderExpandComponent && (
                <td
                  onClick={handleExpandToggle}
                  className={clsx(
                    getTdClassName({} as any, withHoverBorder, verticalAlign),
                    'pl-10 border-l-2',
                    'cursor-pointer',
                    'max-w-[5rem] min-w-[5rem] w-[5rem]',
                    isExpanded
                      ? 'rounded-tl-card rounded-bl-none pb-2'
                      : 'rounded-l-card'
                  )}
                >
                  {isExpanded ? (
                    <ExpandLess fontSize="large" />
                  ) : (
                    <ExpandMore fontSize="large" />
                  )}
                </td>
              )}
              {config.columns.map((column, index) => {
                const isFirstColumn = index === 0 && !renderExpandComponent;
                const isLastColumn = index === config.columns.length - 1;

                const tdClassName = clsx(
                  getTdClassName(column, withHoverBorder, verticalAlign),
                  isExpanded ? 'pb-2' : '',
                  isLastColumn ? 'pr-10 border-r-2' : '',
                  isFirstColumn ? 'rounded-l-card pl-10 border-l-2' : '',
                  isLastColumn
                    ? isExpanded
                      ? 'rounded-tr-card rounded-br-none'
                      : 'rounded-r-card'
                    : ''
                );

                return (
                  <td
                    key={index}
                    style={column.cellStyle}
                    className={tdClassName}
                  >
                    <div
                      className={clsx(
                        column.truncate
                          ? 'truncate'
                          : clampCells && 'line-clamp-4 break-words',

                        cellsContentClassName,
                        column.cellContentClassName
                      )}
                    >
                      {column.getValue(row, rowIndex)}
                    </div>
                  </td>
                );
              })}
            </tr>
            {!!renderExpandComponent && isExpanded && (
              <tr className="bg-contrast group/tablerow translate-y-[-1.2rem]">
                <td
                  colSpan={config.columns.length + 1}
                  className={clsx(
                    getTdClassName({} as any, withHoverBorder, verticalAlign),
                    'px-10 border-x-2',
                    'rounded-b-card'
                  )}
                >
                  {renderExpandComponent(row, rowIndex)}
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

type TableLoaderProps = {
  rowsCount?: number;
  columnsCount: number;
};

const TableLoader: React.FC<TableLoaderProps> = ({
  rowsCount = 4,
  columnsCount,
}) => {
  const element = (
    <tr>
      <td colSpan={columnsCount} className="px-4">
        <div className="py-8 px-4 bg-gray-200 animate-pulse rounded-card">
          <div className="h-10 bg-gray-300 rounded-lg" />
        </div>
      </td>
    </tr>
  );

  return (
    <>
      {Array.from({ length: rowsCount }).map((_item, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
    </>
  );
};

export const Table = <T,>({
  data = [],
  config,
  className,
  emptyMessage,
  loading = false,
  withHeader = true,
  clampCells = true,
  cellsClassName,
  headersClassName,
  cellsContentClassName,
  verticalAlign = 'middle',

  sort,
  onSort,

  renderExpandComponent,
  withHoverBorder = true,
}: TableProps<T>): React.JSX.Element => {
  if (!data?.length && !!emptyMessage && !loading) {
    return <Empty message={emptyMessage} />;
  }

  return (
    <table
      className={clsx(
        className,
        'w-full border-separate border-spacing-y-[1.2rem]'
      )}
    >
      {!!withHeader && (
        <TableHeader
          sort={sort}
          onSort={onSort}
          config={config}
          hasExpand={!!renderExpandComponent}
          headersClassName={headersClassName}
        />
      )}
      <tbody>
        {loading ? (
          <TableLoader columnsCount={config.columns.length} />
        ) : (
          <TableRows
            data={data}
            config={config}
            clampCells={clampCells}
            verticalAlign={verticalAlign}
            cellsClassName={cellsClassName}
            withHoverBorder={withHoverBorder}
            cellsContentClassName={cellsContentClassName}
            renderExpandComponent={renderExpandComponent}
          />
        )}
      </tbody>
    </table>
  );
};
