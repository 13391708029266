import { useState, useLayoutEffect } from 'react';

export const useNode = (css: string) => {
  const currentNode = document.querySelector(css);
  const [node, setNode] = useState<Element | null>(currentNode);

  useLayoutEffect(() => {
    const node = document.querySelector(css);
    if (currentNode === node) return;

    setNode(node);
  }, [currentNode, css]);

  return [node] as const;
};
