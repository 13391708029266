import React, { useMemo, useState } from 'react';

import { getUserName } from '../../utilities';
import { SubmitButton, Button } from '../../components';
import { Dropdown, Form, FormItem } from '../../components';
import type {
  UserListType,
  UnassignUserFromRolePayload,
} from './useOrganization';

type PartialForm = Partial<UnassignUserFromRolePayload>;

type AssignUserRoleFormProps = {
  saving: boolean;
  user?: UserListType;

  onCancel: () => void;
  onSave: (form: UnassignUserFromRolePayload) => void;

  roles: OptionType[];
};

export const UnassignUserRoleForm: React.FC<AssignUserRoleFormProps> = ({
  user,
  roles,
  saving,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialForm>({});
  const theseRoles = useMemo(() => {
    const rolesIds = new Set(
      user?.organizationRoles?.flatMap(ur => ur.roles?.map(r => r.id))
    );

    return roles.filter(r => rolesIds.has(r.value));
  }, [roles, user]);

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <div className="text-medium">
        Select role you want to remove from user:
      </div>
      <div className="text-medium font-medium">{getUserName(user)}</div>
      <Form onChange={setValues} initialValue={values}>
        <FormItem<PartialForm>
          required
          name="roleId"
          inline={false}
          label="User Role"
        >
          <Dropdown options={theseRoles} placeholder="Select role" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            label="Remove"
            disabled={saving}
            className="w-full"
            onClick={() => onSave(values as UnassignUserFromRolePayload)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
