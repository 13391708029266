import { useEffect } from 'react';

import { useRefValue } from './useRefValue';

export const useInterval = (
  func: () => void,
  interval: number = 1000,
  enabled: boolean = true
) => {
  const funcRef = useRefValue(func);

  useEffect(() => {
    if (!interval || !enabled) {
      return;
    }

    const id = setInterval(() => funcRef.current?.(), interval);

    return () => clearInterval(id);
  }, [interval, enabled, funcRef]);
};
