import React, { useEffect } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { useForm } from '.';
import type { FormErrorType } from './DefaultError';

type FormItemProps<T, K> = (K extends StringKeyOf<T>
  ? {
      name: K;
      validate?: (value: T[K], values: T) => FormErrorType;
    }
  : never) & {
  tooltip?: string;
  inline?: boolean;
  required?: boolean;
  className?: string;
  label: React.ReactNode;
  labelClassName?: string;
  labelContainerClassName?: string;
  children: React.ReactElement<{ onChange?: Function; error?: any }>;
  showAsError?: boolean;
  renderError?: null | ((error: FormErrorType) => React.ReactNode);
  errorBelowInput?: boolean;
};

export const FormItem = <
  T extends {} = any,
  K extends keyof T & string = keyof T & string,
>({
  children,
  name,
  label,
  className,
  labelClassName,
  tooltip,
  required,
  validate,
  inline = true,
  showAsError = false,
  labelContainerClassName,
  renderError: ourRenderError,
  errorBelowInput = false,
  ...rest
}: FormItemProps<T, K>): React.JSX.Element => {
  const {
    getValue,
    getErrors,
    onChange,
    register,
    unregister,
    renderError: defaultRenderError,
  } = useForm<T>();

  const value = getValue?.()[name];
  const error = getErrors?.()[name];

  const renderError = ourRenderError ?? defaultRenderError;
  const renderedError = !!renderError && !!error && renderError(error);

  const handleChange = (newValue: any | undefined) => {
    onChange?.({ ...getValue?.(), [name]: newValue } as T);
    children?.props?.onChange?.(newValue);
  };

  useEffect(() => {
    register?.(name, { required, validate });
    return () => {
      unregister?.(name);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, required]);

  const isErrored = !!renderedError || !!showAsError;

  return (
    <div
      className={clsx(
        className,
        'flex',
        inline ? 'flex-row gap-x-4 items-start' : 'flex-col gap-y-2 w-full'
      )}
    >
      {!!label && (
        <div
          className={clsx(
            twMerge(
              'text-left inline-flex items-center',
              inline
                ? twMerge(
                    'font-normal text-secondary min-h-[4rem]',
                    labelContainerClassName || 'min-w-[20rem] max-w-[20rem]'
                  )
                : twMerge('font-medium text-mainText', labelContainerClassName),
              labelClassName
            ),
            inline ? 'text-small' : 'text-medium'
          )}
        >
          {label}
          {!!required && '*'}
        </div>
      )}
      <div
        className={clsx(
          inline ? 'grow' : 'w-full',
          errorBelowInput && 'flex flex-col gap-y-2'
        )}
      >
        <div
          className={clsx(
            inline ? 'flex items-center justify-start min-h-[4rem]' : 'w-full',
            isErrored && 'outline outline-danger outline-1 rounded-bt'
          )}
        >
          {React.cloneElement(children, {
            ...rest,
            value,
            error,
            onChange: handleChange,
          })}
        </div>
        {!!renderedError && (
          <div
            className={clsx(
              'text-danger text-left text-small italic mt-1',
              'w-full break-words',
              inline && !errorBelowInput && 'min-h-[4rem]'
            )}
          >
            {renderedError}
          </div>
        )}
      </div>
    </div>
  );
};
