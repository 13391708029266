/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserOrganizationsResponse } from '../models/UserOrganizationsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserApiService {
    /**
     * Get all organization user affiliated
     * @returns UserOrganizationsResponse Successful response
     * @throws ApiError
     */
    public static getAffiliatedOrganizations(): CancelablePromise<UserOrganizationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/organizations',
        });
    }
}
