import React, { useState } from 'react';

import {
  Button,
  Copy,
  DynamicTopPanel,
  Input,
  JsonFormBuilder,
  Loading,
} from '..';
import type { FormJsonData, AgentWidgetSettings } from '../../api';

type FormBuilderModalProps = {
  onChange: (data: any) => void;
  widgetSettings: AgentWidgetSettings | null;
  schemaData: FormJsonData;
  intializing: boolean;
  onSave?: () => void;
  showSaveButton?: boolean;
};

export const WidgetSettingsBuilder: React.FC<FormBuilderModalProps> = ({
  widgetSettings,
  onChange,
  schemaData,
  intializing,
  onSave,
  showSaveButton = false,
}) => {
  const [isError, setIsError] = useState(false);

  const handleChanges = ({ data, errors }: { data: any; errors: any }) => {
    onChange(data);
    setIsError(!!errors.length);
  };

  return (
    <>
      {intializing ? (
        <Loading />
      ) : (
        <div>
          <div className="px-4">
            <div className="flex justify-end gap-x-md mb-2">
              <div className="text-small truncate">Copy Widget Settings</div>
              <Copy
                className="shrink-0"
                text={JSON.stringify(widgetSettings) ?? ''}
              />
            </div>
            <Input
              value={JSON.stringify(widgetSettings) || ''}
              multiline
              readonly
            />
          </div>
          <JsonFormBuilder
            onChange={handleChanges}
            formData={widgetSettings || {}}
            schemaData={schemaData}
          />
        </div>
      )}
      {showSaveButton && (
        <DynamicTopPanel>
          <div className="flex flex-row gap-x-md justify-center items-center">
            <Button
              label="Cancel"
              url="/agents"
              type="contrast"
              size="medium"
            />
            <Button
              label={'Save Widget Settings'}
              size="medium"
              type="primary"
              disabled={intializing || isError}
              onClick={onSave}
            />
          </div>
        </DynamicTopPanel>
      )}
    </>
  );
};
