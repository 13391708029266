import { useState, useCallback } from 'react';

type UseSortReturn<T = string> = {
  sort: SortState<T>;
  setSort: (name: T) => void;
};

const getNextDirection = (currentDirection: SortDirection): SortDirection => {
  if (currentDirection === 'asc') return 'desc';

  return currentDirection === 'desc' ? undefined : 'asc';
};

export const useSort = <T = string>(
  defaultValue?: SortState<T>
): UseSortReturn<T> => {
  const [sort, setSortState] = useState<SortState<T>>(
    defaultValue ?? { name: undefined, direction: undefined }
  );

  const setSort = useCallback((name: T) => {
    setSortState(prevSort => {
      if (prevSort.name !== name) {
        return { name, direction: 'asc' };
      }

      const nextDirection = getNextDirection(prevSort.direction);
      return {
        name: nextDirection ? name : undefined,
        direction: nextDirection,
      };
    });
  }, []);

  return { sort, setSort };
};
