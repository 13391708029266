import { getCurrentOrg } from '.';
import { AgentApiService } from '../services';
import { ModelInventoryApiService } from '../services';
import type {
  AgentDto,
  AgentWidgetSettingsRequest,
  AgentWidgetSettingsResponse,
  BaseAgentRequest,
  JsonNode,
} from '../services';

export type NodeJSON = JsonNode;
export type AgentResponse = AgentDto;
export type AgentShortResponse = AgentDto;
export type PipelineLanguage = AgentDto.language;
export type AgentCreateRequest = BaseAgentRequest;
export type AgentUpdateRequest = BaseAgentRequest;
export type AgentWidgetSettings = AgentWidgetSettingsResponse;
export type AgentWidgetSettingsRequestBody = AgentWidgetSettingsRequest;

export type AiModelData = {
  value: string;
  label: string;
  jsonSchema?: NodeJSON;
  modelName?: string;
  providerName?: string;
  type?: string;
  settings?: NodeJSON;
  uiSchema?: NodeJSON;
};

export const getAgents = (): Promise<Array<AgentResponse>> => {
  return AgentApiService.getAgents(getCurrentOrg());
};
export const getShortAgents = (): Promise<Array<AgentShortResponse>> => {
  return AgentApiService.getShortAgents(getCurrentOrg());
};
export const getAgent = (id: string): Promise<AgentResponse> => {
  return AgentApiService.getAgentById(getCurrentOrg(), id);
};

export const createAgent = (
  agent: AgentCreateRequest
): Promise<AgentResponse> => {
  return AgentApiService.createAgent(getCurrentOrg(), agent);
};
export const editAgent = (
  agentId: string,
  agent: AgentUpdateRequest
): Promise<AgentResponse> => {
  return AgentApiService.updateAgent(agentId, getCurrentOrg(), agent);
};
export const editAgentFlow = async (
  agentId: string,
  conversation_flow: string
): Promise<void> => {
  await AgentApiService.updateConversationFlow(
    getCurrentOrg(),
    agentId,
    JSON.parse(conversation_flow)
  );

  return;
};
export const assignAgentToOrganization = async (
  agentId: string,
  organizationId: string
) => {
  return AgentApiService.changeOrganization(agentId, organizationId);
};

const formatLabel = (...labels: (string | undefined)[]) => {
  return labels.filter(l => l?.length).join(': ');
};

export const getTTSModels = async (): Promise<AiModelData[]> => {
  return (await ModelInventoryApiService.getAvailableModels('TTS'))
    .map(d => ({
      value: d.id ?? '',
      label: formatLabel(d.providerName, d.modelName),
      jsonSchema: d.settingsValidationSchema,
      ...d,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
export const getLLMModels = async (): Promise<AiModelData[]> => {
  return (await ModelInventoryApiService.getAvailableModels('LLM'))
    .map(d => ({
      value: d.id ?? '',
      label: formatLabel(d.providerName, d.modelName),
      jsonSchema: d.settingsValidationSchema,
      ...d,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
export const getSTTModels = async (): Promise<AiModelData[]> => {
  return (await ModelInventoryApiService.getAvailableModels('STT'))
    .map(d => ({
      value: d.id ?? '',
      label: formatLabel(d.providerName, d.modelName),
      jsonSchema: d.settingsValidationSchema,
      ...d,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getAgentWidgetSettings = async (
  agentId: string
): Promise<AgentWidgetSettings> => {
  return await AgentApiService.updateWidgetSettings(agentId, getCurrentOrg());
};

export const updateAgentWidgetSettings = async (
  agentId: string,
  data: AgentWidgetSettingsRequestBody
): Promise<AgentWidgetSettings> => {
  return await AgentApiService.updateWidgetSettings1(
    agentId,
    getCurrentOrg(),
    data
  );
};
