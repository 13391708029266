import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { JsonFormBuilder, Loading } from '..';
import { useForm } from '../Form';
import type { FormJsonData } from '../../api';

type FormBuilderModalProps = {
  name: string;
  schemaData: FormJsonData;
  intializing?: boolean;
};

const SettingsBuilder: React.FC<FormBuilderModalProps> = ({
  name,
  schemaData,
  intializing,
}) => {
  const { getValue, onChange, setError, register, unregister, getErrors } =
    useForm<any>();
  const value = getValue?.()?.[name];
  const error = getErrors?.()?.[name];

  useEffect(() => {
    register?.(name);
    return () => unregister?.(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleChanges = useCallback(
    ({ data, errors }: { data: any; errors: any }) => {
      onChange?.({
        ...getValue?.(),
        [name]: data,
      });

      if (setError) {
        if (errors?.length) {
          const messages = errors.map((e: any) => e.message).join(', ');
          setError(name, messages);
        } else {
          setError(name, undefined);
        }
      }
    },
    [getValue, name, onChange, setError]
  );

  return intializing ? (
    <Loading />
  ) : (
    <div>
      <JsonFormBuilder
        onChange={handleChanges}
        formData={value || {}}
        schemaData={schemaData}
      />
      <div>
        <p className="text-red-500 text-medium">{error}</p>
      </div>
    </div>
  );
};
export default memo(SettingsBuilder);
