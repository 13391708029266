import React, { useMemo, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import clsx from 'clsx';

import { Input } from '../Input';
import { usePopupPosition } from '../../hooks';

type ArrowProps = {
  className?: string;
  direction?: 'up' | 'down';
};

const Arrow: React.FC<ArrowProps> = ({ className, direction = 'down' }) => {
  return (
    <svg
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        'transition-all',
        className,
        direction === 'down' ? '' : 'rotate-180'
      )}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5L7.72732 10.7273L13.4546 5H12.0404L7.72732 9.3131L3.41421 5H2Z"
        fill="#444444"
      />
    </svg>
  );
};

export type DropdownProps<T = string> = {
  value?: T;
  className?: string;
  placeholder?: string;
  withArrow?: boolean;
  arrowInside?: boolean;
  emptyMessage?: string;
  closeOnSelect?: boolean;
  dock?: 'left' | 'right';

  disabled?: boolean;

  popupClassName?: string;
  optionClassName?: string;
  wrapperClassName?: string;
  inputRoundedClassName?: string;

  renderValue?: (params: {
    value?: T;
    placeholder?: string;
    label?: React.ReactNode;
    inputRoundedClassName?: string;
  }) => React.ReactNode;
  options: OptionType<T>[];
  onChange?: (newValue: T, option?: OptionType<T>) => void;
};

const defaultRenderValue = <T,>({
  label,
  placeholder,
  inputRoundedClassName,
}: {
  value?: T;
  placeholder?: string;
  label?: React.ReactNode;
  inputRoundedClassName?: string;
}) => (
  <Input
    readonly
    withBorder
    className="w-full"
    value={label as any}
    placeholder={placeholder}
    inputClassName="pr-xl cursor-pointer"
    inputRoundedClassName={inputRoundedClassName}
  />
);

export const Dropdown = <T,>({
  value,
  options: initialOptions,
  className,
  placeholder,
  withArrow = true,
  arrowInside = true,
  closeOnSelect = true,
  emptyMessage = 'No options',

  optionClassName,
  wrapperClassName,
  inputRoundedClassName = 'rounded-bt',
  popupClassName = 'max-h-[25rem] overflow-y-auto rounded-bt',

  onChange,
  renderValue = defaultRenderValue,
  disabled = false,
}: DropdownProps<T>): React.JSX.Element => {
  const [open, setOpen] = useState(false);
  const refAnchorRef = useRef<any>(null);
  const {
    popupRef,
    anchorRef,

    popupPosition,
    anchorPosition,
  } = usePopupPosition();

  const options = useMemo(() => {
    if (!value) return initialOptions;
    if (initialOptions.some(o => o.value === value)) {
      return initialOptions;
    }

    return [...initialOptions, { value, label: String(value) }];
  }, [value, initialOptions]);

  const handleClose = () => setOpen(false);
  const handleToggle = () => {
    if (!disabled) setOpen(o => !o);
  };

  const handleSelect = (newValue: T) => {
    if (!disabled) {
      onChange?.(
        newValue,
        options?.find(o => o.value === newValue)
      );
      !!closeOnSelect && handleClose();
    }
  };

  useOnClickOutside(refAnchorRef, handleClose);

  const label = options?.find(o => o.value === value)?.label ?? '';

  return (
    <div
      ref={node => {
        node && anchorRef(node);
        refAnchorRef.current = node;
      }}
      onClick={handleToggle}
      className={clsx(
        'relative text-medium grow',
        className,
        disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
      )}
    >
      <div
        className={clsx(
          'flex items-center justify-between gap-x-3 w-full',
          wrapperClassName
        )}
      >
        {renderValue({ value, label, placeholder, inputRoundedClassName })}
        {!arrowInside && !!withArrow && (
          <Arrow direction={open ? 'up' : 'down'} />
        )}
      </div>
      {!!arrowInside && !!withArrow && (
        <div className="absolute right-4 top-0 h-full inline-flex items-center">
          <Arrow direction={open ? 'up' : 'down'} />
        </div>
      )}
      {!disabled && (
        <div
          ref={popupRef}
          style={{
            left: popupPosition?.style?.left ?? 0,
            top: popupPosition?.style?.top ?? 0,
            minWidth: anchorPosition.width ?? 0,
          }}
          className={clsx(
            'fixed z-popper w-fit max-w-[40rem] py-sm',

            open ? 'visible' : 'invisible'
          )}
        >
          <div
            className={clsx(
              popupClassName,
              // inputRoundedClassName,
              'shadow-sm border bg-contrast p-2',
              'transition-non-position',
              open ? 'opacity-100' : 'opacity-0'
            )}
          >
            {options.map((o, index) => (
              <div
                key={String(o.value)}
                onClick={e => {
                  e.stopPropagation();
                  handleSelect(o.value);
                }}
                className={clsx(
                  'rounded-md hover:bg-dark px-4 py-1 transition-all duration-150',
                  optionClassName,
                  index !== options.length - 1 ? 'mb-2' : '',
                  o.value === value
                    ? 'text-mainTextTextAccent'
                    : 'text-infoText',
                  disabled ? 'pointer-events-none' : 'cursor-pointer'
                )}
              >
                {o.label}
              </div>
            ))}
            {!options.length && !!emptyMessage && (
              <div className="px-4 py-1 pointer-events-none cursor-default">
                {emptyMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
