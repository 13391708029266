import { useState, useMemo } from 'react';

import { notify } from '../../app';
import { unassignUserFromOrg } from '../../api';
import { unassignUserFromRole } from '../../api';
import { getRoles, assignUserToRole } from '../../api';
import { getOrganization, getOrgUsers } from '../../api';
import { getAllUsers, addUserToOrganization } from '../../api';
import { useFetch, useListFetch, useModal, useRequest } from '../../hooks';
import type { UserListType, AssignUserToRolePayload } from '../../api';

export const useOrganization = (id: string) => {
  const { isOpened, onOpen, onClose } = useModal();

  const {
    data: organization,
    refresh: refreshOrganization,
    intializing: loadingOrganization,
  } = useFetch(() => getOrganization(id), [id]);

  const { nodes: users, refresh: refreshUsers } = useListFetch(
    params =>
      getOrgUsers(
        id,
        params?.pagination?.page,
        params?.pagination?.perPage,
        params?.sort
      ),
    { deps: [id] }
  );
  const { nodes: allUsers, loading: loadingAllUsers } = useListFetch(
    () => getAllUsers(0, 100),
    { deps: [] }
  );
  const [handleAddUser, adding] = useRequest(
    (_signal, userId: string) => addUserToOrganization(id, userId),
    {
      deps: [id],

      onSuccess: () => {
        notify.success('User assigned');
        refreshUsers?.();
        refreshOrganization?.();

        onClose();
      },
      onError: (error: any) => {
        notify.error(
          `Failed to assign user: ${error?.body?.detail ?? 'No details'}`
        );
      },
    }
  );

  const {
    isOpened: isOpenedRoleModal,
    onOpen: onOpenRoleModal,
    onClose: onCloseRoleModal,
  } = useModal();
  const [formForAction, setFormForAction] = useState<UserListType | undefined>(
    undefined
  );
  const handleOpenRoleModal = (row: UserListType) => {
    setFormForAction(row);
    onOpenRoleModal();
  };
  const [handleAssignUserRole, assigning] = useRequest(
    (_signal, values: AssignUserToRolePayload) =>
      assignUserToRole({ ...values, organizationId: id }),
    {
      onSuccess: () => {
        refreshUsers();
        onCloseRoleModal?.();
        notify.success('User role assigned');
      },
      onError: (error: any) => {
        notify.error(
          `Failed to assign user role: ${error?.body?.detail ?? 'No details'}`
        );
      },
      deps: [id],
    }
  );
  const userOptions: OptionType[] = useMemo(() => {
    return (allUsers || [])
      .map(
        (user): OptionType => ({
          value: user.id ?? '',
          label: user.username ?? user.id ?? 'No Name',
        })
      )
      .filter(u => !!u.value);
  }, [allUsers]);

  const { data: roles, intializing: loadingRoles } = useFetch(
    () => getRoles(),
    []
  );
  const rolesOptions: OptionType[] = (roles || [])
    .map(o => ({
      value: o.id ?? '',
      label: o.name ?? '',
    }))
    .filter(o => o.value && o.label);

  const {
    isOpened: isOpenedUnassignModal,
    onOpen: onOpenUnassignModal,
    onClose: onCloseUnassignModal,
  } = useModal();
  const handleOpenUnassignModal = (row: UserListType) => {
    setFormForAction(row);
    onOpenUnassignModal();
  };
  const handleCloseUnassignModal = () => {
    setFormForAction(undefined);
    onCloseUnassignModal();
  };
  const [handleUnassignUser, unassigning] = useRequest(
    _signal => {
      if (!formForAction?.id) return;

      return unassignUserFromOrg({
        userId: formForAction?.id,
        organizationId: id,
      });
    },
    {
      onSuccess: () => {
        refreshUsers();
        handleCloseUnassignModal?.();
        notify.success('User removed from organization');
      },
      onError: (error: any) => {
        notify.error(
          `Failed to remove user from organization: ${error?.body?.detail ?? 'No details'}`
        );
      },
      deps: [id, formForAction],
    }
  );

  const {
    isOpened: isOpenedUnassignRoleModal,
    onOpen: onOpenUnassignRoleModal,
    onClose: onCloseUnassignRoleModal,
  } = useModal();
  const handleOpenUnassignRoleModal = (row: UserListType) => {
    setFormForAction(row);
    onOpenUnassignRoleModal();
  };
  const handleCloseUnassignRoleModal = () => {
    setFormForAction(undefined);
    onCloseUnassignRoleModal();
  };
  const [handleUnassignRoleUser, unassigningRole] = useRequest(
    (_signal, roleId: string) => {
      if (!roleId || !formForAction?.id) return;

      return unassignUserFromRole({
        roleId,
        organizationId: id,
        userId: formForAction?.id,
      });
    },
    {
      onSuccess: () => {
        refreshUsers();
        handleCloseUnassignRoleModal?.();
        notify.success('Role removed from user');
      },
      onError: (error: any) => {
        notify.error(
          `Failed to remove role from user: ${error?.body?.detail ?? 'No details'}`
        );
      },
      deps: [id, formForAction],
    }
  );

  return {
    userOptions,
    organization,
    users: users || [],
    loading: loadingOrganization || loadingAllUsers || loadingRoles,

    refreshOrganization,

    isOpened,
    onOpen,
    onClose,
    handleAddUser,

    rolesOptions,
    formForAction,
    isOpenedRoleModal,
    onCloseRoleModal,
    handleAssignUserRole,
    onOpenAddRoleModal: handleOpenRoleModal,

    isOpenedUnassignModal,
    handleUnassignUser,
    onOpenUnassignModal: handleOpenUnassignModal,
    onCloseUnassignModal: handleCloseUnassignModal,

    isOpenedUnassignRoleModal,
    onOpenUnassignRoleModal: handleOpenUnassignRoleModal,
    onCloseUnassignRoleModal: handleCloseUnassignRoleModal,
    handleUnassignRoleUser,

    updating: adding || assigning || unassigning || unassigningRole,
  };
};

export type { AssignUserToRolePayload };
export type { UnassignUserFromRolePayload } from '../../api';
export type { OrganizationType, UserListType } from '../../api';
