import { useEffect, useMemo, useState } from 'react';

import { notify } from '../../app';
import { OpenAPI } from '../../api';
import { getUserOrgs } from '../../api';
import { useUserSettings } from './useUserSettings';
import type { UserOrganizationDto } from '../../api';

const extractPemissions = (
  organizations: UserOrganizationDto[],
  selectedOrg?: string
): SpPermission[] => {
  if (!selectedOrg) return [];

  const organization = organizations?.find(o => o.id === selectedOrg);
  if (!organization) return [];

  return Array.from(
    new Set(organization.roles?.flatMap?.(r => r.permissions ?? []) ?? [])
  ) as SpPermission[];
};

export const useSetPermissions = (
  token: string,
  loading: boolean,
  onRelogin: () => void
) => {
  const [inited, setInited] = useState(false);

  const [orgs, setOrgs] = useState<UserOrganizationDto[]>([]);
  const [selectedOrg, handleSetSelectedOrg] = useUserSettings<
    string | undefined
  >('', 'sp-admin-selected-org');

  const setSelectedOrg = (newOrg?: string) => {
    handleSetSelectedOrg(newOrg);
    // setTimeout(() => window.location.replace('/'), 0);
  };

  useEffect(() => {
    OpenAPI.HEADERS = {
      'x-ai-agent-organization-context': selectedOrg || '',
    };
  }, [selectedOrg]);

  useEffect(
    () => {
      if (loading) return;
      if (!token) return setInited(true);

      let ignore = false;

      const processUserInfo = async () => {
        try {
          const response = await getUserOrgs();
          const organizations = response?.organizations ?? [];

          if (ignore) return;

          setOrgs(organizations);

          if (!organizations.some(o => o.id === selectedOrg)) {
            handleSetSelectedOrg(organizations[0]?.id ?? '');
          }

          setInited(true);
        } catch {
          if (ignore) return;

          setOrgs([]);
          setInited(false);
          onRelogin?.();
          notify.error('Failed to get user info');
        }
      };

      processUserInfo();

      return () => {
        ignore = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, loading]
  );

  const permissions = useMemo(
    () => extractPemissions(orgs, selectedOrg),
    [orgs, selectedOrg]
  );

  return {
    inited,
    orgs,
    setSelectedOrg,
    permissions: permissions,
    organization_id: selectedOrg,
  };
};
