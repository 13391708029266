export const getDurationFromMs = (diffInMs?: number) => {
  if (!diffInMs) return '';

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let formattedDuration = '';
  if (days > 0) {
    formattedDuration += `${days} day${days > 1 ? 's' : ''} `;
  }
  if (hours % 24 > 0) {
    formattedDuration += `${hours % 24} hour${hours % 24 > 1 ? 's' : ''} `;
  }
  if (minutes % 60 > 0) {
    formattedDuration += `${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''} `;
  }
  if (seconds % 60 > 0) {
    formattedDuration += `${seconds % 60} second${seconds % 60 > 1 ? 's' : ''}`;
  }

  return formattedDuration.trim();
};

export const getDurationFromSeconds = (diffInSeconds?: number) => {
  if (!diffInSeconds) return '';

  return getDurationFromMs(diffInSeconds * 1000);
};

export const calcDuration = (
  from?: string | null,
  to?: string | null
): string => {
  if (!from || !to) return '';

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const diffInMs = toDate.getTime() - fromDate.getTime();

  if (diffInMs < 0) return '';

  return getDurationFromMs(diffInMs);
};
