import React, { useState, useCallback, useRef } from 'react';

import { Button } from '..';
import { Input } from '../Input';
import { Modal } from '../Modal';

function escapeRegExp(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export type EditorModalProps = {
  initialContent: string;
  onClose: () => void;
  onSave: (content: string) => void;
};

export const EditorModal: React.FC<EditorModalProps> = ({
  initialContent,
  onClose,
  onSave,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [findText, setFindText] = useState<string>('');
  const [replaceText, setReplaceText] = useState<string>('');

  const handleHighlight = useCallback(() => {
    if (!findText.trim() || !editorRef.current) return;

    const textContent = editorRef.current.innerText;
    const regex = new RegExp(escapeRegExp(findText), 'gi');

    const highlightedText = textContent.replace(
      regex,
      match =>
        `<mark style="background-color: yellow; color: black;">${match}</mark>`
    );

    editorRef.current.innerHTML = highlightedText;
  }, [findText]);

  const handleReplace = useCallback(() => {
    if (!findText.trim() || !editorRef.current) return;

    const textContent = editorRef.current.innerText;
    const regex = new RegExp(escapeRegExp(findText), 'gi');

    const newText = textContent.replace(regex, replaceText);
    editorRef.current.innerText = newText;
  }, [findText, replaceText]);

  const handleSave = useCallback(() => {
    if (editorRef.current) {
      onSave(editorRef.current.innerText);
    }
    onClose();
  }, [onSave, onClose]);

  return (
    <Modal title="Edit Content" size="big" open onClose={onClose}>
      <div className="flex flex-col gap-sm h-full">
        <div className="flex gap-2">
          <Input
            value={findText}
            placeholder="Find..."
            onChange={value => setFindText(value)}
          />
          <Input
            value={replaceText}
            placeholder="Replace with..."
            onChange={value => setReplaceText(value)}
          />
        </div>

        <div className="flex justify-end gap-sm">
          <Button
            type="empty"
            label="Search"
            onClick={handleHighlight}
            className="px-lg py-sm bg-yellow-500 text-white rounded"
          />
          <Button
            type="empty"
            label="Replace"
            onClick={handleReplace}
            className="px-lg py-sm bg-green-500 text-white rounded"
          />
        </div>

        <div
          ref={editorRef}
          contentEditable
          suppressContentEditableWarning
          className="flex-grow border border-gray-300 rounded overflow-auto p-3 text-medium bg-white text-black whitespace-pre-wrap"
          style={{ minHeight: '200px', maxHeight: '60vh' }}
          dangerouslySetInnerHTML={{ __html: initialContent }}
        />

        <div className="flex justify-end gap-sm">
          <Button
            type="contrast"
            label="Cancel"
            onClick={onClose}
            className="px-lg py-sm rounded"
          />
          <Button
            type="empty"
            label="Save"
            onClick={handleSave}
            className="px-lg py-sm bg-buttonPrimary text-white rounded"
          />
        </div>
      </div>
    </Modal>
  );
};
