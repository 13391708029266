import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { useKeyPressed } from '../../hooks';
import { roundTo as roundToFunc } from '../../utilities';

export type InputProps = {
  label?: React.ReactNode;
  className?: string;
  numeric?: boolean;
  roundTo?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  readonly?: boolean;
  autoFocus?: boolean;
  withBorder?: boolean;
  inputClassName?: string;
  inputRoundedClassName?: string;
  borderClassName?: string;
  defaultSizeClass?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  type?: any;

  value?: string | number;
  multiline?: boolean;
  adjustableHeight?: boolean;
  size?: 'small' | 'medium' | 'large';
  onBlur?: () => void;
  onFocus?: () => void;
  onSubmit?: (newValue: string) => void;
  onChange?: (newValue: string) => void;
};

const useFocusAndSubmit = ({
  value,
  autoFocus,
  disabled,
  onBlur,
  onFocus,
  onSubmit,
}: Pick<
  InputProps,
  'autoFocus' | 'value' | 'disabled' | 'onBlur' | 'onFocus' | 'onSubmit'
>) => {
  const ref = useRef<any>(null);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (!autoFocus) return;

    ref.current?.focus();
  }, [autoFocus]);

  useKeyPressed(
    'Enter',
    () => {
      onSubmit?.(String(value ?? ''));
    },
    !!onSubmit && !disabled && !!focus
  );

  return {
    ref,
    handleBlur: () => {
      setFocus(false);
      onBlur?.();
    },
    handleFocus: () => {
      setFocus(true);
      onFocus?.();
    },
  };
};

export const Input: React.FC<InputProps> = ({
  label,
  prefix,
  suffix,
  className,
  inputClassName,
  inputRoundedClassName = 'rounded-bt',
  onBlur,
  onFocus,
  onChange,
  onSubmit,
  min,
  max,
  type,
  value = '',
  placeholder,
  roundTo = 2,
  numeric = false,
  disabled = false,
  readonly = false,
  autoFocus = false,
  withBorder = true,
  multiline = false,
  adjustableHeight = false,
  size = 'medium',
  borderClassName = 'shadow-sm border border-solid border-border',
  defaultSizeClass = 'min-w-[14rem] md:min-w-[20rem] grow',
}) => {
  const { ref, handleBlur, handleFocus } = useFocusAndSubmit({
    value,
    autoFocus,
    disabled,
    onBlur,
    onFocus,
    onSubmit,
  });

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (multiline && adjustableHeight && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value, multiline, adjustableHeight]);

  const getRows = () => {
    if (!size) return 5;
    if (adjustableHeight) return 1;
    return size === 'small' ? 3 : size === 'large' ? 15 : 5;
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  const handleWheel = (
    e: React.UIEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.stopPropagation();
    e.currentTarget.blur();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    if (!numeric || roundTo === undefined) return onChange?.(value);

    const roundedNumber = String(roundToFunc(value, roundTo));
    return onChange?.(roundedNumber);
  };

  const Component = multiline ? 'textarea' : 'input';

  return (
    <div
      className={clsx(
        'flex flex-col items-start relative h-fit',
        className,
        defaultSizeClass
      )}
    >
      {!!label && (
        <div className="text-mainText text-small font-normal">{label}</div>
      )}
      {!!prefix && (
        <div className="absolute left-4 h-full top-0 inline-flex items-center justify-center">
          {prefix}
        </div>
      )}
      {!!suffix && (
        <div className="absolute right-4 h-full top-0 inline-flex items-center justify-center">
          {suffix}
        </div>
      )}

      <Component
        ref={multiline ? textAreaRef : ref}
        min={min}
        max={max}
        value={value ?? ''}
        readOnly={readonly}
        onBlur={e => {
          handleBlur?.();
          if (!numeric || disabled) return;

          const newValue = e.target.value.replace(/[^0-9]$/, '');
          onChange?.(newValue);
          ref.current.value = newValue;
        }}
        onFocus={handleFocus}
        placeholder={placeholder}
        type={numeric ? 'number' : type}
        onWheel={numeric ? handleWheel : undefined}
        onScroll={numeric ? handleWheel : undefined}
        onChange={disabled ? () => {} : handleChange}
        onKeyDown={numeric ? handleKeyDown : undefined}
        className={clsx(
          disabled ? 'opacity-50 pointer-events-none' : '',
          multiline ? 'overflow-y-auto' : 'overflow-hidden',
          'py-[0.7rem] px-6 bg-contrast outline-none w-full text-mainText text-medium appearance-none',
          inputClassName,
          inputRoundedClassName,
          withBorder ? borderClassName : ''
        )}
        {...(multiline ? { rows: getRows() } : {})}
      />
    </div>
  );
};
