import React, { useState } from 'react';

import { Dropdown } from '../../components';
import { Form, FormItem, SubmitButton, Button } from '../../components';

type ValueType = { userId?: string };
type AssignUserFormProps = {
  saving: boolean;
  users: OptionType[];

  onCancel: () => void;
  onSave: (userId: string) => void;
};

export const AssignUserForm: React.FC<AssignUserFormProps> = ({
  saving,
  users,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<{ userId?: string }>({});

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<ValueType>
          required
          name="userId"
          label="User Name"
          inline={false}
        >
          <Dropdown options={users} placeholder="Select User" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            label="Save"
            disabled={saving}
            className="w-full"
            onClick={() => onSave(values?.userId!)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
