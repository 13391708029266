import { useListFetch, useFetch } from '../../hooks';
import {
  getConversationCountries,
  getConversations,
  getShortAgents,
} from '../../api';
import type { ConversationResponse } from '../../api';
import { countries } from './countries';

const masterAgentId =
  process.env.REACT_APP_MASTER_AGENT_ID ||
  '85c6b483-2ab0-4e36-8207-e0a3c6ac1aad';

const processStringValue = (string: string): string | undefined => {
  if (typeof string !== 'string') return string;

  return string?.length ? string : undefined;
};

export const useShortAgents = (): OptionType[] => {
  const { data } = useFetch(() => getShortAgents(), []);

  return (data ?? []).map(d => ({ value: d.title ?? '', label: d.title }));
};

export const useConversationCountries = (): OptionType[] => {
  const { data } = useFetch(() => getConversationCountries(), []);

  return (data ?? [])
    .filter((code): code is string => Boolean(code))
    .map(code => countries.find(opt => opt.value === code))
    .filter((country): country is OptionType => Boolean(country));
};

export const useConversations = () => {
  const agentsOptions = useShortAgents();

  const {
    nodes,
    filters,
    setFilters,
    sort,
    setSort,
    intializing: loading,
    ...rest
  } = useListFetch<ConversationResponse>(
    params =>
      getConversations(
        processStringValue(params?.filters?.bot_master_id) || masterAgentId,

        params?.pagination?.page ?? 0,
        params?.pagination?.perPage ?? 1,
        {
          userId: processStringValue(params?.filters?.user_id),
          endedAt: processStringValue(params?.filters?.ended_at),
          agentId: processStringValue(params?.filters?.agent_id),
          userName: processStringValue(params?.filters?.user_name),
          startedAt: processStringValue(params?.filters?.started_at),
          agentTitle: processStringValue(params?.filters?.agent_title),
          conversationId: processStringValue(params?.filters?.conversation_id),
          country: processStringValue(params?.filters?.country),
          completedConversations:
            params?.filters?.completed_conversations !== ''
              ? params?.filters?.completed_conversations
              : undefined,
        },
        params?.sort
      ),
    {
      deps: [masterAgentId],
      withQueryParams: true,
      resultKey: 'conversations',
      initialFilters: { completed_conversations: true },
      initialSort: { name: 'startedAt', direction: 'desc' },
    }
  );

  return {
    ...rest,

    sort,
    setSort,

    filters,
    setFilters,

    loading,
    conversations: nodes ?? [],

    agentsOptions,
  };
};
