import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Layout from './Layout';
import { useAuth } from '../../hooks';
import { Page404 } from '../../pages';
import { useRoutes } from './getRoutes';
import { Loading } from '../../components';
import { Route, PermissionCheck } from '../../components';
import type { MenuItem } from './Layout';

const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));

const mapItemsAsRoutes = (menuItems: MenuItem[]) => {
  return menuItems.map(route => {
    if (!route.component) return null;

    return (
      <Route
        exact
        key={route.url}
        path={route.url}
        action={route.action}
        component={route.component}
      />
    );
  });
};

const AutoLoginRedirect = () => {
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return null;
};

const AutoRouter = () => {
  return (
    <PermissionCheck
      action="view_agents"
      fallback={<Redirect to="/conversation" />}
    >
      <Redirect to="/agents" />
    </PermissionCheck>
  );
};

const Routes = () => {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth();
  const { publicRoutes, privateRoutes, menuItems } = useRoutes(
    loginWithRedirect,
    logout,
    isAuthenticated
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <AutoLoginRedirect />;
  }

  return (
    <Layout menuItems={menuItems}>
      <Suspense fallback={null}>
        <Switch>
          <Route path="/404" exact>
            <Page404 />
          </Route>
          {mapItemsAsRoutes(publicRoutes)}
          <Route path="/">
            <ProtectedRoutes>
              {mapItemsAsRoutes(privateRoutes)}
              <Route path="/" exact>
                <AutoRouter />
              </Route>
              <Route path="*">
                <Page404 />
              </Route>
            </ProtectedRoutes>
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default Routes;
