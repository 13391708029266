import { useState } from 'react';

import { notify } from '../../../app';
import { useListFetch, useModal, useRequest } from '../../../hooks';
import {
  cancelCompressorTask,
  compressWithPrompt,
  createTaskWithFile,
  createTaskWithText,
  getTaskResultPromptById,
  getTasksList,
} from '../../../api';
import type {
  AnalyzeFile,
  AnalyzeText,
  CompressWithCustomPrompt,
} from '../../../api';

export const useCompressor = () => {
  const { isOpened, onClose, onOpen } = useModal();
  const [taskIdForAction, setTaskIdForAction] = useState<string | undefined>();

  const {
    isOpened: openedDeleteModal,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useModal();
  const handleOpenDeleteTaskModal = (taskId: string) => {
    setTaskIdForAction(taskId);
    openDeleteModal();
  };
  const [createTaskForm, setCreateTaskForm] = useState<
    Partial<any> | undefined
  >(undefined);

  const handleClose = () => {
    onClose();
    setCreateTaskForm(undefined);
  };

  const {
    nodes: tasksList = [],
    intializing: loadingTasks,
    loading: reloadingTasks,
    refresh,
    ...rest
  } = useListFetch<Record<string, any>>(
    params =>
      getTasksList(
        params?.pagination?.page ?? 1,
        params?.pagination?.perPage ?? 10
      ),
    {
      deps: [],
      withQueryParams: true,
    }
  );

  const [handleAddTaskWithText, addingTaskWithText] = useRequest(
    (_signal, payload: AnalyzeText) => createTaskWithText(payload),
    {
      onSuccess: () => {
        notify.success('New prompt task created');
        refresh();
        onClose?.();
      },
      onError: () => {
        notify.error('Failed to create new prompt task');
      },
      deps: [],
    }
  );

  const [handleAddTaskWithFile, addingTaskWithFile] = useRequest(
    (_signal, payload: AnalyzeFile) => createTaskWithFile(payload),
    {
      onSuccess: () => {
        notify.success('New prompt task created');
        refresh();
        onClose?.();
      },
      onError: () => {
        notify.error('Failed to create new prompt task');
      },
      deps: [],
    }
  );

  const [handleCompressWithPrompt, compressingWithPrompt] = useRequest(
    (_signal, formData: CompressWithCustomPrompt) =>
      compressWithPrompt(formData),
    {
      onSuccess: () => {
        notify.success('Compressing text with prompt successfully');
        refresh();
        onClose?.();
      },
      onError: () => {
        notify.error('Failed to compress the text with prompt');
      },
      deps: [],
    }
  );

  const [getTaskResultWithPromptById, compressingTextWithPrompt] = useRequest(
    (_signal, taskId: string) => {
      return getTaskResultPromptById(taskId);
    },
    {
      onError: () => {
        notify.error('Failed getTaskResultWithPromptById');
      },
      deps: [],
    }
  );

  const [handleCancelTask, canceling] = useRequest(
    _signal => {
      return taskIdForAction ? cancelCompressorTask(taskIdForAction) : null;
    },
    {
      onSuccess: () => {
        notify.success('The compressor task was deleted successfully');
        closeDeleteModal();
        refresh();
      },
      onError: () => {
        notify.error('Failed to delete the compressor task');
      },
      deps: [taskIdForAction],
    }
  );

  return {
    createTaskForm,
    currentFormId: 'compressor',
    setCreateTaskForm,
    tasksList: tasksList || [],
    loading: loadingTasks || reloadingTasks,
    handleAddTaskWithFile,
    handleAddTaskWithText,
    addingTaskWithFile,
    addingTaskWithText,
    isOpened,
    openedDeleteModal,
    closeDeleteModal,
    openDeleteModal: handleOpenDeleteTaskModal,
    onOpen,
    getTasksList: refresh,
    handleCompressWithPrompt,
    compressingWithPrompt,
    getTaskResultWithPromptById,
    handleCancelTask,
    canceling,
    compressingTextWithPrompt,
    onClose: handleClose,
    ...rest,
  };
};
