import React from 'react';
import clsx from 'clsx';

type SpinnerProps = {
  size?: number;
  color?: string;
  strokeWidth?: number;
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({
  size = 24,
  color = 'text-blue-500',
  strokeWidth = 4,
  className = 'h-5 w-5',
}) => {
  return (
    <svg
      className={clsx('animate-spin', color, className)}
      width={size}
      height={size}
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        className="opacity-75"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray="31.4 94.2"
        d="M25 5a20 20 0 0120 20"
      />
    </svg>
  );
};
