type SortableItem = { [key: string]: any };

const normalizeValue = <T>(value: T): T => {
  return typeof value === 'string' ? (value.toLowerCase() as T) : value;
};

// Cache sorted arrays using WeakMap
const cache = new WeakMap<object, Map<string, SortableItem[]>>();

export const sortArray = (
  array: SortableItem[],
  sort: SortState
): SortableItem[] => {
  if (!array.length) return array;

  const { name, direction } = sort;

  if (!name || direction === undefined) {
    return array;
  }

  // unique cache key
  const cacheKey = `${name}_${direction}`;

  // Retrieve or create a cache entry for this array
  let arrayCache = cache.get(array);
  if (!arrayCache) {
    arrayCache = new Map<string, SortableItem[]>();
    cache.set(array, arrayCache);
  }

  // Return cached result if it exists
  if (arrayCache.has(cacheKey)) {
    return arrayCache.get(cacheKey)!;
  }

  // Perform the sort operation
  const sortedArray = [...array].sort((a, b) => {
    const valueA = a[name];
    const valueB = b[name];

    // Convert to lowercase if both values are strings for case-insensitive comparison
    const normalizedValueA = normalizeValue(valueA);
    const normalizedValueB = normalizeValue(valueB);

    if (normalizedValueA < normalizedValueB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (normalizedValueA > normalizedValueB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Store the sorted array in the cache
  arrayCache.set(cacheKey, sortedArray);
  return sortedArray;
};
