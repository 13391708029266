import React, { useState } from 'react';

import { Modal } from '../../../../components';

interface ImageModalProps {
  imageUrl: string;
  label?: string;
  children?: React.ReactNode;
}

const ScrapingImageModal: React.FC<ImageModalProps> = ({
  imageUrl,
  label = 'View Image',
  children,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const onOpen = () => setIsOpened(true);
  const onClose = () => setIsOpened(false);

  const cursorClassName = imageUrl ? 'cursor-pointer' : '';

  return (
    <div className={cursorClassName}>
      <div onClick={onOpen}>{children}</div>
      <Modal open={isOpened && !!imageUrl} onClose={onClose} title={label}>
        <div className="flex justify-center items-center max-w-full max-h-[80vh] overflow-auto p-4">
          <img
            src={imageUrl}
            alt="Preview"
            className="max-w-full max-h-[75vh] object-contain rounded-md shadow-md"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ScrapingImageModal;
