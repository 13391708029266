import BigNumber from 'bignumber.js';

export const roundTo = (maybeNumber: string | number, places: number = 2) => {
  if (maybeNumber === '') return '';
  if (!maybeNumber) return 0;

  const input =
    typeof maybeNumber === 'string'
      ? parseFloat(maybeNumber.replace(/,/, '.'))
      : maybeNumber;

  return new BigNumber(input).decimalPlaces(places).toNumber();
};
