import { useRouteMatch } from 'react-router';

import { UsersTab } from './UsersTab';
import { getUserName } from '../../utilities';
import { AssignUserForm } from './AssignUserForm';
import { useOrganization } from './useOrganization';
import { SectionHeader, Status } from '../../components';
import { Button, Loading, Modal } from '../../components';
import { AssignUserRoleForm } from './AssignUserRoleForm';
import { Tabs, Tab, ConfirmModal } from '../../components';
import { UnassignUserRoleForm } from './UnassignUserRoleForm';
import { DynamicPageTitle, Breadcrumbs } from '../../components';

type TabsEnum = 'users' | 'knowledge';

type CounterProps = {
  label: string;
  value: React.ReactNode;
};
const Counter: React.FC<CounterProps> = ({ label, value }) => {
  return (
    <div className="flex flex-col gap-y-sm">
      <div className="text-mainText text-tiny font-medium">{label}</div>
      <div className="text-main text-section font-bold">{value}</div>
    </div>
  );
};

const Organization = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const {
    organization,
    users,
    loading,
    isOpened,

    onOpen,
    onClose,
    handleAddUser,
    userOptions,
    updating,

    rolesOptions,
    formForAction,

    onCloseRoleModal,
    isOpenedRoleModal,
    onOpenAddRoleModal,
    handleAssignUserRole,

    isOpenedUnassignModal,
    handleUnassignUser,
    onOpenUnassignModal,
    onCloseUnassignModal,

    isOpenedUnassignRoleModal,
    onOpenUnassignRoleModal,
    onCloseUnassignRoleModal,
    handleUnassignRoleUser,
  } = useOrganization(params.id);

  if (!organization || !params.id || loading) {
    return (
      <>
        <DynamicPageTitle>Organization</DynamicPageTitle>
        {loading && <Loading />}
      </>
    );
  }

  const breadcrumbs = (
    <Breadcrumbs
      className="mb-md"
      subtitle="Organizations"
      subtitleUrl="/user_management"
      title={organization?.name ?? ''}
    />
  );

  return (
    <>
      <div className="border-b pb-lg mb-lg">
        <div className="flex gap-md flex-wrap justify-between items-center mb-md">
          {breadcrumbs}
        </div>
        <div className="flex flex-row grow">
          <SectionHeader
            className="grow"
            label={
              <div className="flex flex-row gap-x-md">
                {organization.name}
                <Status active={organization.active} />
              </div>
            }
            subLabel={'Description'}
            content={
              <div className="flex flex-row gap-x-lg items-center">
                <Button
                  className="h-fit"
                  onClick={onOpen}
                  label="Assign user to Organization"
                />
                <Counter label="Total Users" value={organization.userCount} />
                <Counter label="Active Users" value={organization.userCount} />
              </div>
            }
          />
        </div>
      </div>
      <DynamicPageTitle>Organization</DynamicPageTitle>
      <ConfirmModal
        open={isOpenedUnassignModal}
        onClose={onCloseUnassignModal}
        onConfirm={handleUnassignUser}
        key={String(isOpenedUnassignModal) + '_unassign_user'}
        title={`Remove ${getUserName(formForAction)} from organization?`}
      />
      <Modal
        size="small"
        title="Assign user"
        open={isOpened}
        onClose={onClose}
        key={String(isOpened) + '_assign_user'}
      >
        <AssignUserForm
          saving={updating}
          users={userOptions}
          onCancel={onClose}
          onSave={handleAddUser}
        />
      </Modal>
      <Modal
        size="small"
        title="Assign role"
        open={isOpenedRoleModal}
        onClose={onCloseRoleModal}
        key={String(isOpenedRoleModal) + '_assign_role'}
      >
        <AssignUserRoleForm
          saving={updating}
          roles={rolesOptions}
          user={formForAction}
          onCancel={onCloseRoleModal}
          onSave={handleAssignUserRole}
        />
      </Modal>
      <Modal
        size="small"
        title="Remove user role"
        open={isOpenedUnassignRoleModal}
        onClose={onCloseUnassignRoleModal}
        key={String(isOpenedUnassignRoleModal) + '_unassign_role'}
      >
        <UnassignUserRoleForm
          saving={updating}
          roles={rolesOptions}
          user={formForAction}
          onCancel={onCloseUnassignRoleModal}
          onSave={form => handleUnassignRoleUser(form.roleId)}
        />
      </Modal>
      <Tabs<TabsEnum>
        tabs={[
          { name: 'users', label: 'Users' },
          { name: 'knowledge', label: 'Knowledge' },
        ]}
        contentClassName="mt-md"
      >
        <Tab name="users">
          <UsersTab
            users={users}
            loading={loading}
            updating={updating}
            onOpenAddRoleModal={onOpenAddRoleModal}
            onOpenUnassignModal={onOpenUnassignModal}
            onOpenRemoveRoleModal={onOpenUnassignRoleModal}
          />
        </Tab>
        <Tab name="knowledge">Knowledge</Tab>
      </Tabs>
    </>
  );
};

export default Organization;
