import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getUrlParamActions } from './getUrlParamActions';
import type { GetUrlParamActionsParams } from './getUrlParamActions';

type UseUrlParamParams<T> = GetUrlParamActionsParams & {
  enable?: boolean;
  defaultValue?: T;
  parseData?: (data: any) => T;
};

const defaultParseData = <T>(data: any): T => data;

export const useUrlParam = <T>({
  name,
  defaultValue,
  enable = true,
  parseData = defaultParseData,
}: UseUrlParamParams<T>) => {
  const history = useHistory();
  const location = useLocation();

  const [getInitialParam, setParamToUrl] = getUrlParamActions<T>({
    name,
    history,
    search: location.search,
  });

  const [param, setParam] = useState<T | undefined>(() =>
    enable ? parseData(getInitialParam()) ?? defaultValue : defaultValue
  );

  const handleParamChange = useCallback(
    (newParam?: T) => {
      setParam(newParam);
      enable && setParamToUrl(newParam);
    },
    [enable, setParamToUrl]
  );

  return [param, handleParamChange] as const;
};
