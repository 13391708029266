import { useEffect, useState } from 'react';

export const useDebouncedValue = <T = any>(value: T, wait: number = 500) => {
  const [_value, setValue] = useState(value);

  useEffect(() => {
    const current = window.setTimeout(() => {
      setValue(value);
    }, wait);

    return () => {
      window.clearTimeout(current);
    };
  }, [value, wait]);

  return _value;
};
