import { notify } from '../../app';
import { useFetch } from '../../hooks';
import { useInterval } from '../../hooks';
import {
  getConversationAudio,
  getConversationParticipantInfo,
} from '../../api';
import { getConversation, getConversationMessages } from '../../api';

const REFRESH_MESSAGES_INTERVAL = 10 * 1000;
const REFRESH_CONVO_INTERVAL = 1 * 60 * 1000;

export const useConversation = (id: string) => {
  const {
    data: conversation,
    intializing: loadingConvo,
    refresh: refreshConvo,
  } = useFetch(() => getConversation(id), [id]);
  const {
    data: audio,
    intializing: loadingAudio,
    refresh: refreshAudio,
  } = useFetch(() => getConversationAudio(id), [id]);
  useInterval(refreshConvo, REFRESH_CONVO_INTERVAL, !conversation?.ended_at);

  const {
    data: messages,
    intializing: loadingMessages,
    refresh: refreshMessages,
  } = useFetch(() => getConversationMessages(id), [id]);
  useInterval(
    refreshMessages,
    REFRESH_MESSAGES_INTERVAL,
    !conversation?.ended_at
  );

  const participant_external_id =
    conversation?.participant_external_id ||
    // TODO: remove this line
    messages?.find(m => m.author_type === 'user')?.author_identifier;

  const { data: participant, intializing: loadingParticipant } = useFetch(
    () =>
      participant_external_id
        ? getConversationParticipantInfo(participant_external_id)
        : null,
    [participant_external_id],
    {
      skip: !participant_external_id,
      onError: (ex: any) => {
        if (ex?.status === 404) {
          return notify.error('Participant info not found');
        }

        notify.error('Something went wrong');
      },
    }
  );

  return {
    messages,
    participant,
    conversation,
    audio,
    loadingAudio,
    refreshAudio,
    loading:
      loadingConvo ||
      loadingMessages ||
      (loadingParticipant && !!participant_external_id),
  };
};

export type { MessageResponse } from '../../api';
