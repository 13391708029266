import { useEffect } from 'react';

import type { AgentForm } from '../../../utilities';
import type { AgentResponse, AiModelData, NodeJSON } from '../../../api';

type UseSyncedModelSettingsProps = {
  form: AgentForm | null;
  data: AgentResponse | null;
  setForm: React.Dispatch<React.SetStateAction<AgentForm | null>>;
  setFormId: React.Dispatch<React.SetStateAction<number>>;
  models: {
    ttsPrimary: AiModelData;
    ttsBackup: AiModelData;
    sttPrimary: AiModelData;
    sttBackup: AiModelData;
    llmPrimary: AiModelData;
    llmBackup: AiModelData;
    turnDetector: AiModelData;
  };
};

export const useSyncedModelSettings = ({
  form,
  data,
  setForm,
  setFormId,
  models,
}: UseSyncedModelSettingsProps) => {
  const {
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
  } = models;

  useEffect(() => {
    if (
      ttsPrimary.modelName &&
      ttsBackup.modelName &&
      sttPrimary.modelName &&
      sttBackup.modelName &&
      llmPrimary.modelName &&
      llmBackup.modelName &&
      turnDetector.modelName
    ) {
      setForm((f: any) => {
        if (!f) return f;

        return {
          ...f,
          tts_primary_model_settings:
            Object.keys(data?.tts_primary_model_settings || {}).length > 0 &&
            form?.tts_primary_model_id === data?.tts_primary_model_id
              ? data?.tts_primary_model_settings
              : ttsPrimary.settings,
          tts_backup_model_settings:
            Object.keys(data?.tts_backup_model_settings || {}).length > 0 &&
            form?.tts_backup_model_id === data?.tts_backup_model_id
              ? data?.tts_backup_model_settings
              : ttsBackup.settings,
          stt_primary_model_settings:
            Object.keys(data?.stt_primary_model_settings || {}).length > 0 &&
            form?.stt_primary_model_id === data?.stt_primary_model_id
              ? data?.stt_primary_model_settings
              : sttPrimary.settings,
          stt_backup_model_settings:
            Object.keys(data?.stt_backup_model_settings || {}).length > 0 &&
            form?.stt_backup_model_id === data?.stt_backup_model_id
              ? data?.stt_backup_model_settings
              : sttBackup.settings,
          llm_primary_model_settings:
            Object.keys(data?.llm_primary_model_settings || {}).length > 0 &&
            form?.llm_primary_model_id === data?.llm_primary_model_id
              ? data?.llm_primary_model_settings
              : llmPrimary.settings,
          llm_backup_model_settings:
            Object.keys(data?.llm_backup_model_settings || {}).length > 0 &&
            form?.llm_backup_model_id === data?.llm_backup_model_id
              ? data?.llm_backup_model_settings
              : llmBackup.settings,
          llm_turn_detector_model_settings:
            Object.keys(data?.llm_turn_detector_model_settings || {}).length >
              0 &&
            form?.llm_turn_detector_model_id ===
              data?.llm_turn_detector_model_id
              ? data?.llm_turn_detector_model_settings
              : turnDetector.settings,
        };
      });
      setFormId(f => f + 1);
    }
  }, [
    ttsPrimary.modelName,
    ttsBackup.modelName,
    data?.tts_primary_model_settings,
    data?.tts_backup_model_settings,
    form?.tts_primary_model_id,
    form?.tts_backup_model_id,
    setForm,
    setFormId,
    data?.tts_primary_model_id,
    data?.tts_backup_model_id,
    sttPrimary.modelName,
    data?.stt_primary_model_settings,
    data?.stt_primary_model_id,
    form?.stt_primary_model_id,
    sttBackup.modelName,
    sttBackup.settings,
    data?.stt_backup_model_settings,
    data?.stt_backup_model_id,
    form?.stt_backup_model_id,
    ttsPrimary.settings,
    ttsBackup.settings,
    sttPrimary.settings,
    llmPrimary.modelName,
    llmPrimary.settings,
    data?.llm_primary_model_settings,
    data?.llm_primary_model_id,
    form?.llm_primary_model_id,
    llmBackup.modelName,
    llmBackup.settings,
    data?.llm_backup_model_settings,
    data?.llm_backup_model_id,
    form?.llm_backup_model_id,
    turnDetector.modelName,
    turnDetector.settings,
    data?.llm_turn_detector_model_settings,
    data?.llm_turn_detector_model_id,
    form?.llm_turn_detector_model_id,
  ]);
};
