import { AddSharp, EmailOutlined } from '@mui/icons-material';

import { useUsers } from './useUsers';
import { NewUserForm } from './NewUserForm';
import { formatDate } from '../../../utilities';
import { AssignUserForm } from './AssignUserForm';
import { Table, SearchPanel } from '../../../components';
import { Status, MoreActions } from '../../../components';
import { Button, Modal, DynamicPageTitle, Copy } from '../../../components';

export const UsersTab = () => {
  const {
    users,
    loading,

    isOpened,
    updating,
    search,

    onOpen,
    onClose,
    setSearch,
    handleAddUser,

    pagination,
    setPage,

    sort,
    setSort,

    roles,
    organizations,
    assignOpened,
    formForAction,
    closeAssignModal,
    handleAssignUser,
    handleOpenAssignModal,

    inviting,
    handleInviteUser,
  } = useUsers();

  return (
    <>
      <DynamicPageTitle>Users</DynamicPageTitle>
      <div className="flex justify-between items-center mb-sm">
        <SearchPanel
          search={search}
          onSearch={setSearch}
          withPaginate={false}
        />

        <Button label="Add User" onClick={onOpen} size="medium" />
      </div>
      <Modal
        size="small"
        open={isOpened}
        onClose={onClose}
        title="Create new user"
      >
        <NewUserForm
          onCancel={onClose}
          saving={updating}
          onSave={handleAddUser}
        />
      </Modal>
      <Modal
        size="small"
        title="Assign User"
        open={assignOpened}
        onClose={closeAssignModal}
        key={formForAction?.id ?? 'new'}
      >
        <AssignUserForm
          roles={roles}
          organizations={organizations}
          saving={updating}
          user={formForAction}
          userId={formForAction?.id!}
          onSave={handleAssignUser}
          onCancel={closeAssignModal}
        />
      </Modal>
      <Table
        sort={sort}
        onSort={setSort}
        data={users}
        loading={loading}
        emptyMessage="There are no users."
        config={{
          columns: [
            {
              header: 'Name',
              sortName: 'username',
              cellClassName: 'min-w-[20rem]',
              getValue: ({ username, id }) => (
                <>
                  <span>{username || id}</span>
                  <Copy
                    text={id ?? ''}
                    label="Copy ID"
                    className="mr-xs block"
                  />
                </>
              ),
            },
            {
              header: 'Organization',
              cellClassName: 'min-w-[35rem]',
              getValue: ({ organizationRoles }) => (
                <>
                  {organizationRoles?.map(organization => (
                    <div key={organization.id}>
                      {organization.name}:{' '}
                      {organization.roles?.map(r => r.name).join(', ') ||
                        'No Roles'}
                    </div>
                  ))}
                </>
              ),
            },

            {
              header: 'Last Active',
              getValue: ({ lastActivity }) => formatDate(lastActivity),
            },
            {
              header: 'Status',
              getValue: ({ enabled }) => <Status active={enabled} />,
            },
            {
              header: '',
              getValue: row => (
                <MoreActions
                  options={[
                    {
                      label: 'Assign',
                      icon: <AddSharp />,
                      onClick: () => handleOpenAssignModal(row),
                    },
                    {
                      label: 'Invite',
                      loading: inviting,
                      icon: <EmailOutlined />,
                      onClick: () => handleInviteUser(row.id),
                    },
                  ]}
                />
              ),
            },
          ],
        }}
      />
      <div className="mt-sm flex justify-between items-center">
        <SearchPanel withPaginate {...pagination} setPage={setPage} />
      </div>
    </>
  );
};
