import clsx from 'clsx';

type SectionHeaderProps = {
  subLabel?: string;
  className?: string;
  contentClassName?: string;

  label: React.ReactNode;
  content?: React.ReactNode;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  label,
  content,
  subLabel,
  className = '',
  contentClassName = '',
}) => {
  return (
    <div
      className={clsx(
        className,
        subLabel ? 'h-[5.6rem]' : 'h-[4.8rem]',
        'mb-sm flex flex-row justify-between items-center'
      )}
    >
      <div className="flex flex-col gap-y-sm">
        <div className="text-section font-bold text-mainText">{label}</div>
        {!!subLabel && (
          <div className="text-medium text-secondary">{subLabel}</div>
        )}
      </div>
      {!!content && (
        <div className={clsx(contentClassName, 'text-mainText text-medium')}>
          {content}
        </div>
      )}
    </div>
  );
};
