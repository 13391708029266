import { ToastContainer, toast } from 'react-toastify';
import clsx from 'clsx';

import { Close } from './Close';

const contextClass = {
  success: 'border-l-green-600',
  error: 'border-l-red-600',
  info: 'border-l-gray-600',
  warning: 'border-l-orange-400',
  default: 'border-l-indigo-600',
  dark: 'border-l-white-600 font-gray-300',
};

export const notify = toast;

export const Notifications: React.FC = () => {
  return (
    <ToastContainer
      rtl={false}
      icon={false}
      newestOnTop
      closeOnClick
      pauseOnHover
      hideProgressBar
      autoClose={10_000}
      position="bottom-right"
      closeButton={<Close />}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      toastClassName={({ type, defaultClassName } = {}) =>
        clsx(
          'mb-md bg-contrast rounded-bt text-mainText text-small pl-lg',
          'border-l-4 border border-solid shadow-popout-main',
          defaultClassName,
          contextClass[type!]
        )
      }
    />
  );
};
