import { Dropdown } from '../Dropdown';
import { Paginate } from '../Paginate';
import { SearchBar } from '../SearchBar';
import { ViewSelect } from '../ViewSelect';

import type { SearchBarProps } from '../SearchBar';
import type { ViewSelectProps } from '../ViewSelect';

type SearchPanelProps<T = any> = Partial<SearchBarProps> &
  Partial<ViewSelectProps> & {
    withPaginate?: boolean;

    sort?: T;
    options?: OptionType<T>[];
    onSort?: (newSort?: T) => void;

    page?: number;
    perPage?: number;
    totalItems?: number;
    setPage?: (page: number) => void;
  };

export const SearchPanel = <T = any,>({
  search,
  onSearch,
  view,
  sort,
  onSort,
  options,
  onChangeView,

  page,
  perPage,
  totalItems,
  setPage,
  withPaginate = true,
}: SearchPanelProps<T>) => {
  return (
    <div className="flex gap-x-md items-center">
      {!!withPaginate && (
        <Paginate
          page={page}
          setPage={setPage}
          itemsPerPage={perPage}
          totalItems={totalItems}
          className="rounded-card bg-contrast px-4 border border-border shadow-sm py-[0.325rem]"
        />
      )}
      {!!onSearch && <SearchBar search={search ?? ''} onSearch={onSearch} />}
      {!!options?.length && !!onSort && (
        <Dropdown
          value={sort}
          options={options}
          onChange={onSort}
          placeholder="Sort By:"
          inputRoundedClassName="rounded-card"
        />
      )}
      {!!view && !!onChangeView && (
        <ViewSelect view={view} onChangeView={onChangeView} />
      )}
    </div>
  );
};
