export const SearchIcon = () => {
  return (
    <svg
      width="23"
      height="27"
      fill="none"
      viewBox="0 0 23 27"
      className="text-mainText w-8 h-8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18.4755 6.54504C21.3198 10.4505 20.4596 15.9222 16.5541 18.7665C12.6487 21.6108 7.17691 20.7506 4.33262 16.8451C1.48834 12.9397 2.34858 7.46793 6.25402 4.62364C10.1595 1.77935 15.6312 2.63959 18.4755 6.54504ZM18.2288 19.9987C22.5992 16.4133 23.4626 9.99545 20.0922 5.36762C16.5976 0.569301 9.87493 -0.487609 5.07661 3.00695C0.278285 6.50151 -0.778625 13.2242 2.71593 18.0225C5.93779 22.4464 11.9036 23.69 16.5755 21.1185C16.5909 21.1443 16.6076 21.1695 16.6256 21.1943L20.1781 26.0722C20.5033 26.5186 21.1287 26.6169 21.5752 26.2918C22.0216 25.9667 22.12 25.3412 21.7948 24.8947L18.2423 20.0168C18.2379 20.0107 18.2334 20.0047 18.2288 19.9987Z"
      />
    </svg>
  );
};
