/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagePageItemUserDto } from '../models/PagePageItemUserDto';
import type { UserDto } from '../models/UserDto';
import type { UserRequest } from '../models/UserRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserManagementApiService {
    /**
     * @param xAiAgentOrganizationContext
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns PagePageItemUserDto OK
     * @throws ApiError
     */
    public static getUsers(
        xAiAgentOrganizationContext?: string,
        page?: number,
        size: number = 10,
        sort?: Array<string>,
    ): CancelablePromise<PagePageItemUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/users',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserDto OK
     * @throws ApiError
     */
    public static createUser(
        requestBody: UserRequest,
    ): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static unblockUser(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/users/{userId}/unblock',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static inviteUser(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/users/{userId}/invite',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static blockUser(
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/users/{userId}/block',
            path: {
                'userId': userId,
            },
        });
    }
}
