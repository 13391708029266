import { useMemo, useState } from 'react';

import { useModal } from '../../../hooks';
import { Button, Table, Modal, Toggle } from '../../../components';
import type { RoleType, PermissionType } from './useRoles';

type PermissionTypeForChecker = PermissionType & { checked: boolean };

type EditRolePermissionsProps = {
  role: RoleType;
  allPermissions: PermissionType[];

  loading: boolean;
  onEditPermissions: (
    roleId: string,
    newPermissionsIds: string[]
  ) => Promise<void>;
};

type PermissionsFormProps = {
  role: RoleType;
  allPermissions: PermissionType[];

  onSubmit: (roleId: string, newPermissionsIds: string[]) => Promise<void>;
};

const PermissionsForm: React.FC<PermissionsFormProps> = ({
  role,
  onSubmit,
  allPermissions,
}) => {
  const [permissions, setPermissions] = useState<string[]>(
    role.permissions?.map(p => p.id ?? '') ?? []
  );
  const data: PermissionTypeForChecker[] = useMemo(
    () =>
      allPermissions.map(p => ({
        id: p.id,
        name: p.name,
        checked: permissions.includes(p.id ?? ''),
      })),
    [permissions, allPermissions]
  );

  return (
    <>
      <Table
        data={data}
        cellsClassName="bg-background"
        emptyMessage="No permissions"
        config={{
          columns: [
            {
              header: '',
              cellClassName: 'min-w-[10rem] max-w-[10rem] w-[10rem]',
              getValue: ({ id, checked }) => (
                <Toggle
                  value={checked}
                  onChange={newValue =>
                    setPermissions(old => {
                      if (newValue) return [...old, id || ''];

                      return old.filter(o => o !== id);
                    })
                  }
                />
              ),
            },
            { header: 'Name', getValue: ({ name }) => name },
          ],
        }}
      />
      <Button onClick={() => onSubmit(role.id!, permissions)} label="Save" />
    </>
  );
};

export const EditRolePermissions: React.FC<EditRolePermissionsProps> = ({
  role,
  loading,
  allPermissions,

  onEditPermissions,
}) => {
  const { isOpened, onOpen, onClose } = useModal();

  return (
    <>
      <Modal
        title={`Edit ${role.name} permissions`}
        open={isOpened}
        onClose={onClose}
      >
        <PermissionsForm
          key={String(isOpened)}
          role={role}
          allPermissions={allPermissions}
          onSubmit={async (...params) => {
            await onEditPermissions(...params);
            onClose();
          }}
        />
      </Modal>
      <Button
        size="small"
        onClick={onOpen}
        disabled={loading}
        label="Edit Role Permissions"
      />
    </>
  );
};
