import { SpiderService } from '../services/scraper';
import type { WithPaginationResult } from '../hooks';
import type { TaskStatus } from '../services/scraper';
import type {
  PaginationInfo,
  ParsePayload,
  TaskDetails,
  TaskResponse,
  TasksResponse,
  TaskStatusResponse,
} from '../services/scraper';

type TaskData = TaskDetails;
type TaskDetailsData = TaskData & {
  screenshot?: string;
  id?: string;
};

export type TasksDetailsResponse = {
  tasks: Array<TaskDetailsData>;
  pagination: PaginationInfo;
};
export type TaskStatusKey = keyof typeof TaskStatus;
export type TaskStatusValue = (typeof TaskStatus)[TaskStatusKey];
export type TaskPayload = ParsePayload;
export type TaskResult = TaskResponse | TasksResponse | TaskStatusResponse;

export const getScrapingList = async (
  page: number,
  limit: number,
  url?: string | null,
  status?: TaskStatusValue | null
): Promise<WithPaginationResult<TasksDetailsResponse>> => {
  const response = await SpiderService.getTasksScraperApiV1SpiderTasksGet(
    page,
    limit,
    url,
    status
  );

  return {
    content: [response],
  };
};

export const addScrapingTask = (
  urls: Array<string>,
  depth?: number | null,
  is_include_links?: boolean,
  requested_by?: string | null,
  take_screenshot?: boolean
): Promise<TaskResult> => {
  return SpiderService.submitParseRequestScraperApiV1SpiderParsePost({
    urls,
    depth,
    is_include_links,
    requested_by,
    take_screenshot,
  });
};

export const retryScrapingTask = (taskId: string): Promise<TaskResult> => {
  return SpiderService.retryTaskScraperApiV1SpiderTasksTaskIdRetryPost(taskId);
};

export const cancelScrapingTask = (taskId: string): Promise<void> => {
  return SpiderService.cancelTaskScraperApiV1SpiderTasksTaskIdCancelDelete(
    taskId
  );
};

//TODO: temporary solution until OpenAPI is fixed by provider
export const downloadScrapingTask = async (taskId: string): Promise<Blob> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API_URL}/scraper/api/v1/spider/result/${taskId}/download/`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      redirect: 'follow',
    }
  );

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Download failed: ${response.status} - ${errorText}`);
  }

  return response.blob();
};
