import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Modal, Input } from '../../../components';
import { AVAILABLE_TYPES } from '../utils/const';

interface PropertyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    name: string,
    type: string,
    layout: string,
    enumValues?: string
  ) => void;
  layouts: string[];
  groupName: string;
}

const PropertyModal: React.FC<PropertyModalProps> = ({
  isOpen,
  onClose,
  onSave,
  layouts,
  groupName,
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('string');
  const [enumValues, setEnumValues] = useState('');
  const [selectedLayout, setSelectedLayout] = useState('');

  useEffect(() => {
    setName('');
    setType('string');
    setEnumValues('');
    setSelectedLayout(layouts[0] || '');
  }, [isOpen, layouts]);

  const handleSave = () => {
    if (!name.trim() || !selectedLayout) return;
    onSave(
      name,
      type,
      selectedLayout?.split(' ')?.[1],
      type === 'enum' ? enumValues : undefined
    );
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={`Add Property to ${groupName}`}
    >
      <div className="p-5 flex flex-col gap-4">
        {layouts.length > 0 && (
          <Dropdown
            options={layouts.map(layout => ({ label: layout, value: layout }))}
            value={selectedLayout}
            onChange={setSelectedLayout}
            placeholder="Choose Layout"
          />
        )}

        <Input
          label="Property Name"
          value={name}
          onChange={setName}
          placeholder="Enter property name"
        />

        <Dropdown
          options={AVAILABLE_TYPES.map(t => ({ label: t, value: t }))}
          value={type}
          onChange={setType}
        />

        {type === 'enum' && (
          <Input
            label="Enum Values"
            value={enumValues}
            onChange={setEnumValues}
            placeholder="Comma-separated values"
          />
        )}

        <div className="flex justify-end gap-3">
          <Button
            label="Cancel"
            className="bg-gray-500 text-white"
            onClick={onClose}
          />
          <Button
            label="Save"
            className="bg-green-500 text-white"
            onClick={handleSave}
            disabled={!name.trim() || !selectedLayout}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PropertyModal;
