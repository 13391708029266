import { useState, useCallback, useRef } from 'react';

import { notify } from '../app';

import { useAuth } from './useAuth';
import { useRefValue, useUpdateEffect, useDeepEffect } from '.';

type FetchFunctionType<T> = (cancelToken: AbortSignal) => Promise<T> | T;
type UseFetchConfig<T> = {
  delay?: number;
  skip?: boolean;
  initialData?: T;
  initialLoading?: boolean;
  onError?: ((ex: Error) => void) | false;
  onSuccess?: (result: T, isRefresh: boolean) => void;

  transformData?: (
    newData: T | null,
    oldData: T | null,
    isRefresh: boolean
  ) => T;
};

const defaultTransformData: UseFetchConfig<any>['transformData'] = data => data;

export const useFetch = <T>(
  request: FetchFunctionType<T>,
  deps: any[] = [],
  config?: UseFetchConfig<T>
): UseFetchResultType<T> => {
  const lockRef = useRef(true);
  const [fetchId, setFetchId] = useState(1);
  const [resultId, setResultId] = useState(1);
  const { isLoading, isAuthenticated, token } = useAuth();

  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(config?.initialLoading ?? true);
  const [data, setData] = useState<T | null>(config?.initialData ?? null);
  const refreshRef = useRefValue(false);

  const fetchFuncRef = useRefValue(request);
  const delayRef = useRefValue(config?.delay);
  const defaultOnError = () => {
    const message: string = 'Something went wrong';
    notify.error(message);
  };
  const skip = config?.skip;
  const onSuccessRef = useRefValue(config?.onSuccess);
  const onErrorRef = useRefValue(config?.onError ?? defaultOnError);
  const transformDataRef = useRefValue(
    config?.transformData ?? defaultTransformData
  );

  useUpdateEffect(() => {
    if (lockRef.current) {
      return;
    }

    let ignore = false;
    const controller = new AbortController();

    const fetchFunc = fetchFuncRef.current;
    const onSuccess = onSuccessRef.current;
    const onError = onErrorRef.current;

    const performFetch = async () => {
      try {
        setLoading(true);
        const result = await fetchFunc?.(controller.signal);

        if (!ignore) {
          setData(old =>
            transformDataRef.current(result, old, refreshRef.current)
          );
          setError(null);
          onSuccess?.(result, refreshRef.current);
        }
      } catch (ex: any) {
        // if (agent.isCancel(ex)) {
        //   return console.info('request canceled');
        // }

        console.error(ex);
        if (ignore) return;

        if (!ignore) {
          setData(null);
          setError(ex);

          if (onError) onError?.(ex);
        }
      } finally {
        !ignore && lockRef && (lockRef.current = true);

        !ignore && setLoading(false);
        !ignore && setResultId(r => r + 1);
      }
    };

    const timedOut = setTimeout(performFetch, delayRef.current ?? 50);

    return () => {
      ignore = true;

      clearTimeout(timedOut);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchId]);

  useDeepEffect(
    !isLoading && !skip
      ? () => {
          lockRef.current = false;

          setFetchId(f => f + 1);
        }
      : () => {},
    [...deps, skip, token, isLoading, isAuthenticated]
  );

  const handleRefresh = useCallback(() => {
    lockRef.current = false;

    setLoading(true);
    setFetchId(f => f + 1);
    refreshRef.current = true;
  }, [refreshRef]);

  return {
    data,
    error,
    loading,
    resultId,
    intializing: loading && !data && !error,
    reloading: loading && (!!data || !!error),

    setData,
    refresh: handleRefresh,
  };
};
