import { Button, Modal } from '..';

type ConfirmModalProps = {
  title?: string;
  open: boolean;
  saving?: boolean;

  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  saving,
  title = 'Confirm',

  onClose,
  onConfirm,
}) => {
  return (
    <Modal size="small" title={title} open={open} onClose={onClose}>
      <div className="flex flex-col gap-y-md justify-center items-center mt-md">
        <Button
          size="large"
          label="Confirm"
          disabled={saving}
          className="w-full"
          onClick={onConfirm}
        />
        <Button
          size="large"
          type="light"
          label="Cancel"
          className="w-full"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};
