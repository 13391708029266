/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConversationAudioFile } from '../models/ConversationAudioFile';
import type { ConversationDto } from '../models/ConversationDto';
import type { ConversationListResponse } from '../models/ConversationListResponse';
import type { MessageDto } from '../models/MessageDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ConversationApiService {
    /**
     * @param xAiAgentOrganizationContext
     * @param botMasterId
     * @param startedAt
     * @param endedAt
     * @param completedConversations
     * @param conversationId
     * @param userId
     * @param userName
     * @param agentId
     * @param agentTitle
     * @param agentName
     * @param country
     * @param page Zero-based page index (0..N)
     * @param size The size of the page to be returned
     * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @returns ConversationListResponse OK
     * @throws ApiError
     */
    public static getConversations(
        xAiAgentOrganizationContext: string,
        botMasterId?: string,
        startedAt?: string,
        endedAt?: string,
        completedConversations?: boolean,
        conversationId?: string,
        userId?: string,
        userName?: string,
        agentId?: string,
        agentTitle?: string,
        agentName?: string,
        country?: string,
        page?: number,
        size: number = 100,
        sort?: Array<string>,
    ): CancelablePromise<ConversationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/messages/conversations',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            query: {
                'bot_master_id': botMasterId,
                'started_at': startedAt,
                'ended_at': endedAt,
                'completed_conversations': completedConversations,
                'conversation_id': conversationId,
                'user_id': userId,
                'user_name': userName,
                'agent_id': agentId,
                'agent_title': agentTitle,
                'agent_name': agentName,
                'country': country,
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
    }
    /**
     * @param conversationId
     * @param xAiAgentOrganizationContext
     * @returns ConversationDto OK
     * @throws ApiError
     */
    public static getConversation(
        conversationId: string,
        xAiAgentOrganizationContext: string,
    ): CancelablePromise<ConversationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/messages/conversations/{conversationId}',
            path: {
                'conversationId': conversationId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
        });
    }
    /**
     * @param xAiAgentOrganizationContext
     * @param conversationId
     * @returns MessageDto OK
     * @throws ApiError
     */
    public static getMessagesByConversationId(
        xAiAgentOrganizationContext: string,
        conversationId: string,
    ): CancelablePromise<Array<MessageDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/messages/conversations/{conversationId}/messages',
            path: {
                'conversationId': conversationId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
        });
    }
    /**
     * @param xAiAgentOrganizationContext
     * @returns string OK
     * @throws ApiError
     */
    public static getConversationCountries(
        xAiAgentOrganizationContext: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/messages/conversations/countries',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
        });
    }
    /**
     * @param conversationId
     * @returns ConversationAudioFile OK
     * @throws ApiError
     */
    public static getAudioFiles(
        conversationId: string,
    ): CancelablePromise<Array<ConversationAudioFile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/conversations/{conversationId}/audio',
            path: {
                'conversationId': conversationId,
            },
        });
    }
}
