import { useMemo, useState } from 'react';

import { useSort } from '../../hooks';
import { getAgents } from '../../api';
import { sortArray } from '../../utilities';
import { searchArray } from '../../utilities';
import { useFetch, useDebouncedValue } from '../../hooks';
import type { AgentCreateRequest } from '../../api';

export type AgentFormType = AgentCreateRequest;

export const useAgents = () => {
  const {
    data,
    // refresh,
    intializing: loading,
  } = useFetch(async () => {
    const res = await getAgents();
    if (!Array.isArray(res)) throw new Error('Wrong data type');
    return res;
  }, []);
  const { sort, setSort } = useSort();
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebouncedValue(search);

  const agents = useMemo(
    () =>
      sortArray(
        searchArray(data ?? [], debouncedSearch, [
          'title',
          'agent_name',

          'llm_model',
          'stt_model',
          'tts_model',

          'settings.keywords',
          'settings.activation_phrases',
        ]),
        sort
      ),
    [data, sort, debouncedSearch]
  );

  return {
    loading,

    agents,

    search,
    setSearch,

    sort,
    setSort,
  };
};
