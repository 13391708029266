import { createPortal } from 'react-dom';

import { useNode } from '../../hooks';

type DynamicTopPanelProps = {
  children: React.ReactNode;
};

export const DynamicTopPanel: React.FC<DynamicTopPanelProps> = ({
  children,
}) => {
  const [container] = useNode('#dynamic-top-panel');

  return <>{container ? createPortal(children, container) : children}</>;
};
