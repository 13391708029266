/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignPermissionRequest } from '../models/AssignPermissionRequest';
import type { PermissionDto } from '../models/PermissionDto';
import type { RoleDto } from '../models/RoleDto';
import type { RoleRequest } from '../models/RoleRequest';
import type { UpdateRolePermissionsRequest } from '../models/UpdateRolePermissionsRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoleManagementApiService {
    /**
     * @param roleId
     * @param requestBody
     * @returns RoleDto OK
     * @throws ApiError
     */
    public static updateRole(
        roleId: string,
        requestBody: RoleRequest,
    ): CancelablePromise<RoleDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/management/roles/{roleId}',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns RoleDto OK
     * @throws ApiError
     */
    public static getAllRoles(): CancelablePromise<Array<RoleDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/roles',
        });
    }
    /**
     * @param requestBody
     * @returns RoleDto OK
     * @throws ApiError
     */
    public static createRole(
        requestBody: RoleRequest,
    ): CancelablePromise<RoleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/roles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param roleId
     * @returns PermissionDto OK
     * @throws ApiError
     */
    public static getRolePermissions(
        roleId: string,
    ): CancelablePromise<Array<PermissionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/roles/{roleId}/permissions',
            path: {
                'roleId': roleId,
            },
        });
    }
    /**
     * @param roleId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static assignPermissionToRole(
        roleId: string,
        requestBody: AssignPermissionRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/roles/{roleId}/permissions',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update permissions for a role
     * Updates the permissions for a specified role.
     * @param roleId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updatePermissions(
        roleId: string,
        requestBody: UpdateRolePermissionsRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/management/roles/{roleId}/permissions',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input data`,
                404: `Role or permissions not found`,
            },
        });
    }
    /**
     * Duplicate a role
     * Creates a duplicate of an existing role including its permissions. The duplicated role will have a '[Copy]' prefix added to its name.
     * @param roleId
     * @returns RoleDto Role duplicated successfully
     * @throws ApiError
     */
    public static duplicateRole(
        roleId: string,
    ): CancelablePromise<RoleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/roles/{roleId}/duplicate',
            path: {
                'roleId': roleId,
            },
            errors: {
                404: `Role not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param roleId
     * @param permissionId
     * @returns any OK
     * @throws ApiError
     */
    public static removePermissionFromRole(
        roleId: string,
        permissionId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/roles/{roleId}/permissions/{permissionId}',
            path: {
                'roleId': roleId,
                'permissionId': permissionId,
            },
        });
    }
    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRole(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/roles/{id}',
            path: {
                'id': id,
            },
        });
    }
}
