import { useRequest } from 'use-react-api-request';

import { getManagementSettings } from '../../../api';

export const useManagementSettings = () => {
  const [handleGetManagementSettings, loading] = useRequest(
    async (_token, name: string) => await getManagementSettings(name),
    {
      deps: [],
    }
  );

  return { handleGetManagementSettings, loading };
};
