import React from 'react';
import clsx from 'clsx';

export type TextVariant = 'primary' | 'secondary' | 'error';

export type TextProps<T extends React.ElementType = 'p'> = {
  as?: T;
  children: React.ReactNode;
  variant?: TextVariant;
  className?: string;
};

export const Text = <T extends React.ElementType = 'p'>({
  as,
  children,
  variant = 'primary',
  className = 'font-medium',
  ...rest
}: TextProps<T>) => {
  const Component = as || 'p';

  const variantClass: Record<TextVariant, string> = {
    primary: 'text-main text-gray-800',
    secondary: 'text-gray-500',
    error: 'text-red-600',
  };

  return (
    <Component className={clsx(variantClass[variant], className)} {...rest}>
      {children}
    </Component>
  );
};
