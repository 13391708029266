import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { notify } from '../../../app';
import { languages } from '../../../utilities';
import { useAuth, useModal } from '../../../hooks';
import { getAgent, createAgent } from '../../../api';
import { useRequest, useModels } from '../../../hooks';
import { useManagementSettings } from './useManagementSettings';
import { formatAgentToForm, formatFormToAgent } from '../../../utilities';
import { useAgentWidgetSettings } from '../../agent/hooks/useWidgetSettings';
import type { AgentForm } from '../../../utilities';
import type { ManagementSettingsType } from '../../../api';
import { useModelsById } from '../../../components/AgentForm/utils/getModelById';
import { useSyncedModelSettings } from '../../agent/hooks/useSyncedModelSettings';

const masterAgentId =
  process.env.REACT_APP_MASTER_AGENT_ID ||
  '85c6b483-2ab0-4e36-8207-e0a3c6ac1aad';

export const DEFAULT_SPEECHMATIC_VOCAB =
  '[{"content":"hello", "sounds_like": ["hallo", "hello"]}]';

const defaultValues: AgentForm = {
  active: true,
  agent_name: '',
  temperature: 0.3,
  llm_primary_model_id: '',
  stt_primary_model_id: '',
  tts_primary_model_id: '',
  llm_turn_detector_model_id: '',
  speechmatics_max_delay: 0.7,
  turn_detector: false,
  speechmatics_additional_vocab: DEFAULT_SPEECHMATIC_VOCAB,
  agent_master_id: masterAgentId,
  turn_detector_debug_mode: false,
  language: 'en' as AgentForm['language'],
};

export const useNewAgent = () => {
  const history = useHistory();
  const [formId, setFormId] = useState(1);
  const { isOpened, onClose, onOpen } = useModal();
  const { organization_id } = useAuth();
  const { llmModels, ttsModels, sttModels, loadingModels } = useModels();
  const {
    handleUpdateWidgetSettings,
    setWidgetData,
    data: widgetData,
  } = useAgentWidgetSettings();
  const { handleGetManagementSettings } = useManagementSettings();

  const [form, setForm] = useState<AgentForm | null>(defaultValues);

  const {
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
    validity,
  } = useModelsById({
    form,
    ttsModels,
    sttModels,
    llmModels,
  });

  const [handleCopyFromAgent, copying] = useRequest(
    (_token, id: string) => getAgent(id),
    {
      deps: [organization_id],
      onSuccess: copyFromAgent => {
        setFormId(f => f + 1);
        setForm(old => ({
          ...formatAgentToForm(copyFromAgent),
          organization_id,
          id: old?.id ?? '',
          title: old?.title ?? '',
        }));
        onClose();
      },
    }
  );

  const [handleCreateAgent, adding] = useRequest(
    async () => {
      if (!form) return null;

      const newAgent = await createAgent({
        ...(formatFormToAgent(form) as any),
        agent_master_id: masterAgentId,
      });

      if (!newAgent) {
        notify.info('No changes to save');
        return null;
      }

      notify.success('Agent created');

      if (widgetData && Object.keys(widgetData).length) {
        try {
          await handleUpdateWidgetSettings(newAgent.id as string, {
            widgetSettings: { ...widgetData },
          });
        } catch (error) {
          notify.error('Failed to update widget settings');
        }
      }

      history.push('/agents');
      return newAgent;
    },
    {
      deps: [form, widgetData],
      onError: () => notify.error('Failed to create agent'),
    }
  );

  const handleCopy = (id: string) => handleCopyFromAgent(id);

  useEffect(() => {
    async function fetchData() {
      try {
        const defaultSettings = (await handleGetManagementSettings(
          'defaultAgentModels'
        )) as ManagementSettingsType;

        const {
          llmPrimaryModel = '',
          llmBackupModel = '',
          ttsPrimaryModel = '',
          ttsBackupModel = '',
          sttBackupModel = '',
          sttPrimaryModel = '',
          llmTurnDetectorModel = '',
        } = defaultSettings || {};

        const initialValues = {
          ...defaultValues,
          llm_primary_model_id: llmPrimaryModel,
          llm_backup_model_id: llmBackupModel,
          stt_primary_model_id: sttPrimaryModel,
          stt_backup_model_id: sttBackupModel,
          tts_primary_model_id: ttsPrimaryModel,
          tts_backup_model_id: ttsBackupModel,
          llm_turn_detector_model_id: llmTurnDetectorModel,
        };

        setForm(initialValues);
        setFormId(f => f + 1);
      } catch {
        notify.error('Failed to recrieve management settings');
      }
    }

    fetchData();
  }, [handleGetManagementSettings]);

  useSyncedModelSettings({
    form,
    data: null,
    setForm,
    setFormId,
    models: {
      ttsPrimary,
      ttsBackup,
      sttPrimary,
      sttBackup,
      llmPrimary,
      llmBackup,
      turnDetector,
    },
  });

  return {
    showCopyModal: isOpened,
    openCopyModal: onOpen,
    closeCopyModal: onClose,

    form,
    formId: String(formId),

    copying,
    loading: loadingModels,
    adding,
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
    validity,
    onChange: setForm,
    onCopy: handleCopy,
    onSubmit: handleCreateAgent,
    setWidgetData,
    widgetData,
    setFormId,
    languages,
    llmModels: llmModels || [],
    ttsModels: ttsModels || [],
    sttModels: sttModels || [],
  };
};
