/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PermissionCreateRequest } from '../models/PermissionCreateRequest';
import type { PermissionDto } from '../models/PermissionDto';
import type { UpdatePermissionRequest } from '../models/UpdatePermissionRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PermissionManagementApiService {
    /**
     * Update a permission's description
     * Allows updating only the description of a permission.
     * @param permissionId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updatePermissionDescription(
        permissionId: string,
        requestBody: UpdatePermissionRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/management/permissions/{permissionId}',
            path: {
                'permissionId': permissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Permission not found`,
            },
        });
    }
    /**
     * Get all permissions
     * Fetches a list of all available permissions in the system.
     * @returns PermissionDto List of permissions retrieved successfully
     * @throws ApiError
     */
    public static getAllPermissions(): CancelablePromise<Array<PermissionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/management/permissions',
        });
    }
    /**
     * Create a new permission
     * Allows creating a new permission by specifying its name and optional description.
     * @param requestBody
     * @returns PermissionDto Permission created successfully
     * @throws ApiError
     */
    public static createPermission(
        requestBody: PermissionCreateRequest,
    ): CancelablePromise<PermissionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/management/permissions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input data`,
            },
        });
    }
    /**
     * Delete a permission
     * Deletes a permission by its unique identifier (UUID).
     * @param id
     * @param force
     * @returns void
     * @throws ApiError
     */
    public static deletePermission(
        id: string,
        force: boolean = false,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/management/permissions/{id}',
            path: {
                'id': id,
            },
            query: {
                'force': force,
            },
            errors: {
                404: `Permission not found`,
            },
        });
    }
}
