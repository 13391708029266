/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AddMessageRequest = {
    messageType?: AddMessageRequest.messageType;
    timestamp: string;
    conversation_id: string;
    author_identifier: string;
    message_text: string;
    author_type: string;
};
export namespace AddMessageRequest {
    export enum messageType {
        TEXT = 'TEXT',
        VOICE = 'VOICE',
        INVISIBLE = 'INVISIBLE',
        TEXT_INTERRUPTED = 'TEXT_INTERRUPTED',
        VOICE_INTERRUPTED = 'VOICE_INTERRUPTED',
    }
}

