import clsx from 'clsx';
import { Link } from '../Link';

type BreadcrumbsProps = {
  title: string;
  subtitle: string;
  subtitleUrl: string;
  className?: string;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  title,
  className,
  subtitle,
  subtitleUrl,
}) => {
  return (
    <div
      className={clsx(
        className,
        'flex flex-row items-center gap-x-xs text-small'
      )}
    >
      <Link
        url={subtitleUrl}
        withDecoration
        defaultClassName="text-secondary cursor-pointer pr-xs pl-0"
      >
        {subtitle}
      </Link>
      <span>/</span>
      <Link
        url="#"
        withDecoration
        defaultClassName="text-main cursor-pointer font-medium px-xs"
      >
        {title}
      </Link>
    </div>
  );
};
