import React, { useState } from 'react';
import clsx from 'clsx';

type TabsProps<T extends string = string> = {
  tabs: TabType<T>[];
  className?: string;
  tabClassName?: string;
  tabsClassName?: string;
  contentClassName?: string;
  activeTab?: string;
  keepMounted?: boolean;
  defaultActiveTab?: string;
  onChange?: (newTab: TabType<T>['name']) => void;
  children?: React.ReactNode | React.ReactNode[];
};

type TabProps = {
  name: string;
  children?: React.ReactNode;
};

export const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

export const Tabs = <T extends string = string>({
  activeTab: value,
  defaultActiveTab,
  tabs,
  onChange,
  children,
  className,
  keepMounted = false,
  tabClassName,
  tabsClassName,
  contentClassName,
}: TabsProps<T>) => {
  const [localActiveTab, setActiveTab] = useState(
    defaultActiveTab ?? tabs?.[0]?.name
  );
  const activeTab = value ?? localActiveTab;

  const handleChangeTab = (newTab: T) => {
    onChange?.(newTab);
    setActiveTab(newTab);
  };

  const Content = !keepMounted
    ? React.Children.toArray(children)
        .map(c => c as React.ReactElement<TabProps>)
        .filter(c => c?.type === Tab && c.props.name === activeTab)
        .map((c, index) => (
          <React.Fragment key={c.props.name ?? index}>{c}</React.Fragment>
        ))
    : React.Children.toArray(children)
        .map(c => c as React.ReactElement<TabProps>)
        .map((c, index) => (
          <div
            key={c.props.name ?? index}
            className={c.props.name === activeTab ? 'block' : 'hidden'}
          >
            {c}
          </div>
        ));

  return (
    <div className={clsx('flex flex-col', className)}>
      <div
        className={clsx(
          tabsClassName,
          'flex gap-x-md border-b border-solid border-border'
        )}
      >
        {tabs.map((tab, index) => (
          <div
            key={`${tab.name}-${index}`}
            onClick={() => handleChangeTab(tab.name)}
            className={clsx(
              tabClassName,
              'cursor-pointer transition-def',
              'py-sm px-md text-medium',
              activeTab === tab.name
                ? 'font-medium border-b-2 border-solid border-main'
                : 'font-normal'
            )}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={contentClassName}>{Content}</div>
    </div>
  );
};
