export const Close = () => {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 14 16"
      className="w-6 h-6 text-mainText absolute top-4 right-4"
    >
      <path
        fillRule="evenodd"
        fill="currentColor"
        d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
      ></path>
    </svg>
  );
};
