import React, { useMemo, useState } from 'react';

import { useFetch } from '../../hooks';
import { Copy } from '../../components';
import { getShortAgents } from '../../api';
import { Button, Dropdown, Modal } from '../../components';

type CopyFromProps = {
  showCopyModal: boolean;
  openCopyModal: () => void;
  closeCopyModal: () => void;

  copying: boolean;
  onCopy: (id: string) => void;
};

type CopyFromListProps = Pick<CopyFromProps, 'copying' | 'onCopy'> & {
  onClose: () => void;
};

const CopyFromList: React.FC<CopyFromListProps> = ({
  copying,
  onCopy,
  onClose,
}) => {
  const [value, setValue] = useState('');

  const { loading, data: agents } = useFetch(() => getShortAgents(), []);
  const options = useMemo<OptionType[]>(() => {
    return (agents || [])
      .map(a => ({
        value: a.id ?? '',
        label: a.title ?? '',
        // label: `${a.title}: ${a.agent_name}`,
      }))
      .filter(({ value }) => !!value);
  }, [agents]);

  return (
    <>
      <Dropdown
        value={value}
        options={options}
        onChange={setValue}
        disabled={loading || copying}
      />
      <div className="flex flex-col gap-y-md justify-center items-center mt-lg">
        <Button
          label="Copy"
          size="large"
          className="w-full"
          loading={loading}
          onClick={() => onCopy(value)}
          disabled={!value.length || loading || copying}
        />
        <Button
          size="large"
          type="light"
          label="Cancel"
          className="w-full"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export const CopyFromAgent: React.FC<CopyFromProps> = ({
  copying,
  showCopyModal,

  onCopy,
  openCopyModal,
  closeCopyModal,
}) => {
  return (
    <>
      <Button
        size="medium"
        type="contrast"
        icon={<Copy />}
        label="Copy From"
        onClick={openCopyModal}
      />
      <Modal
        size="small"
        open={showCopyModal}
        onClose={closeCopyModal}
        title="Choose agent to copy from"
      >
        <CopyFromList
          key={String(showCopyModal)}
          onCopy={onCopy}
          copying={copying}
          onClose={closeCopyModal}
        />
      </Modal>
    </>
  );
};
