const formatSnakeCase = (str: string) =>
  str
    .trim()
    .replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase())
    .replace(/^\w/, char => char.toLowerCase());

const formatTitleCase = (str: string) =>
  str.replace(/\b\w/g, char => char.toUpperCase());

export { formatSnakeCase, formatTitleCase };
