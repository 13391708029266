import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { Input } from '../Input';
import { EditorModal } from '..';

type EditableInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  multiline?: boolean;
  label?: string;
  disabled?: boolean;
};

export const EditableInput: React.FC<EditableInputProps> = ({
  value = '',
  onChange,
  disabled,
  multiline = false,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string>(value);

  const handleChanges = (value: string) => {
    setEditorContent(value);
    onChange?.(value);
  };

  const handleSave = (content: string) => {
    handleChanges(content);
    setIsEditing(false);
  };

  return (
    <div className="relative w-full">
      {!disabled && (
        <IconButton
          onClick={() => setIsEditing(true)}
          className="absolute -left-16 top-4 px-3 py-2 text-main border border-[#e5e7eb] border-solid rounded shadow-sm hover:bg-gray-100  focus:outline-none focus:ring-2"
        >
          <EditIcon />
        </IconButton>
      )}
      <Input
        value={editorContent}
        onChange={handleChanges}
        disabled={disabled}
        multiline={multiline}
      />
      {isEditing && (
        <EditorModal
          initialContent={editorContent}
          onClose={() => setIsEditing(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};
