import type { TaskStatusKey } from '../../../api/scraping';

export enum ScrapingStatus {
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  IN_PROCESS = 'IN_PROCESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  REVOKED = 'REVOKED',
  RETRY = 'RETRY',
  STARTED = 'STARTED',
  UNKNOWN = 'UNKNOWN',
}

export enum OutputMode {
  FLAT_FILE = 'FLAT_FILE',
  ARCHIVE = 'ARCHIVE',
}

export enum ScrapingDepthType {
  ONE_PAGE = 'ONE_PAGE',
  ALL_PAGES = 'ALL_PAGES',
}

export const outputModeOptions: Array<OptionType> = [
  { label: 'Flat File', value: OutputMode.FLAT_FILE },
  { label: 'Archive', value: OutputMode.ARCHIVE },
];

export const depthTypeOptions: Array<OptionType> = [
  { label: 'One page only', value: ScrapingDepthType.ONE_PAGE },
  { label: 'All pages on the site', value: ScrapingDepthType.ALL_PAGES },
];

export const sliderStepOptions = ['0', '1', '2', '3', '4', '5', '∞'];

export const filterStatusOptions: Array<
  OptionType<TaskStatusKey | 'IN_PROCESS' | ''>
> = [
  { value: '', label: 'All' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'RETRY', label: 'Retry' },
  { value: 'PENDING', label: 'In progress' },
  { value: 'FAILURE', label: 'Failed' },
];
