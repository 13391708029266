import { message_type } from './const';
import type { MessageResponse } from '../../api';

export const filterMessagesWithInterruptions = (
  messages: MessageResponse[],
  showInterruptions: boolean
): MessageResponse[] => {
  const groupedByTimestamp = new Map<string, MessageResponse[]>();

  for (const msg of messages) {
    const group = groupedByTimestamp.get(msg.timestamp!) || [];
    group.push(msg);
    groupedByTimestamp.set(msg.timestamp!, group);
  }

  const filtered: MessageResponse[] = [];

  groupedByTimestamp.forEach(group => {
    if (group.length === 1) {
      filtered.push(group[0]);
      return;
    }

    const interrupted = group.find(
      m => m.message_type === message_type.VOICE_INTERRUPTED
    );
    const full = group.find(m => m.message_type === message_type.VOICE);

    if (showInterruptions && interrupted) {
      filtered.push(interrupted);
    } else if (!showInterruptions && full) {
      filtered.push(full);
    }
  });

  return filtered.sort(
    (a, b) =>
      new Date(a.timestamp!).getTime() - new Date(b.timestamp!).getTime()
  );
};
