const sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl;

export const normalizeLink = (url: string) => {
  const sanitizedUrl = sanitizeUrl(url);

  try {
    const withProtocol = !sanitizedUrl.includes('://')
      ? `https://${sanitizedUrl}`
      : sanitizedUrl;

    return new URL(withProtocol).toString();
  } catch {
    return '';
  }
};
