/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentDto } from '../models/AgentDto';
import type { AgentWidgetSettingsRequest } from '../models/AgentWidgetSettingsRequest';
import type { AgentWidgetSettingsResponse } from '../models/AgentWidgetSettingsResponse';
import type { BaseAgentRequest } from '../models/BaseAgentRequest';
import type { CopyAgentRequest } from '../models/CopyAgentRequest';
import type { JsonNode } from '../models/JsonNode';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AgentApiService {
    /**
     * Get an agent by ID
     * Fetches the details of an agent by its unique identifier.
     * @param xAiAgentOrganizationContext
     * @param agentId
     * @returns AgentDto Agent retrieved successfully
     * @throws ApiError
     */
    public static getAgentById(
        xAiAgentOrganizationContext: string,
        agentId: string,
    ): CancelablePromise<AgentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            errors: {
                400: `Invalid agent ID`,
                404: `Agent not found`,
            },
        });
    }
    /**
     * Update an agent by ID
     * Updates an agent's details. Only fields provided in the request will be updated.
     * @param agentId
     * @param xAiAgentOrganizationContext
     * @param requestBody
     * @returns AgentDto Agent updated successfully
     * @throws ApiError
     */
    public static updateAgent(
        agentId: string,
        xAiAgentOrganizationContext: string,
        requestBody: BaseAgentRequest,
    ): CancelablePromise<AgentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/agents/{agentId}',
            path: {
                'agentId': agentId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid agent ID or input data`,
                404: `Agent not found`,
            },
        });
    }
    /**
     * Gets agent widget settings
     * @param agentId
     * @param xAiAgentOrganizationContext
     * @returns AgentWidgetSettingsResponse Successful response
     * @throws ApiError
     */
    public static updateWidgetSettings(
        agentId: string,
        xAiAgentOrganizationContext: string,
    ): CancelablePromise<AgentWidgetSettingsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agents/{agentId}/widget-settings',
            path: {
                'agentId': agentId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Updates agentwidget settings
     * @param agentId
     * @param xAiAgentOrganizationContext
     * @param requestBody
     * @returns AgentWidgetSettingsResponse Successful response
     * @throws ApiError
     */
    public static updateWidgetSettings1(
        agentId: string,
        xAiAgentOrganizationContext: string,
        requestBody: AgentWidgetSettingsRequest,
    ): CancelablePromise<AgentWidgetSettingsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/agents/{agentId}/widget-settings',
            path: {
                'agentId': agentId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param agentId
     * @param organizationId
     * @returns AgentDto OK
     * @throws ApiError
     */
    public static changeOrganization(
        agentId: string,
        organizationId: string,
    ): CancelablePromise<AgentDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/agents/{agentId}/organization/{organizationId}',
            path: {
                'agentId': agentId,
                'organizationId': organizationId,
            },
        });
    }
    /**
     * @param xAiAgentOrganizationContext
     * @param agentId
     * @param requestBody
     * @returns JsonNode OK
     * @throws ApiError
     */
    public static updateConversationFlow(
        xAiAgentOrganizationContext: string,
        agentId: string,
        requestBody: JsonNode,
    ): CancelablePromise<JsonNode> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/agents/{agentId}/conversation-flow',
            path: {
                'agentId': agentId,
            },
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all agents
     * Fetches a list of all agents, including their settings, widget settings, and other details.
     * @param xAiAgentOrganizationContext
     * @returns AgentDto List of agents retrieved successfully
     * @throws ApiError
     */
    public static getAgents(
        xAiAgentOrganizationContext: string,
    ): CancelablePromise<Array<AgentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agents',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * Create a new agent
     * Creates a new agent with the required fields: agentMasterId, title, agentName, and description. Optional fields can also be provided.
     * @param xAiAgentOrganizationContext
     * @param requestBody
     * @returns AgentDto Agent created successfully
     * @throws ApiError
     */
    public static createAgent(
        xAiAgentOrganizationContext: string,
        requestBody: BaseAgentRequest,
    ): CancelablePromise<AgentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agents',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input data`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param agentId
     * @param requestBody
     * @returns AgentDto OK
     * @throws ApiError
     */
    public static copyAgent(
        agentId: string,
        requestBody: CopyAgentRequest,
    ): CancelablePromise<AgentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/agents/{agentId}/copy',
            path: {
                'agentId': agentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all short representation of agents
     * Fetches a list of short representation agents
     * @param xAiAgentOrganizationContext
     * @returns AgentDto List of agents retrieved successfully
     * @throws ApiError
     */
    public static getShortAgents(
        xAiAgentOrganizationContext: string,
    ): CancelablePromise<Array<AgentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/agents/short',
            headers: {
                'x-ai-agent-organization-context': xAiAgentOrganizationContext,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
}
