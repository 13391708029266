import { UserApiService } from '../services';
import { UserManagementApiService } from '../services';
import { RoleManagementApiService } from '../services';
import { PermissionManagementApiService } from '../services';
import { OrganizationManagementApiService } from '../services';
import type { UserRequest } from '../services';
import type { PageItemUserDto } from '../services';
import type { OrganizationDto } from '../services';
import type { AssignRoleRequest } from '../services';
import type { RoleDto, RoleRequest } from '../services';
import type { PermissionCreateRequest } from '../services';
import type { OrganizationCreateRequest } from '../services';

export const updatePermissions = (roleId: string, permissionIds: string[]) => {
  return RoleManagementApiService.updatePermissions(roleId, { permissionIds });
};
export const getPermissionsList = () => {
  return PermissionManagementApiService.getAllPermissions();
};
export const addPermission = (permission: PermissionCreateRequest) => {
  return PermissionManagementApiService.createPermission(permission);
};
export const updatePermission = (
  permissionId: string,
  permission: PermissionCreateRequest
) => {
  return PermissionManagementApiService.updatePermissionDescription(
    permissionId,
    permission
  );
};
export const deletePermission = (permissionId: string, force = true) => {
  return PermissionManagementApiService.deletePermission(permissionId, force);
};

export const getRoles = (): Promise<Array<RoleDto>> => {
  return RoleManagementApiService.getAllRoles();
};
export const addRole = (values: RoleRequest) => {
  return RoleManagementApiService.createRole(values);
};
export const updateRole = (roleId: string, values: RoleRequest) => {
  return RoleManagementApiService.updateRole(roleId, values);
};
export const duplicateRole = (id: string) => {
  return RoleManagementApiService.duplicateRole(id);
};
export const deleteRole = (id: string) => {
  return RoleManagementApiService.deleteRole(id);
};
export const removePermissionFromRole = (
  roleId: string,
  permissionId: string
) => {
  return RoleManagementApiService.removePermissionFromRole(
    roleId,
    permissionId
  );
};

export const getUserOrgs = async () => {
  return UserApiService.getAffiliatedOrganizations();
};

export const getAllUsers = async (
  page?: number,
  pageSize?: number,
  sort?: SortState<string>
) => {
  return UserManagementApiService.getUsers(
    undefined,
    page,
    pageSize,
    sort?.name ? [`${sort.name},${sort.direction}`] : []
  );
};
export const getOrgUsers = async (
  organizationId: string,
  page?: number,
  pageSize?: number,
  sort?: SortState<string>
) => {
  return UserManagementApiService.getUsers(
    organizationId,
    page,
    pageSize,
    sort?.name ? [`${sort.name},${sort.direction}`] : []
  );
};
export const addUser = async (request: UserRequest) => {
  return UserManagementApiService.createUser(request);
};
export const inviteUser = async (userId: string) => {
  return UserManagementApiService.inviteUser(userId);
};
export const assignUserToRole = (payload: AssignUserToRolePayload) => {
  return OrganizationManagementApiService.assignUserRole(
    payload.organizationId,
    { userId: payload.userId, roleId: payload.roleId }
  );
};
export const unassignUserFromOrg = (payload: UnassignUserFromOrgPayload) => {
  return OrganizationManagementApiService.unassignUserFromOrganization(
    payload.organizationId,
    payload.userId
  );
};
export const unassignUserFromRole = (payload: UnassignUserFromRolePayload) => {
  return OrganizationManagementApiService.unassignUserRole(
    payload.organizationId,
    payload
  );
};
export const addUserToOrganization = (
  organizationId: string,
  userId: string
) => {
  return OrganizationManagementApiService.addUserToOrganization(
    organizationId,
    userId
  );
};

export const getOrganizations = () => {
  return OrganizationManagementApiService.getAllOrganizations();
};
export const deleteOrganization = (id: string) => {
  return OrganizationManagementApiService.deleteOrganization(id);
};
export const addOrganization = (value: OrganizationCreatePayload) => {
  return OrganizationManagementApiService.createOrganization(value);
};
export const editOrganization = (
  id: string,
  value: OrganizationCreatePayload
) => {
  return OrganizationManagementApiService.updateOrganization(id, value);
};
export const getOrganization = (id: string) => {
  return OrganizationManagementApiService.getOrganizationById(id);
};

export type { RoleDto, RoleRequest, PermissionCreateRequest };
export type { PermissionDto, UserOrganizationDto } from '../services';
export type UserFormType = UserRequest;
export type UserListType = PageItemUserDto;
export type OrganizationType = OrganizationDto;
export type OrganizationCreatePayload = OrganizationCreateRequest;
export type AssignUserToRolePayload = AssignRoleRequest & {
  organizationId: string;
};
export type UnassignUserFromOrgPayload = {
  userId: string;
  organizationId: string;
};
export type UnassignUserFromRolePayload = {
  userId: string;
  roleId: string;
  organizationId: string;
};
