import clsx from 'clsx';
import { Card } from '../Card';

export type EmptyProps = {
  message?: string;
  className?: string;
  withBorder?: boolean;
  heightClassName?: string;
  minWidth?: boolean;
};

const EmptyComponent: React.FC<EmptyProps> = ({
  message,
  className,
  minWidth = true,
  heightClassName,
  withBorder = true,
}) => {
  return (
    <Card
      className={heightClassName}
      size={minWidth ? undefined : 'unset'}
      border={withBorder ? undefined : false}
    >
      <div className={clsx('text-mainText text-medium', className)}>
        {message}
      </div>
    </Card>
  );
};

export const Empty = EmptyComponent;
