import React from 'react';

type CheckIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const CheckIcon: React.FC<CheckIconProps> = ({
  color = '#5861FA',
  width = 14,
  height = 12,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.541 0.313509L4.18531 8.20732L1.96802 5.8883C1.55958 5.51131 0.91773 5.48847 0.450933 5.80833C-0.00419433 6.13962 -0.132564 6.72223 0.147514 7.1906L2.77325 11.3717C3.02999 11.7601 3.47344 12 3.97525 12C4.45372 12 4.90884 11.7601 5.16558 11.3717C5.5857 10.8348 13.6029 1.47873 13.6029 1.47873C14.6532 0.427747 13.3812 -0.497576 12.541 0.302086V0.313509Z"
      fill={color}
    />
  </svg>
);
