import { Form, FormItem, Dropdown, Input } from '../../../../components';
import { SearchIcon } from '../../../../components/SearchBar/SearchIcon';
import { filterStatusOptions } from '../const';

type FiltersEnum = 'status' | 'url';

type FilterProps = {
  values: Record<FiltersEnum, any>;
  onChange: (newValues: Record<FiltersEnum, any>) => void;
};

export const ScraperFilter: React.FC<FilterProps> = ({ values, onChange }) => {
  return (
    <Form initialValue={values} onChange={onChange}>
      <FormItem inline={false} name="url" label="URL">
        <Input
          placeholder="Search"
          inputClassName="pl-20"
          prefix={<SearchIcon />}
        />
      </FormItem>
      <FormItem inline={false} label="Filter by Status" name="status">
        <Dropdown options={filterStatusOptions} />
      </FormItem>
    </Form>
  );
};
