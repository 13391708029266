import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button, Spinner } from '../../../../components';
import { ScrapingStatus } from '../const';

type ScrapingActionsProps = {
  status: string;
  onRetry: () => void;
  onDownload: () => void;
  onViewErrors: () => void;
  onDelete?: () => void;
};

const ScrapingActions: React.FC<ScrapingActionsProps> = ({
  status,
  onRetry,
  onDownload,
  onViewErrors,
  onDelete,
}) => {
  switch (status) {
    case ScrapingStatus.IN_PROCESS:
    case ScrapingStatus.STARTED:
      return (
        <div className="flex items-center gap-2">
          <Spinner className="h-10 w-10" />
          <Button
            onClick={onDelete}
            size="small"
            className="px-2"
            type="contrast"
            icon={<DeleteIcon className="h-6 w-6 text-buttonPrimary" />}
          />
        </div>
      );

    case ScrapingStatus.SUCCESS:
      return (
        <Button
          label="Download"
          onClick={onDownload}
          type="contrast"
          size="small"
          className="px-4"
          icon={<DownloadIcon className="h-4 w-4" />}
        />
      );

    case ScrapingStatus.FAILURE:
      return (
        <div className="flex gap-2">
          <Button
            label="Errors"
            onClick={onViewErrors}
            size="small"
            type="contrast"
            className="px-4"
          />
          <Button
            label="Retry"
            onClick={onRetry}
            size="small"
            className="px-4"
          />
        </div>
      );
    case ScrapingStatus.UNKNOWN:
    default:
      return null;
  }
};

export default ScrapingActions;
