import { useEffect } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { useUserSettings } from '../../shared';
import { CollapserIcon } from './CollapserIcon';

type CardSize = 'small' | 'medium' | 'unset';

type CardProps = {
  name?: string;
  title?: string;
  subtitle?: string;

  size?: CardSize;
  border?: string | boolean;
  children: React.ReactNode;
  actions?: React.ReactNode[];

  collapsable?: boolean;
  defaultCollapsed?: boolean;
  withOverflow?: boolean;

  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
};

const sizes: { [x in CardSize]: string } = {
  unset: '',
  small: 'w-[20rem]',
  medium: 'w-[48rem]',
};

export const Card: React.FC<CardProps> = ({
  name,
  title,
  subtitle,
  children,
  size = 'medium',
  collapsable = false,
  defaultCollapsed = true,
  border = 'border shadow-sm',

  className,
  headerClassName,
  contentClassName,

  actions,
  withOverflow = true,
  disabled,
}) => {
  const [collapsed, setCollapsed] = useUserSettings(
    collapsable ? defaultCollapsed : false,
    name
  );
  const hasTitle = !!title?.length || !!subtitle?.length || actions?.length;

  useEffect(() => {
    if (disabled && collapsable) {
      setCollapsed(true);
    }
  }, [disabled, collapsable, setCollapsed]);

  return (
    <div
      className={twMerge(
        clsx(
          border,
          sizes[size],
          withOverflow ? 'max-h-screen' : '',
          disabled && 'opacity-50 pointer-events-none select-none'
        ),
        'flex flex-col bg-contrast rounded-card p-lg',
        className
      )}
    >
      {hasTitle && (
        <div
          onClick={
            collapsable && !disabled ? () => setCollapsed(c => !c) : undefined
          }
          className={clsx(
            headerClassName,
            'flex justify-between items-center',
            collapsable ? 'cursor-pointer' : ''
          )}
        >
          <div className="flex flex-col gap-y-sm">
            {!!title?.length && (
              <div className="text-big font-semibold text-mainText">
                {title}
              </div>
            )}
            {!!subtitle?.length && (
              <div className="text-small font-normal text-secondary">
                {subtitle}
              </div>
            )}
          </div>

          <div
            className="shrink-0 flex gap-x-md items-center"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {actions}
            {collapsable && (
              <CollapserIcon
                show={!collapsed}
                onClick={
                  collapsable && !disabled
                    ? () => setCollapsed(c => !c)
                    : undefined
                }
              />
            )}
          </div>
        </div>
      )}

      <div
        className={clsx(
          contentClassName,
          'flex-grow',
          hasTitle ? 'mt-sm' : '',
          collapsed ? 'hidden' : '',
          withOverflow ? 'overflow-y-auto' : ''
        )}
      >
        {children}
      </div>
    </div>
  );
};
