/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MessageDto = {
    id?: string;
    timestamp?: string;
    conversation_id?: string;
    author_identifier?: string;
    message_type?: MessageDto.message_type;
    message_text?: string;
    author_type?: string;
};
export namespace MessageDto {
    export enum message_type {
        TEXT = 'TEXT',
        VOICE = 'VOICE',
        INVISIBLE = 'INVISIBLE',
        TEXT_INTERRUPTED = 'TEXT_INTERRUPTED',
        VOICE_INTERRUPTED = 'VOICE_INTERRUPTED',
    }
}

