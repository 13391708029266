import { useCallback } from 'react';

import {
  Tabs,
  Tab,
  WidgetSettingsBuilder,
  Breadcrumbs,
  Button,
  DynamicTopPanel,
  SubmitButton,
  Form,
} from '../../components';
import NewAgentPage from './NewAgentFormTab';
import { useNewAgent } from './hooks/useNewAgent';
import { useCheckPermissions } from '../../shared';
import { useFormSchema } from '../agent/hooks/useFormSchema';
import type { AgentForm } from '../../utilities';
import type { FormJsonData } from '../../api/form-controller';

type TabsEnum = 'newAgent' | 'widgetFormSettings';

const NewAgentPageTabs = () => {
  const editDisabled = !useCheckPermissions(['edit_agents'])[0]?.allowed;
  const {
    form,
    onChange,
    adding,
    onSubmit,
    setWidgetData,
    widgetData,
    formId,
    setFormId,
    ttsPrimary,
    ttsBackup,
    sttPrimary,
    sttBackup,
    llmPrimary,
    llmBackup,
    turnDetector,
    validity,
    ...rest
  } = useNewAgent();
  const { data: schema, intializing } = useFormSchema('agentWidgetSettings');

  const handleFormChange = useCallback(
    (data: AgentForm) => {
      onChange(data);
    },
    [onChange]
  );

  const handleWidgetSettingsChange = useCallback(
    (data: Record<string, unknown>) => {
      const formData = {
        ...form,
        widget_settings: data ? JSON.stringify(data) : '',
      };
      setWidgetData(data);
      handleFormChange(formData as any);
    },
    [form, handleFormChange, setWidgetData]
  );

  const handleTabChange = useCallback(
    (tab: string) => {
      if (tab !== 'widgetFormSettings') {
        setFormId(f => f + 1);
      }
    },
    [setFormId]
  );

  return (
    <Form
      key={String(formId || 'NEW')}
      initialValue={form as any}
      onChange={handleFormChange}
    >
      <div className="flex gap-md flex-wrap justify-between items-center mb-md">
        <Breadcrumbs
          subtitle="Agents"
          title="New Agent"
          subtitleUrl="/agents"
        />
      </div>
      <Tabs<TabsEnum>
        tabs={[
          { name: 'newAgent', label: 'Agent' },
          { name: 'widgetFormSettings', label: 'Widget Settings' },
        ]}
        contentClassName="mt-md"
        keepMounted
        onChange={handleTabChange}
      >
        <Tab name="newAgent">
          <NewAgentPage
            form={form!}
            editDisabled={editDisabled}
            onChange={handleFormChange}
            ttsPrimary={ttsPrimary}
            ttsBackup={ttsBackup}
            sttPrimary={sttPrimary}
            sttBackup={sttBackup}
            llmPrimary={llmPrimary}
            llmBackup={llmBackup}
            turnDetector={turnDetector}
            validity={validity}
            {...rest}
          />
        </Tab>
        <Tab name="widgetFormSettings">
          <WidgetSettingsBuilder
            onChange={handleWidgetSettingsChange}
            widgetSettings={widgetData}
            schemaData={schema as FormJsonData}
            intializing={intializing}
          />
        </Tab>
      </Tabs>
      {!editDisabled && (
        <DynamicTopPanel>
          <div className="flex flex-row gap-x-md justify-center items-center">
            <Button
              label="Cancel"
              url="/agents"
              type="contrast"
              size="medium"
            />
            <SubmitButton
              label="Save"
              size="medium"
              type="primary"
              loading={adding}
              disabled={adding}
              onClick={onSubmit}
            />
          </div>
        </DynamicTopPanel>
      )}
    </Form>
  );
};

export default NewAgentPageTabs;
