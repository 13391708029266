import { useMemo, useState } from 'react';

import { Button, ConfirmModal, Modal, Tab, Tabs } from '../../../../components';
import PreprocessingList from './PreprocessingList';
import { CompletedList } from './CompletedList';
import CompressPromptForm from './CompressPromptForm';
import { useCompressor } from '../useCompressor';
import { CompressTaskForm } from './CompressTaskForm';
import type { FormProps } from './CompressTaskForm';
import { completedTypes, processingTypes } from '../utils/const';
import type { CompressingTaskType } from '../utils/const';

type TabsEnum = 'preprocessing' | 'completed';
export type TaskProps = {
  _id: string;
  task_id: string;
  task_type: CompressingTaskType;
  status: string;
  filename: string;
  user_name: string;
  created_at: string;
  updated_at: string;
};

const TextCompressorTabs = () => {
  const {
    loading,
    reloading,
    isOpened,
    onOpen,
    onClose,
    setCreateTaskForm,
    tasksList,
    openedDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    createTaskForm,
    handleCancelTask,
    handleAddTaskWithFile,
    handleAddTaskWithText,
    getTaskResultWithPromptById,
    compressingTextWithPrompt,
    getTasksList,
  } = useCompressor();

  const [isCompressPromptOpened, setIsCompressPromptOpened] = useState(false);
  const inProgress = loading || reloading;

  const handleSaveTask = (data: FormProps) => {
    const { text, file, requested_by, chunk_size } = data;
    if (file) {
      handleAddTaskWithFile({
        file: file as File,
        user_name: requested_by,
        chunk_size,
      });
    } else {
      handleAddTaskWithText({
        text: text as string,
        user_name: requested_by,
        chunk_size,
      });
    }
  };

  const processingTasks = useMemo(
    () =>
      tasksList.filter(task =>
        processingTypes.includes(task.task_type)
      ) as TaskProps[],
    [tasksList]
  );

  const completedTasks = useMemo(
    () =>
      tasksList.filter(task =>
        completedTypes.includes(task.task_type)
      ) as TaskProps[],
    [tasksList]
  );

  const handleCompressClick = ({ task_id }: { task_id: string }) => {
    setCreateTaskForm({ id: task_id });
    setIsCompressPromptOpened(true);
  };

  return (
    <div className="p-4">
      <div className="flex flex-wrap justify-end items-center mb-4">
        <Button
          label="Add Task"
          onClick={() => {
            setCreateTaskForm(undefined);
            onOpen();
          }}
          size="medium"
        />
      </div>
      <ConfirmModal
        title="Do you want to delete this task?"
        open={openedDeleteModal}
        onConfirm={handleCancelTask}
        onClose={closeDeleteModal}
      />
      <Modal size="small" open={isOpened} onClose={onClose} title="New Task">
        <CompressTaskForm
          key={'new task'}
          onCancel={onClose}
          saving={inProgress}
          onSave={handleSaveTask}
        />
      </Modal>

      <Modal
        size="medium"
        open={isCompressPromptOpened}
        onClose={() => setIsCompressPromptOpened(false)}
        title="Prompt"
      >
        <CompressPromptForm
          key={'prompt'}
          onCancel={() => setIsCompressPromptOpened(false)}
          saving={false}
          onSave={() => setIsCompressPromptOpened(false)}
          taskId={createTaskForm?.id || ''}
          type={createTaskForm?.type || ''}
        />
      </Modal>
      <Tabs<TabsEnum>
        tabsClassName="w-fit"
        tabs={[
          { name: 'preprocessing', label: 'Preprocessing' },
          { name: 'completed', label: 'In Progress & Completed' },
        ]}
        contentClassName="mt-md"
        onChange={() => getTasksList()}
      >
        <Tab name="preprocessing">
          <PreprocessingList
            processingTasks={processingTasks}
            loading={loading}
            actionLoading={compressingTextWithPrompt}
            handleCompressClick={handleCompressClick}
            openDeleteModal={openDeleteModal}
          />
        </Tab>
        <Tab name="completed">
          <CompletedList
            completedTasks={completedTasks}
            loading={loading}
            actionLoading={compressingTextWithPrompt}
            openDeleteModal={openDeleteModal}
            handleCompressClick={handleCompressClick}
            getTaskResultWithPromptById={getTaskResultWithPromptById}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default TextCompressorTabs;
