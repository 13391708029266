import React from 'react';
import { Avatar } from '@mui/material';

import { Badge, Table } from '../../../../components';
import CompressorActions from './CompressorActions';
import { formatDate, getTaskTypeLabel } from '../utils/utils';
import { statusConfig } from '../utils/const';
import type { CompressingTaskType } from '../utils/const';
import type { TaskProps } from './TextCompressorTabs';

export type CompressTaskData = {
  task_id: string;
};

export type PreprocessingListProps = {
  processingTasks: TaskProps[];
  loading: boolean;
  actionLoading: boolean;
  handleCompressClick: (data: CompressTaskData) => void;
  openDeleteModal: (id: string) => void;
};

export const PreprocessingList: React.FC<PreprocessingListProps> = ({
  processingTasks,
  loading,
  actionLoading,
  handleCompressClick,
  openDeleteModal,
}) => {
  return (
    <div className="p-4">
      <div className="overflow-x-auto">
        <Table
          data={processingTasks}
          loading={loading}
          emptyMessage="There are no tasks yet."
          config={{
            columns: [
              {
                header: 'ID',
                cellClassName: 'min-w-[5rem] max-w-[25rem] ',
                getValue: ({ task_id }) => (
                  <div className="truncate">{task_id}</div>
                ),
              },
              {
                header: 'Type',
                cellClassName: 'min-w-[6rem] text-left',
                getValue: ({ task_type }) => (
                  <p>{getTaskTypeLabel(task_type)}</p>
                ),
              },

              {
                header: 'Requested by',
                sortName: 'requested_by',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ user_name }) => (
                  <div className="flex items-center">
                    <Avatar
                      sx={{
                        bgcolor: '#3b82f6',
                        width: 28,
                        height: 28,
                        fontSize: '1.5rem',
                        mr: 1,
                      }}
                    >
                      {user_name?.[0]}
                    </Avatar>
                    <span>{user_name || 'Unknown'}</span>
                  </div>
                ),
              },
              {
                header: 'Date & Time',
                sortName: 'created_at',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ created_at }: any) => formatDate(created_at),
              },
              {
                header: 'Status',
                sortName: 'status',
                cellClassName: 'min-w-[5rem] text-left',
                getValue: ({ status }) => (
                  <Badge config={statusConfig} status={status} />
                ),
              },
              {
                header: 'Actions',
                cellClassName: 'min-w-[5rem]',
                getValue: ({ status, task_id }) => (
                  <div className="flex items-center justify-end h-full min-h-[3rem]">
                    <CompressorActions
                      loading={actionLoading}
                      status={status}
                      onCompress={() => handleCompressClick({ task_id })}
                      onDelete={() => openDeleteModal(task_id)}
                    />
                  </div>
                ),
              },
            ],
          }}
        />
      </div>
      {/* <div className="mt-sm flex justify-between items-center">
        <SearchPanel
          totalItems={pagination?.totalItems}
          page={pagination?.page}
          perPage={pagination?.perPage}
          withPaginate
          setPage={setPage}
        />
      </div> */}
    </div>
  );
};

export default PreprocessingList;
