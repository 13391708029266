import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import type { Auth0ProviderOptions } from '@auth0/auth0-react';

import Routes from '../Routes';
import { UserProvider } from '../../shared';
import { Notifications } from '../Notifications';

const rootStyle = getComputedStyle(document.documentElement);

const theme = createTheme({
  palette: {
    primary: {
      main: rootStyle.getPropertyValue('--main').trim(),
      light: rootStyle.getPropertyValue('--button-light').trim(),
      dark: rootStyle.getPropertyValue('--button-main-hover').trim(),
      contrastText: rootStyle.getPropertyValue('--contrast').trim(),
    },
    secondary: {
      main: rootStyle.getPropertyValue('--secondary').trim(),
    },
    background: {
      default: rootStyle.getPropertyValue('--background').trim(),
      paper: rootStyle.getPropertyValue('--contrast').trim(),
    },
    text: {
      primary: rootStyle.getPropertyValue('--main-text').trim(),
      secondary: rootStyle.getPropertyValue('--secondary').trim(),
    },
    error: {
      main: rootStyle.getPropertyValue('--error').trim(),
    },
    success: {
      main: rootStyle.getPropertyValue('--success').trim(),
    },
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    fontSize: 14, // Base font size (matches Tailwind root font size)
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 400,
    },
  },
});

// auth0-config.js
export const auth0Config: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENT,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
    scope: 'read:current_user update:current_user_metadata offline_access',
  },
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      <CssBaseline />
      <Auth0Provider
        useRefreshTokens
        domain={auth0Config.domain}
        cacheLocation="localstorage"
        clientId={auth0Config.clientId}
        authorizationParams={auth0Config.authorizationParams}
      >
        <UserProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </UserProvider>
      </Auth0Provider>
    </ThemeProvider>
  );
};

export default App;
