import clsx from 'clsx';
import { OpenInNewOutlined } from '@mui/icons-material';

import { SafeLink } from './SafeLink';
export { SafeLink } from './SafeLink';

type LinkProps = {
  url: string;
  title?: string;
  newTab?: boolean;
  className?: string;
  activeClassName?: string;
  defaultClassName?: string;
  label?: React.ReactNode;
  children?: React.ReactNode;

  withIcon?: boolean;
  withDecoration?: boolean;
};

export const Link: React.FC<LinkProps> = ({
  url,
  label,
  title,
  newTab,
  children,
  className,
  activeClassName,
  defaultClassName = 'cursor-pointer',

  withIcon = false,
  withDecoration = false,
}) => {
  if (!url) return null;

  const content = children ?? label;

  return (
    <SafeLink
      isNav
      to={url}
      title={title}
      newTab={newTab}
      activeClassName={activeClassName}
      className={clsx(
        defaultClassName,

        withDecoration
          ? 'border-b hover:border-main hover:text-main pr-sm border-transparent transition-default'
          : '',
        className
      )}
    >
      {withIcon ? <span className="mr-xs">{content}</span> : content}
      {!!withIcon && <OpenInNewOutlined className="mb-[0.2em]" />}
    </SafeLink>
  );
};
