import { UsersTab } from './UsersTab';
import { Tabs, Tab } from '../../components';
import { OrganizationsTab } from './OrganizationsTab';

type TabsEnum = 'organizations' | 'users';

const UsersAndOrganizationsPage = () => {
  return (
    <Tabs<TabsEnum>
      tabs={[
        { name: 'organizations', label: 'Organizations' },
        { name: 'users', label: 'Users' },
      ]}
      contentClassName="mt-md"
    >
      <Tab name="organizations">
        <OrganizationsTab />
      </Tab>
      <Tab name="users">
        <UsersTab />
      </Tab>
    </Tabs>
  );
};

export default UsersAndOrganizationsPage;
