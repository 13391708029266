import { notify } from '../../../app/Notifications';
import { useFetch, useRequest } from '../../../hooks';
import { getFormByName, updateForm } from '../../../api';
import type { FormJsonData } from '../../../api';

export const useFormSchema = (name: string) => {
  const { intializing, data, refresh } = useFetch<FormJsonData | void>(
    () => getFormByName(name),
    []
  );

  const [handleUpdateForm, updating] = useRequest(
    (_signal, schema: any) => updateForm(schema),
    {
      onSuccess: () => {
        notify.success('Update form schema sucessfully');
        refresh();
      },
      onError: () => {
        notify.error('Failed to update the form schema');
      },
      deps: [],
    }
  );

  return {
    intializing: intializing || updating,
    data,
    handleUpdateForm,
  };
};
