import {
  useCheckPermissions as libUseCheckPermissions,
  PermissionCheck as LibPermissionCheck,
  PermissionsProvider as LibPermissionsProvider,
} from 'react-permissions-dynamic';
import type {
  PermissionCheckProps as LibPermissionCheckProps,
  PermissionsProviderProps as LibPermissionsProviderProps,
} from 'react-permissions-dynamic';

import { useAuth } from '../User';

type PermissionCheckProps = LibPermissionCheckProps<SpPermission>;
type PermissionsProviderProps = LibPermissionsProviderProps<SpPermission>;

const PermissionCheck: React.FC<PermissionCheckProps> = props => {
  const { isSuper } = useAuth();

  return (
    <LibPermissionCheck
      {...props}
      isAllowed={allowed => isSuper || allowed.some(c => !!c)}
    />
  );
};
const PermissionsProvider =
  LibPermissionsProvider as React.FC<PermissionsProviderProps>;

export { PermissionCheck, PermissionsProvider };
export const useCheckPermissions = (actions: SpPermission[]) => {
  const result = libUseCheckPermissions(Array.from(new Set(actions)));
  const { isSuper } = useAuth();

  return result.map(item => ({ ...item, allowed: item.allowed || isSuper }));
};
