import _ from 'lodash';

export const customDeepEqual = <T>(obj1: T, obj2: T): boolean => {
  if (_.isObject(obj1) && _.isObject(obj2)) {
    // Check object equality, ignoring keys with undefined values
    const keys1 = Object.keys(obj1).filter(
      (key: any) => (obj1 as any)[key] !== undefined
    );
    const keys2 = Object.keys(obj2).filter(
      key => (obj2 as any)[key] !== undefined
    );

    if (!_.isEqual(_.sortBy(keys1), _.sortBy(keys2))) return false;

    return keys1.every(key =>
      customDeepEqual((obj1 as any)[key], (obj2 as any)[key])
    );
  } else if (Array.isArray(obj1) && Array.isArray(obj2)) {
    // Compare arrays using custom logic
    if (obj1.length !== obj2.length) return false;
    return obj1.every((item1, index) => customDeepEqual(item1, obj2[index]));
  } else {
    // For primitive types, use loose equality for the comparison
    // eslint-disable-next-line eqeqeq
    return obj1 == obj2;
  }
};

// Main deepDiff function
export const deepDiff = <T>(object1: T, object2: T): Partial<T> => {
  const result: Partial<T> = {};

  const compareObjects = (obj1: any, obj2: any, resultObj: any) => {
    Object.keys(obj1).forEach(key => {
      const value1 = obj1[key];
      const value2 = obj2[key];

      if (_.isObject(value1) && _.isObject(value2)) {
        // If both are objects, only compare at this level
        if (!customDeepEqual(value1, value2)) {
          resultObj[key] = value1; // Add the entire object if they differ
        }
      } else if (!customDeepEqual(value1, value2)) {
        // Add to result if values are not equal
        resultObj[key] = value1;
      }
    });
  };

  compareObjects(object1, object2, result);
  return result;
};
