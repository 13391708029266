import { useEffect } from 'react';
import { useRefValue } from './useRefValue';

export const useKeyPressed = (
  key: string,
  callback: () => void,
  enable = true
) => {
  const callbackRef = useRefValue(callback);

  useEffect(
    () => {
      if (!enable) return;

      const handleKeyPress = (event: KeyboardEvent) => {
        // Check if the pressed key is the desired key (e.g., 'Enter')
        if (event.key === key) {
          // Perform your desired action here
          callbackRef.current?.();
        }
      };

      // Add event listener when component mounts
      window.addEventListener('keydown', handleKeyPress);

      // Clean up: remove event listener when component unmounts
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, enable]
  ); // Empty dependency array ensures this effect runs only on mount and unmount
};
