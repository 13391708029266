import { useRouteMatch } from 'react-router';

import { Details } from './Details';
import { Messages } from './Messages';
import { useConversation } from './useConversation';
import { Empty, Loading, PermissionCheck, Toggle } from '../../components';
import { Card, DynamicPageTitle, Breadcrumbs } from '../../components';
import { useState } from 'react';
import { filterMessagesWithInterruptions } from './utils';

const Conversation = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { conversation, participant, messages, loading, audio } =
    useConversation(params.id);
  const [showInterruptions, setShowInterruptions] = useState(false);

  const breadcrumbs = (
    <Breadcrumbs
      className="mb-md"
      subtitle="Conversations"
      subtitleUrl="/conversation"
      title={params.id ?? 'Title'}
    />
  );

  if (!conversation || !params.id || loading) {
    return (
      <>
        <DynamicPageTitle>Conversation</DynamicPageTitle>
        {breadcrumbs}
        {loading && <Loading />}
      </>
    );
  }

  return (
    <PermissionCheck
      action="view_conversations"
      fallback={<Empty message="No Access" />}
    >
      {breadcrumbs}
      <DynamicPageTitle>Conversation</DynamicPageTitle>
      <Card
        size="unset"
        title="Transcribe"
        className="h-content"
        headerClassName="z-[2]"
        contentClassName="z-[1] overflow-hidden flex flex-row gap-x-lg -mt-md"
      >
        <div className="flex flex-col">
          <Toggle
            value={showInterruptions}
            onChange={() => setShowInterruptions(prev => !prev)}
            label="Show interruptions"
          />
          <Messages
            messages={filterMessagesWithInterruptions(
              messages ?? [],
              showInterruptions
            )}
            className="overflow-y-auto mt-lg mb-md"
          />
        </div>
        <Details
          conversation={conversation}
          participant={participant}
          audio={audio || []}
        />
      </Card>
    </PermissionCheck>
  );
};

export default Conversation;
