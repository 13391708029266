import { CompressorEndpointsService } from '../services/compressor';

import type {
  Body_compress_with_custom_prompt_compressor_api_v1_compress_with_prompt_post,
  Body_analyze_file_compressor_api_v1_analyze_file_post,
  AnalyzeTextRequest,
  StatusResponse,
  UploadResponse,
} from '../services/compressor';

export type AnalyzeText = AnalyzeTextRequest;
export type CompressResponse = UploadResponse;
export type CompressDeleteResponse = StatusResponse;
export type AnalyzeFile = Body_analyze_file_compressor_api_v1_analyze_file_post;
export type CompressWithCustomPrompt =
  Body_compress_with_custom_prompt_compressor_api_v1_compress_with_prompt_post;

export const uploadAndCmpressText = ({
  file,
}: AnalyzeFile): Promise<CompressResponse> => {
  return CompressorEndpointsService.uploadFileCompressorApiV1UploadPost({
    file,
  });
};

export const getTasksList = async (
  page: number = 1,
  limit: number = 10,
  taskName?: string | null,
  status?: string | null
): Promise<any> => {
  const res =
    await CompressorEndpointsService.listMongodbTasksCompressorApiV1MongodbTasksGet(
      page,
      limit,
      taskName,
      status
    );

  return { content: res.tasks, total: res.pagination.total_items };
};

export const createTaskWithText = ({
  text,
  user_name,
  chunk_size,
}: AnalyzeText): Promise<CompressResponse> => {
  return CompressorEndpointsService.analyzeTextCompressorApiV1AnalyzeTextPost({
    text,
    user_name,
    chunk_size,
  });
};

export const createTaskWithFile = ({
  file,
  user_name,
  chunk_size,
}: AnalyzeFile): Promise<CompressResponse> => {
  return CompressorEndpointsService.analyzeFileCompressorApiV1AnalyzeFilePost({
    file,
    user_name,
    chunk_size,
  });
};

export const getTaskResultPromptById = (taskId: string): Promise<any> => {
  return CompressorEndpointsService.getUnifiedTaskResultCompressorApiV1TaskTaskIdGetResultGet(
    taskId
  );
};

export const compressWithPrompt = (
  formData: CompressWithCustomPrompt
): Promise<CompressResponse> => {
  return CompressorEndpointsService.compressWithCustomPromptCompressorApiV1CompressWithPromptPost(
    formData
  );
};

export const cancelCompressorTask = (
  taskId: string
): Promise<CompressDeleteResponse> => {
  return CompressorEndpointsService.cancelOrDeleteTaskCompressorApiV1TaskTaskIdDelete(
    taskId
  );
};
