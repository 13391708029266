import React, { useState } from 'react';

import { Form, FormItem } from '../../../components';
import { Input, SubmitButton, Button } from '../../../components';
import type { UserFormType } from './useUsers';

type PartialForm = Partial<UserFormType>;

type NewUserFormProps = {
  saving: boolean;
  isEdit?: boolean;
  form?: PartialForm | null;

  onCancel: () => void;
  onSave: (form: UserFormType) => void;
};

export const NewUserForm: React.FC<NewUserFormProps> = ({
  form,
  saving,

  onSave,
  onCancel,
}) => {
  const [values, setValues] = useState<PartialForm>(form ?? {});

  return (
    <div className="flex flex-col gap-y-12 w-full">
      <Form onChange={setValues} initialValue={values}>
        <FormItem<UserFormType>
          required
          label="First Name"
          inline={false}
          name="firstName"
        >
          <Input withBorder placeholder="Enter User First Name" />
        </FormItem>
        <FormItem<UserFormType>
          required
          name="lastName"
          inline={false}
          label="Last Name"
        >
          <Input withBorder placeholder="Enter User Last Name" />
        </FormItem>
        <FormItem<UserFormType>
          required
          name="email"
          inline={false}
          label="Email"
        >
          <Input withBorder placeholder="Enter Email" />
        </FormItem>

        <div className="flex flex-col gap-y-md justify-center items-center">
          <SubmitButton
            size="large"
            label="Create"
            disabled={saving}
            className="w-full"
            onClick={() => onSave(values as UserFormType)}
          />
          <Button
            size="large"
            type="light"
            label="Cancel"
            className="w-full"
            onClick={onCancel}
          />
        </div>
      </Form>
    </div>
  );
};
