export const Clear = () => (
  <svg
    width="19"
    height="19"
    strokeWidth="2"
    viewBox="0 0 19 19"
    stroke="currentColor"
    className="text-infoText"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="4" x2="15" y1="4" y2="15"></line>
    <line x1="15" x2="4" y1="4" y2="15"></line>
  </svg>
);
