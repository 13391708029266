import React, { useTransition } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  IconButton,
  List,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useAuth } from '../../hooks';
import { useCheckPermissions } from '../../shared';
import { Dropdown, PermissionCheck } from '../../components';

const Jessica = React.lazy(() => import('../Jessica'));

const drawerWidth = 240;

export type MenuItem = {
  url?: string;
  text?: string;
  isPublic?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  component?: React.ComponentType<any>;

  action?: SpPermission;
};

type ResponsiveLayoutProps = {
  menuItems: MenuItem[];
  children: React.ReactNode;
};

const MenuItemComponent: React.FC<{ menuItem: MenuItem }> = ({
  menuItem: item,
}) => {
  const theme = useTheme();

  const element = (
    <ListItem
      disablePadding
      sx={{
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light, // Subtle hover effect for background
          transition: 'all 0.3s ease', // Smooth transition for hover effect
        },
      }}
    >
      {item.url ? (
        <NavLink
          to={item.url}
          exact={item.url === '/'}
          style={isActive => ({
            textDecoration: 'none',
            color: isActive ? theme.palette.primary.main : undefined,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '8px 16px',
            fontWeight: isActive ? 500 : 400,
          })}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                fontSize: '20px',
                '& svg': { fontSize: '20px' },
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText
            style={{ fontWeight: 'inherit' }}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '16px',
                fontWeight: 'inherit',
              },
            }}
            primary={item.text}
          />
        </NavLink>
      ) : (
        <Button
          onClick={item.onClick}
          fullWidth
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            padding: '8px 16px',
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          }}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                fontSize: '20px',
                '& svg': { fontSize: '20px' },
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.text}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '16px',
              },
            }}
          />
        </Button>
      )}
    </ListItem>
  );

  return item.action ? (
    <PermissionCheck action={item.action}>{element}</PermissionCheck>
  ) : (
    element
  );
};

const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = ({
  menuItems,
  children,
}) => {
  const theme = useTheme();
  const permissions = useCheckPermissions(
    menuItems.map(item => item.action).filter(i => !!i)
  );
  const [isPending, startTransition] = useTransition();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    user,
    orgs,
    isAuthorized,
    organization_id: selectedOrg,

    setSelectedOrg,
  } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const topMenuItems = menuItems
    .filter(item => item.text !== 'Logout' && item.text)
    .filter(
      item =>
        !item.action ||
        permissions.some(p => p.allowed && p.action === item.action)
    );

  const drawer = (
    <>
      <Toolbar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          marginTop: '2.5rem',
        }}
      >
        {!topMenuItems.length && (
          <div className="pl-md text-medium font-semibold">
            You have no access, please contact your administrator
          </div>
        )}
        {/* Menu items */}
        {!!topMenuItems.length && (
          <List>
            {
              // Exclude Logout from the top part
              topMenuItems.map((item, index) => (
                <MenuItemComponent key={index} menuItem={item} />
              ))
            }
          </List>
        )}
        <Divider sx={{ my: 2 }} />
        {/* Logout button at the bottom */}
        <Box sx={{ marginTop: 'auto' }}>
          {menuItems
            .filter(item => item.text === 'Logout') // Only show Logout here
            .map((item, index) => (
              <Button
                key={index}
                color="inherit"
                onClick={item.onClick}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  padding: '8px 16px',
                  color: theme.palette.error.main,
                  '&:hover': {
                    color: theme.palette.error.dark, // Change text color on hover for Logout
                  },
                }}
              >
                {item.icon && (
                  <ListItemIcon
                    sx={{ fontSize: '20px', '& svg': { fontSize: '20px' } }}
                  >
                    {item.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={item.text}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '16px',
                    },
                  }}
                />
              </Button>
            ))}
        </Box>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {isAuthorized && <Jessica />}
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            noWrap
            component="div"
            id="dynamic-page-title"
            className="font-medium text-main"
          ></Typography>
          <div id="jessica-mount" className="ml-md" />
          <Dropdown
            value={selectedOrg}
            disabled={isPending}
            className="max-w-[20rem] ml-auto"
            placeholder="Select organization"
            options={orgs.map(o => ({ value: o.id, label: o.name }))}
            onChange={newOrg => startTransition(() => setSelectedOrg(newOrg))}
          />
          <div id="dynamic-top-panel" className="ml-md"></div>
          {!!user?.name && (
            <Typography
              variant="h5"
              noWrap
              component="div"
              className="ml-md font-medium"
            >
              {user?.name}
            </Typography>
          )}
          {user?.picture && (
            <img
              alt=""
              src={user?.picture}
              className="rounded-full size-16 ml-sm"
            />
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
          },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default ResponsiveLayout;
