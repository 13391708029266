/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormDto } from '../models/FormDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ManagementFormControllerService {
    /**
     * @returns void
     * @throws ApiError
     */
    public static getForm(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/forms',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateForm(
        requestBody: FormDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/forms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createForm(
        requestBody: FormDto,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/forms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * @param name
     * @returns void
     * @throws ApiError
     */
    public static getFormByName(
        name: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/forms/{name}',
            path: {
                'name': name,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }
}
